import { Drawer, DrawerProps } from '@mui/material'
import { Box } from '@mui/system'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const AnchorDrawer = ({ open, onClose, anchor = 'bottom', children, ...props }: DrawerProps) => {
	return (
		<DrawerBox
			anchor={anchor}
			open={open}
			onClose={onClose}
			transitionDuration={{
				enter: 500,
				exit: 400,
			}}
			sx={
				anchor === 'bottom'
					? {
							'.MuiPaper-root': {
								borderRadius: '12px 12px 0 0',
							},
							maxHeight: 'calc(100vh - 48px)',
					  }
					: {}
			}
			{...props}>
			<Box sx={{ width: anchor === 'bottom' || anchor === 'top' ? '100%' : '250px' }}>
				{children}
			</Box>
		</DrawerBox>
	)
}
const Contents = ({ children }: PropsWithChildren<{}>) => {
	return <BottomBox>{children}</BottomBox>
}
const Title = ({ children }: PropsWithChildren<{}>) => {
	return <TitleText>{children}</TitleText>
}

AnchorDrawer.Contents = Contents
AnchorDrawer.Title = Title

export const DrawerBox = styled(Drawer)`
	.MuiBox-root {
		width: 100%;
	}
	.MuiPaper-root {
		overflow: hidden;

		@media (max-width: 1200px) {
			width: 400px;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}
`
const BottomBox = styled.div`
	width: 100%;
	max-height: calc(100vh - 48px);
	padding: 28px 20px 0 20px;
`

const TitleText = styled.p`
	display: flex;
	justify-content: space-between;

	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px; /* 150% */

	margin-bottom: 8px;
	color: ${(props) => props.theme.new.colors.sol_gray_900};
`
export default AnchorDrawer
