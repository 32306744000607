import { PaginationInput, PaginationResult } from '@/lib/pagination'
import { otherApiClient } from '.'
import { LibrarySearchType } from './library'
import { Subjects } from './product'

export interface ClassSourceCount {
	sourceType: LibrarySearchType
	count: number
}
export const getClassSourceTypeName = (sourceType: ClassSourceCount['sourceType']) => {
	switch (sourceType) {
		case 'workbook':
		case 'textbook':
			return '교재'

		case 'handout':
			return '자료'

		default:
			const label = Object.keys(LibrarySearchType).find(
				(k) => LibrarySearchType[k] === sourceType,
			)
			return label
	}
}

export interface ClassSource extends Pick<ClassSourceCount, 'sourceType'> {
	sourceId: string | number
	title: string
	thumbImg: string

	author: string
	isPick: boolean

	productId: number

	baseSourceTitle: string
	unit: string
	dataType: Array<string>

	publisher: string

	canUse: boolean
}

export interface Class {
	id: number
	userId: number
	name: string
	isMembership: boolean
	subject: Subjects
	createdAt: string
	updatedAt: string
	deletedAt: string
	useBookCount: Array<ClassSourceCount>
	useHandouts: Array<ClassSource>
	useWorkbooks: Array<ClassSource>
}

type ClassSourceResponse = Pick<ClassSource, 'sourceId' | 'sourceType'>

interface ClassChoices {
	choices: Array<ClassSourceResponse>
}

export interface GetClassesRequest extends PaginationInput {
	subject: Subjects
}
export interface GetClassesResponse extends Class {
	pagination: PaginationResult
	resource: string
	data: Array<Class>
}
export const fetchClasses = async ({ ...params }: GetClassesRequest) => {
	const { data } = await otherApiClient.market<GetClassesResponse>(`/classes`, { params })
	return data
}

interface ChangeClassRequest {
	name: string
	subject: Subjects
}

export type PostClassRequest = ChangeClassRequest & ClassChoices
export const postClass = async ({ ...params }: PostClassRequest) => {
	const { data } = await otherApiClient.market.post<Class>(`/classes`, params)
	return data
}

export interface GetClassRequest extends Pick<Class, 'id'> {
	expanded: boolean
}
export interface GetClassResponse extends Class {
	pagination: PaginationResult
	resource: string
	data: Array<Class>
}
export const fetchClass = async ({ id, ...params }: GetClassRequest) => {
	const { data } = await otherApiClient.market<GetClassesResponse>(`/classes/${id}`, {
		params,
	})
	return data
}

export type PatchClassRequest = Pick<Class, 'id'> & ChangeClassRequest
export const patchClass = async ({ id, ...params }: PatchClassRequest) => {
	const { data } = await otherApiClient.market.patch<Class>(`/classes/${id}`, params)
	return data
}

export const deleteClass = async ({ id }: Pick<Class, 'id'>) => {
	const { data } = await otherApiClient.market.delete(`/classes/${id}`)
	return data
}

export interface GetClassUseBooksRequest extends Pick<Class, 'id'> {
	searchType: Extract<LibrarySearchType, 'handout' | 'workbook'>
}
export const fetchClassUseBooks = async ({ id, ...params }: GetClassUseBooksRequest) => {
	const { data } = await otherApiClient.market<Array<ClassSource>>(`/classes/${id}/use-books`, {
		params,
	})
	return data
}

export interface PatchClassUseBooksRequest extends Pick<Class, 'id'>, ClassChoices {
	deletes?: Array<ClassSourceResponse>
}
export interface PatchClassUseBooksResponse extends Class {}
export const patchClassUseBooks = async ({ id, ...params }: PatchClassUseBooksRequest) => {
	const { data } = await otherApiClient.market.patch<PatchClassUseBooksResponse>(
		`/classes/${id}/use-books`,
		params,
	)
	return data
}
