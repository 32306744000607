import {
	Button,
	ButtonProps,
	Divider,
	Rating,
	RatingProps,
	styled as muiStyled,
} from '@mui/material'
import BigNumber from 'bignumber.js'
import { FC, forwardRef, PropsWithChildren, ReactNode, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import Icon, { Props as IconProps } from '../icon/Icon'
import { Sizes, Star } from '../icon/IconList'

const ReviewSectionCardWrapper = styled.div`
	padding: 16px;
	background-color: ${(props) => props.theme.new.colors.white};
	border-radius: 6px;

	& textarea::placeholder {
		color: ${(props) => props.theme.new.colors.sol_gray_300};
	}
`
const ReviewSectionTitle = styled.h2`
	margin-bottom: 4px;
	color: ${(props) => props.theme.new.colors[props.color]};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
`

export const ReviewSectionCard: FC<
	PropsWithChildren<{
		title: ReactNode
		titleColor?: keyof ReturnType<typeof useTheme>['new']['colors']
	}>
> = ({ children, title, titleColor = 'sol_blue_500' }) => {
	return (
		<ReviewSectionCardWrapper>
			<ReviewSectionTitle color={titleColor}>{title}</ReviewSectionTitle>
			{children}
		</ReviewSectionCardWrapper>
	)
}

const ReviewTitleWrapper = styled.h3`
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	span {
		color: ${(props) => props.theme.new.colors.sol_blue_900};
		font-size: 18px;
		font-weight: 600;
		line-height: 26px; /* 144.444% */
	}

	strong {
		color: ${({ theme }) => theme.new.colors.sol_indigo_500};
	}

	small {
		display: inline-flex;
		margin-left: 8px;
		align-items: center;
		color: ${(props) => props.theme.new.colors.sol_gray_300};
		font-size: 14px;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
		letter-spacing: -0.14px;
	}
`

export const ReviewTitle: FC<PropsWithChildren<{ smallSuffix?: ReactNode }>> = ({
	children,
	smallSuffix,
}) => {
	return (
		<ReviewTitleWrapper>
			<span>{children}</span>
			{!!smallSuffix && <small>{smallSuffix}</small>}
		</ReviewTitleWrapper>
	)
}

export const ReviewFormDivider = styled(Divider)({
	'&.MuiDivider-root': { margin: '24px 0' },
})

const ReviewChipButtonWrapper = muiStyled(Button)<{ selected?: boolean }>(({ theme, selected }) => {
	const {
		typography: { pxToRem: tpr, ...typo },
	} = theme

	return {
		'&.MuiButton-outlined': {
			padding: tpr(12).concat(' ', tpr(16)),
			backgroundColor: selected
				? theme.palette.indigo[50]
				: theme.palette.blackAndWhite.white,
			borderColor: selected ? theme.palette.indigo[200] : theme.palette.grey[100],
			color: theme.palette[selected ? 'indigo' : 'grey'][500],

			...typo.semibold16,

			'&:hover': { backgroundColor: theme.palette.indigo[50] },
		},
	}
})

export const ReviewChipButton = forwardRef<
	HTMLButtonElement,
	PropsWithChildren<ButtonProps & { label: ReactNode; selected?: boolean; alignStart?: boolean }>
>(({ children, variant = 'outlined', label, alignStart, ...props }, ref) => {
	return (
		<ReviewChipButtonWrapper
			ref={ref}
			variant={variant}
			{...props}
			sx={{
				...(alignStart
					? { justifyContent: 'flex-start' }
					: {
							'&.MuiButton-outlined': (theme) => ({
								[theme.breakpoints.down('sm')]: { px: 0 },
							}),
					  }),
			}}>
			{children || label}
		</ReviewChipButtonWrapper>
	)
})

const StyledRating = styled(Rating)(({ theme }) => ({
	'& .MuiRating-icon': { transition: 'color 0.2s' },
	'& .MuiRating-decimal > span:has(.MuiRating-iconFilled)': { zIndex: 1 },
	'&:hover': {
		'& .MuiRating-iconFilled': { color: theme.new.colors.sol_yellow_200 },
		'& .MuiRating-iconEmpty': { color: theme.new.colors.sol_gray_100 },
	},
}))

export const ProductRating = forwardRef<
	HTMLSpanElement,
	Omit<RatingProps, 'size'> & Pick<IconProps, 'size'>
>(({ precision = 0.01, readOnly = true, size = Sizes.large, value: _value, ...props }, ref) => {
	const theme = useTheme()

	const { value } = useMemo(() => {
		let result: number = _value

		if (_value) {
			const integer = BigNumber(_value).integerValue(BigNumber.ROUND_FLOOR)

			if (BigNumber(_value).comparedTo(integer) !== 0) {
				const decimal = BigNumber(_value).minus(integer)
				const calculated = decimal.multipliedBy(BigNumber(0.7)).plus(BigNumber(0.15))
				// console.log(integer.toNumber(), decimal.toNumber(), calculated.toNumber())

				result = calculated.plus(integer).toNumber()
			}
		}
		return { value: result }
	}, [_value])

	return (
		<StyledRating
			tabIndex={0}
			ref={ref}
			icon={<Icon icon={Star} size={size} color={theme.new.colors.sol_yellow_400} />}
			emptyIcon={<Icon icon={Star} size={size} color={theme.new.colors.sol_gray_100} />}
			precision={precision}
			readOnly={readOnly}
			value={value}
			{...props}
		/>
	)
})
