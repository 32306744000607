import FilledButton from '@/components/common/Button/FilledButton'
import { BOOKS_LANDING } from '@/settings/constant'
import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo } from 'react'
import styled from 'styled-components'

export const BooksWrapper = styled.div`
	flex-grow: 1;
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
`
export const BooksContainer = styled.div`
	max-width: 640px;
	margin: 0 auto;
	padding: 48px 20px;

	@media (max-width: 1200px) {
	}
	@media (max-width: 768px) {
		max-width: 390px;
		padding: 24px 16px;
	}
`

export const BooksStyledTitle = styled.h2`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 24px;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
	color: ${(props) => props.theme.new.colors.sol_gray_900};
`
export const BooksSubtitle = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: -0.16px;
	color: ${(props) => props.theme.new.colors.sol_gray_500};
`

export const BooksTitle: FC<{ primary: ReactNode; secondary?: ReactNode }> = ({
	primary,
	secondary,
}) => {
	return (
		<>
			<BooksStyledTitle>
				{primary}
				{!!secondary && <BooksSubtitle>{secondary}</BooksSubtitle>}
			</BooksStyledTitle>
		</>
	)
}

export const OrderFilledButton = styled(FilledButton)`
	padding: 16px 24px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: -0.16px;
`

export const TermsContainer = styled.dl`
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.14px;

	&,
	dd {
		margin-bottom: 0;
	}

	dt {
		margin-bottom: 8px;
		color: ${(props) => props.theme.new.colors.sol_gray_900};
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		letter-spacing: -0.16px;
	}

	li {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}

	ol {
		margin-bottom: 0;
		padding-left: 20px;
		li {
			list-style-type: decimal;
		}
	}
	ul {
		padding-left: 20px;
		li {
			list-style-type: disc;

			&::marker {
				content: '• ';
			}
		}
	}
`

export const getIsBooks = (pathname: string) => pathname.startsWith(BOOKS_LANDING)
export const useBooks = () => {
	const { pathname } = useRouter()
	const { isBooks } = useMemo(() => {
		return { isBooks: getIsBooks(pathname) }
	}, [pathname])

	return { isBooks }
}
