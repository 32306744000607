import { ModalProps as AntdModalProps } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'
import BooksOrderModal from '../../Books/Order/BooksOrderModal'
import FilledButton from '../../common/Button/FilledButton'
export interface DialogProps extends AntdModalProps {
	isOpen: boolean
	onClose: () => void
	title: ReactNode
	content?: ReactNode
	severity?: 'success' | 'caution'
	prefix?: {
		variant: 'soft' | 'solid'
		label: string
		handleClick?: () => void
	}
	suffix?: {
		variant: 'soft' | 'solid'
		label: string
		handleClick?: () => void
	}
}
export const initialModalProps: DialogProps = {
	isOpen: false,
	onClose: () => {},
	title: '',
	content: '',
}

const Dialog = ({
	isOpen,
	onClose,
	title,
	content,
	severity,
	prefix,
	suffix,
	...props
}: DialogProps) => {
	return (
		<BooksOrderModal
			open={isOpen}
			title={title}
			severity={severity}
			onClose={() => onClose()}
			content={content}
			{...props}
			footer={
				<ButtonBox>
					{prefix && (
						<FilledButton
							type="text"
							variant={prefix.variant}
							onClick={() => prefix.handleClick && prefix.handleClick()}>
							{prefix.label}
						</FilledButton>
					)}
					{suffix && (
						<FilledButton
							type="text"
							variant={suffix.variant}
							onClick={() => suffix.handleClick && suffix.handleClick()}>
							{suffix.label}
						</FilledButton>
					)}
				</ButtonBox>
			}></BooksOrderModal>
	)
}

const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	button {
		font-size: 16px;
		padding: 12px 20px;
	}
`
export default Dialog
