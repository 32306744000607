import styled, { css } from 'styled-components'

export interface ContainerWrapperProps {
	fullWidth?: boolean
	noGutter?: boolean
	fluid?: boolean
}

const ContainerWrapper = styled.div<ContainerWrapperProps>`
	max-width: 1260px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
			max-width: none !important;
		`};
	${(props) =>
		(props.noGutter &&
			css`
				padding-left: 0;
				padding-right: 0;
			`) ||
		css`
			padding-left: 30px;
			padding-right: 30px;

			@media (max-width: 1200px) {
				padding-left: 32px;
				padding-right: 32px;
			}
			@media only screen and (max-width: 768px) {
				padding-left: 16px;
				padding-right: 16px;
			}
		`};

	${(props) =>
		props.fluid &&
		css`
			width: 100% !important;
			max-width: 100% !important;
			@media only screen and (min-width: 1441px) {
				padding-left: 64px;
				padding-right: 64px;
			}
		`};

	@media (max-width: 768px) {
	}
	@media (max-width: 992px) {
	}
	@media (max-width: 1200px) {
		//overflow: hidden;
		max-width: inherit;
	}
`

export default ContainerWrapper
