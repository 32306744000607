import { createGlobalStyle } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

//less
export const AppDefaultStyle = createGlobalStyle`

span {
	color: inherit;
}

body {
   //-webkit-user-select: none;
   //-webkit-tap-highlight-color: transparent;
   //-webkit-touch-callout: none;
	padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
	padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
    
.ant-form-item {
margin: 0 0 16px;

 .ant-form-explain, .ant-form-extra {
	margin-top: 2px;
	line-height: 26px;   		
  } 
}

.active-link {
	display: inline-block;
	height: 64px;
}

 .am-tabs-default-bar-tab-active {
 	color: ${themeGet('primary.0')};
 } 
 
 .am-tabs-default-bar-underline {
 	border: 1px ${themeGet('primary.0')} solid;
 }
//
// .ant-checkbox {
// 	flex-shrink: 0;
// 	.ant-checkbox-inner {
// 	  border-radius: 0;
// 	  border-color: ${themeGet('text.1', '#909090')};
// 	}
//     &.ant-checkbox-checked {
// 	  .ant-checkbox-inner {
// 	     border-color: ${themeGet('primary.0', '#008489')};
// 		 background-color: ${themeGet('primary.0', '#008489')};
// 	   }
//     }
// 	&::after {
// 		display: none;
// 	}
// }  
// `
