import { FC, useMemo } from 'react'
import { Dialog, styled as muiStyled, Tab, Tabs, Typography } from '@mui/material'
import { SearchTextField } from '../common/TextField/searchTextField'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { TermItem } from '../../api/search'
import { purchasableSubjectsList } from '../../api/product'
import { KeywordChip } from '../Search/Chips'
import { ButtonWithIcon } from '../Search'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

interface SearchRecommendPanelProps {
	onClose: () => void
	isOpen: boolean
	modalValue: string
	onClear: () => void
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	currentSubject: string
	handleCurrentSubject: (subject: string) => void
	onSubmit: () => void
	onClick: (keyword: string) => void
	recommendSearchTermList: TermItem
}

export const SearchRecommendDialog: FC<SearchRecommendPanelProps> = ({
	isOpen,
	onClose,
	modalValue,
	onClear,
	onChange,
	currentSubject,
	handleCurrentSubject,
	onSubmit,
	onClick,
	recommendSearchTermList,
}) => {
	const { width, isTablet, isMobile } = useWindowDimensions()
	const hasRecommendSearchTerm = useMemo(
		() => recommendSearchTermList.terms && recommendSearchTermList.terms.length > 0,
		[recommendSearchTermList],
	)

	return (
		<SearchDialog onClose={onClose} open={isOpen}>
			<Panel>
				<SearchSection>
					{isTablet && <ButtonWithIcon onClick={onClose} />}
					<SearchTextField
						autoFocus={true}
						onSearchClick={() => {
							onSubmit()
							mixpanelEvent('Icon UI Clicked', {
								'Icon Type': 'Search',
								'Icon Position': '[Market] Search Modal',
							})
						}}
						onClear={onClear}
						placeholder={recommendSearchTermList.placeHolder}
						value={modalValue}
						onChange={onChange}
						onSubmit={(e) => {
							e.preventDefault()
							onSubmit()
						}}
					/>
				</SearchSection>
				<SubjectTabList
					value={currentSubject}
					onChange={(e, subject) => {
						handleCurrentSubject(subject)
						mixpanelEvent('Tab UI Clicked', {
							'Tab Type': 'Search Modal',
							'Tab Name': subject,
						})
					}}
					centered>
					{purchasableSubjectsList().map((tab) => (
						<SubjectTabItem key={tab.key} label={tab.label} value={tab.key} />
					))}
				</SubjectTabList>
				{hasRecommendSearchTerm && (
					<RecommendationKeywordSection>
						<Typography
							variant={'subtitle1'}
							component={'p'}
							sx={{ marginBottom: '12px' }}>
							쏠북 인기 검색어
						</Typography>
						<RecommendationKeywordList>
							{recommendSearchTermList.terms.map((item, idx) => {
								return (
									<KeywordChip
										key={idx + '-' + item}
										text={item}
										onClick={() => {
											mixpanelEvent('Search Keyword Clicked', {
												'Button Name': item,
												Subject: currentSubject,
												'Keyword Order': idx + 1,
												'Placeholder Text':
													recommendSearchTermList?.placeHolder,
											})
											onClick(item)
										}}
									/>
								)
							})}
						</RecommendationKeywordList>
					</RecommendationKeywordSection>
				)}
			</Panel>
		</SearchDialog>
	)
}
const RecommendationKeywordList = muiStyled('ul')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: '8px',
}))
const RecommendationKeywordSection = muiStyled('section')(({ theme }) => ({
	padding: '24px 20px',
	maxWidth: '440px',
	margin: '0 auto',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	height: 'auto',
	overflowY: 'auto',
	overscrollBehavior: 'contain',
	[theme.breakpoints.down('lg')]: {
		maxWidth: '480px',
	},
}))
const SubjectTabList = muiStyled(Tabs)(({ theme }) => ({
	width: '100%',
	margin: '0 auto',
	position: 'relative',
	borderBottom: `1px solid ${theme.palette.grey[50]}`,

	'& .MuiTabs-indicator': {
		backgroundColor: theme.palette.text['title'],
	},
	'& .MuiTabs-scroller': {
		maxWidth: '440px',
		margin: '0 auto',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '480px',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
		},
	},
}))
const SubjectTabItem = muiStyled(Tab)(({ theme }) => ({
	width: '100%',
	flex: 1,

	'&.MuiTab-root': {
		minWidth: 'auto',
		color: theme.palette.text['disabled'],
		...theme.typography.subtitle1,
		padding: '12px 0',
		'&:hover': {
			color: theme.palette.text['title'],
			transition: 'color 0.3s',
		},
		'&.Mui-selected': {
			color: theme.palette.text['title'],
			'& ::selection': {
				backgroundColor: theme.palette.text['title'],
			},
		},
	},
}))
const Panel = muiStyled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'hidden',
	flex: '1 1 auto',
	width: '440px',
	[theme.breakpoints.down('lg')]: {
		width: '100%',
	},
}))
const SearchSection = muiStyled('div')(({ theme }) => ({
	padding: '8px 20px',
	boxSizing: 'border-box',
	[theme.breakpoints.down('lg')]: {
		display: 'flex',
		padding: '8px 12px',
		gap: '4px',
		width: '100%',
		alignItems: 'center',
		form: {
			width: '100%',
		},
	},
}))
const SearchDialog = muiStyled(Dialog)(({ theme }) => ({
	overflowY: 'scroll',
	'&.MuiBackdrop-root': {
		backgroundColor: 'rgba(28, 31, 46, 0.2)',
	},
	'& .MuiDialog-container': {
		alignItems: 'flex-start',
		'& .MuiDialog-paper': {
			margin: '4px 0 40px',
			borderRadius: '12px',
			boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
			[theme.breakpoints.down('lg')]: {
				width: '100%',
				maxWidth: '100%',
				borderRadius: '0',
				margin: '0',
				height: '100%',
				maxHeight: '100%',
			},
		},
	},
}))
