import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material/styles'
import { Sizes } from '../icon/IconList'
import { ProductRating } from '../Reviews'

// 메인에서 혹은 검색페이지에서 사용되는 평점 컴포넌트입니다.
// 일단 시안상 통일된다면, 사이즈는 작게 가는게 좋을 것 같아서 스타일은 고정인데,
// 추가로 확장이 필요하다면 자유롭게 확장해주세요.
const RatingWithText = ({ rating }: { rating: number }) => {
	return (
		<AverageRating>
			<ProductRating
				precision={0.1}
				max={1}
				value={rating}
				readOnly={true}
				size={Sizes.small}
			/>
			<p>{Number.isInteger(rating) ? `${rating}.0` : rating}</p>
		</AverageRating>
	)
}
//검색에서 사용되는 평점 컴포넌트
//스타일의 변경이 있어서 container를 새로 생성하였습니다.
export const SearchRatingWithText = ({ rating }: { rating: number }) => {
	return (
		<SearchAverageRating>
			<ProductRating
				precision={0.1}
				max={1}
				value={rating}
				readOnly={true}
				size={Sizes.small}
			/>
			<p>{Number.isInteger(rating) ? `${rating}.0` : rating}</p>
		</SearchAverageRating>
	)
}
const SearchAverageRating = muiStyled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: '4px',
	p: {
		color: theme.palette.text.secondary,
		...theme.typography.body2,
	},
}))
const AverageRating = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_700};
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
		letter-spacing: -0.14px;
	}
`
export default RatingWithText
