import { ModalType } from '@/components/Landing/UserSourceStep'
import { itemDTO } from 'src/api/main'
import { Categories, Subjects } from 'src/api/product'
import { UserMeta } from 'src/api/users'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface MainPageStoreState {
	subject: Subjects
	selectedBookList: Array<itemDTO>
	initialSelectedBookList?: Array<itemDTO>
	userMeta: UserMeta
	modalType: ModalType
	selectedBook: itemDTO | null
	selectedDataType: Categories
}
export interface MainPageStoreActions {
	setSubject: (subject: Subjects) => void
	setSelectedBookList: (selectedBook: itemDTO) => void
	setInitialSelectedBookList: (selectedBook?: Array<itemDTO>) => void
	setAllSelectedBookList: (selectedBook: Array<itemDTO>) => void
	setUserMeta: (userMeta: UserMeta) => void
	setModalType: (modalType: ModalType) => void
	setSelectedBook: (selectedBook: itemDTO) => void
	setSelectedDataType: (selectedDataType: Categories) => void
}

export const useMainPageStore = create<MainPageStoreState & MainPageStoreActions>((set, get) => ({
	subject: null,
	selectedBookList: [],
	userMeta: {
		job: null,
		mainSubject: null,
	},
	modalType: 'selectBooksModal',
	selectedBook: null,
	selectedDataType: null,
	setSubject: (subject) => {
		//과목 변경시 선택교재 리스트, 선택교재 초기화
		set((state) => {
			return {
				subject,
				selectedBookList: [],
				selectedBook: null,
			}
		})
	},
	setSelectedBookList: (selectedBook) => {
		//교재 선택시 선택교재 리스트에 추가/제거
		set((state) => {
			const findBook = state.selectedBookList.find((item) => item.id === selectedBook.id)
			const newSelectedBookList = findBook
				? state.selectedBookList.filter((item) => item.id !== selectedBook.id)
				: [...state.selectedBookList, selectedBook]
			const newSelectedBook = newSelectedBookList.length > 0 ? newSelectedBookList[0] : null
			return {
				selectedBookList: newSelectedBookList,
				selectedBook: newSelectedBook,
			}
		})
	},
	setInitialSelectedBookList: (selectedBooks) => {
		set((state) => ({ initialSelectedBookList: selectedBooks }))
	},
	setAllSelectedBookList: (selectedBookList) => {
		set((state) => {
			return {
				selectedBookList,
				selectedBook: selectedBookList.length > 0 ? selectedBookList[0] : null,
			}
		})
	},
	setUserMeta: ({ job, mainSubject }) => set({ userMeta: { job, mainSubject } }),
	setModalType: (modalType) => set({ modalType }),
	setSelectedBook: (selectedBook) => {
		set((state) => {
			return {
				selectedBook,
				selectedDataType: null,
			}
		})
	},
	setSelectedDataType: (selectedDataType) => set({ selectedDataType }),
}))

const StorageKey = 'user-selected-books'
interface State {
	savedUserSource: {
		subject: Subjects
		selectedBookList: itemDTO[]
	}[]
	setSavedUserSource: (userSource: { subject: Subjects; selectedBookList: itemDTO[] }) => void
	savedUserMeta: UserMeta
	updateUserMeta: (userMeta: UserMeta) => void
}

export const useSavedUserSourceStore = create(
	persist<State>(
		(set, get) => ({
			savedUserSource: [],
			savedUserMeta: {
				job: null,
				mainSubject: null,
				schoolItems: [],
			},
			setSavedUserSource: (userSource) => {
				const index = get().savedUserSource.findIndex(
					(item) => item.subject === userSource.subject,
				)
				let newSavedUserSource

				if (index !== -1) {
					newSavedUserSource = [...get().savedUserSource]
					newSavedUserSource[index] = userSource
				} else {
					newSavedUserSource = [...get().savedUserSource, userSource]
				}

				set({ savedUserSource: newSavedUserSource })
			},
			updateUserMeta: (userMeta) => {
				set({ savedUserMeta: userMeta })
			},
		}),
		{
			name: StorageKey,
		},
	),
)
