import Icon from '../icon/Icon'
import { PASSES_LANDING } from '@/settings/constant'
import { Bookmark, Sizes } from '../icon/IconList'
import styled from 'styled-components'
import Link from 'next/link'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export interface MembershipStatusLinkProps {
	children?: React.ReactNode
}

export const PassPromotion: React.FC<MembershipStatusLinkProps> = ({ children }) => {
	return (
		<MembershipStatusLinkWrapper
			href={PASSES_LANDING}
			onClick={() =>
				mixpanelEvent('Home Quick Button Clicked', {
					'Button Type': 'Pass',
					'Button Name': '이용권 구매',
					url: PASSES_LANDING,
				})
			}>
			<Icon icon={Bookmark} size={Sizes.xmedium} />
			<p>이용권 구매</p>
			{children}
		</MembershipStatusLinkWrapper>
	)
}
export const MembershipStatusLinkWrapper = styled(Link)`
	font-size: 14px;
	display: flex;
	font-weight: 600;
	align-items: center;
	line-height: 22px;
	padding: 2px 8px;
	border-radius: 4px;
	color: ${(props) => props.theme.new.colors.sol_indigo_500};

	i {
		margin-right: 2px;
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
	}

	:hover {
		transition: background-color 0.3s ease-in-out;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
	}

	@media (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		border-radius: 6px;
		margin-bottom: 24px;
		padding: 9px 12px;
	}
`
