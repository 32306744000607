import { toQueryStringCamel } from 'src/utils/misc'
import { IQueryResponse, otherApiClient } from '.'
import { MemberAddress, UserInfoRes } from './getMe'
import { itemDTO } from './main'
import { Subjects } from './product'

interface UserSourceDTO {
	subject: Subjects
	items: itemDTO[]
}

interface IPutUserSourceDTO {
	subject: Subjects
	items: {
		id: string | number
		type: 'textbook' | 'workbook'
	}[]
}

interface IUserSourceRes {
	curriculum: UserSourceDTO
	mySource: UserSourceDTO
	publisher: UserSourceDTO
	sourceData: {
		selectedCurriculum: string
		selectedPublisher: string
		textbook: UserSourceDTO
		workbook: UserSourceDTO
	}
}

export type UserType = 'teacher' | 'student' | 'parent' | 'principal' | 'university' | 'etc'
export const userTypeWithKorean = {
	teacher: '선생님',
	student: '중/고등학생',
	parent: '학부모',
	principal: '원장님',
	university: '대학생',
	etc: '기타',
} as const

export interface UserMeta {
	job: UserType | null
	mainSubject?: Subjects
	schoolItems?: Array<string> //학교uid  - patch request에서의 타입을 위해 로컬 스토리지 타입과 다르게 설정했습니다.
	setDefaultSource?: 'true' //정보등록 플로우에서 전송시 디폴트 교재 지급
}

//교재 리스트 가져오기
export const getUserSourcesSelection = async ({
	publisher,
	curriculum,
	subject,
}: {
	publisher?: string
	curriculum?: string
	subject?: Subjects
}) => {
	const { data } = await otherApiClient.market.get<IUserSourceRes>(
		`/users/sources/selection/${toQueryStringCamel({ publisher, curriculum, subject })}`,
	)
	return data
}

//맞춤교재 등록
export const putUserSource = async ({ items, subject }: IPutUserSourceDTO) => {
	const { data } = await otherApiClient.market.put<IQueryResponse>(`/users/sources`, {
		subject,
		items,
	})
	return data
}

//유저 메타 정보 저장
export const patchUserMeta = async (params: UserMeta) => {
	const { data } = await otherApiClient.market.patch<IQueryResponse>(`/users/meta`, {
		...params,
	})
	return data
}
export const getUserMeta = async () => {
	const { data } = await otherApiClient.market.get<UserInfoRes>(`/users/meta`)
	return data
}

export const putUserAddress = async (params: MemberAddress) => {
	const { data } = await otherApiClient.market.put<UserInfoRes>(`/users/address`, params)
	return data
}
