import cookie from 'js-cookie'
import http from 'http'

export const DEFAULT_SESSION_COOKIE_NAME = 'session_id'
export const DEFAULT_USER_COOKIE_NAME = 'save_id'

export const setCookie = (key: string, value: string, expires = 1) => {
	if (typeof window === 'undefined') {
		cookie.set(key, value, {
			expires,
			path: '/',
		})
	}
}

export const removeCookie = (key: string) => {
	if (typeof window === 'undefined') {
		cookie.remove(key, {
			expires: 1,
		})
	}
}

export const getCookie = (key: string, req?: http.IncomingMessage) => {
	if (typeof window === 'undefined') {
		return getCookieFromBrowser(key)
	} else {
		return getCookieFromServer(key, req)
	}
}

export const getCookieFromBrowser = (key: string) => {
	return cookie.get(key)
}

export const getCookieFromServer = (
	key: string,
	req: http.IncomingMessage | undefined,
): string | null => {
	if (!req) {
		return null
	}
	const { headers } = req
	if (!headers || !headers.cookie) {
		return null
	}
	const rawCookie = headers.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`))
	if (!rawCookie) {
		return null
	}
	return rawCookie.split('=')[1]
}
