import styled from 'styled-components'
import Icon from '../icon/Icon'
import { Check_Thin, CouponDownload } from '../icon/IconList'

type CouponProps = {
	children?: React.ReactNode
}

const Coupon = ({ children }: CouponProps) => {
	return <CouponBox role="listitem">{children}</CouponBox>
}

const Content = ({ children }: CouponProps) => {
	return <CouponContents>{children}</CouponContents>
}

const CouponTitle = ({ children }: CouponProps) => {
	return <CouponTitleText>{children}</CouponTitleText>
}
const CouponMaxDiscount = ({ children }: CouponProps) => {
	return <MaxDiscountText>{children}</MaxDiscountText>
}
const CouponCondition = ({ children }: CouponProps) => {
	return <ConditionText>{children}</ConditionText>
}
const ExpireDate = ({ children }: CouponProps) => {
	return <ExpireDateText>{children}</ExpireDateText>
}

const Possession = ({
	status,
	onHandleClick,
}: {
	status?: boolean
	onHandleClick?: () => void
}) => {
	return (
		<IsOwnedStateArea
			role="button"
			className={status ? 'status-isMine' : ''}
			onClick={onHandleClick}>
			{status ? (
				<StateIcon>
					<Icon icon={Check_Thin} color="#A9AFC7" />
					<IconWithText>보유중</IconWithText>
				</StateIcon>
			) : (
				<StateIcon>
					<Icon icon={CouponDownload} color="#513CBA" />
					<IconWithText>받기</IconWithText>
				</StateIcon>
			)}
		</IsOwnedStateArea>
	)
}

Coupon.Content = Content
Coupon.Title = CouponTitle
Coupon.MaxDiscount = CouponMaxDiscount
Coupon.Condition = CouponCondition
Coupon.ExpireDate = ExpireDate

Coupon.Possession = Possession

const CouponBox = styled.div`
	display: flex;
	height: 142px;
	gap: 8px;
	justify-content: space-between;
`
const CouponContents = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	padding: 16px;
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	background-color: ${(props) => props.theme.new.colors.white};
`
const CouponTitleText = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.16px;
	margin-bottom: 4px;
	color: ${(props) => props.theme.new.colors.sol_gray_900};
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
`
const MaxDiscountText = styled.p`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_red_400};
`
const ConditionText = styled.p`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_gray_500};
`
const ExpireDateText = styled.p`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_gray_300};
`

const IsOwnedStateArea = styled.div`
	display: flex;
	width: 64px;
	align-items: center;

	/* flex-shrink: 0; */
	/* padding: 0 11px; */
	justify-content: center;
	border-radius: 6px;
	background-color: ${(props) => props.theme.new.colors.sol_indigo_100};

	:hover {
		cursor: pointer;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_200};
	}

	&.status-isMine {
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};

		p {
			color: ${(props) => props.theme.new.colors.sol_gray_300};
		}

		:hover {
			cursor: default;
		}
	}
`
const StateIcon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const IconWithText = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;

	color: ${(props) => props.theme.new.colors.sol_indigo_600};
`

export default Coupon
