import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { List } from 'antd'
// import { FlatList as RNFlatList } from 'react-native-gesture-handler'
// import Animated from 'react-native-reanimated'
import { reaction, toJS } from 'mobx'
import { Text } from './Text'
import { View } from './View'
import { ActivityIndicator } from './ActivityIndicator'
import { useStyledTheme } from '@/hooks/useStyledTheme'

export type ListRenderItem<T> = (item: T, index: number) => React.ReactNode

// React.Component<
// NativeViewGestureHandlerProperties & FlatListProperties<ItemT>
// > {}
export type FlatListProps<T> = {
	useRefresh?: boolean
	status: FlatListStatus
	actions: FlatListActions<T>
	renderItem: ListRenderItem<T>
	// contentContainerStyle?: StyleProp<ViewStyle>
	ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null
	ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null
	ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null
	ListLoadingComponent?: React.ComponentType<any> | React.ReactElement | null
	// onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
	scrollEventThrottle?: number // null
	contentOffset?: number
}

const FlatListLoadingWrapper = styled(View)`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`

export const DefaultEmptyComponent: React.FC<{}> = ({}) => {
	const theme = useStyledTheme()
	return (
		<View
			style={{
				flex: 1,
				alignContent: 'center',
				justifyContent: 'center',
			}}>
			<Text
				style={{
					flex: 0,
					textAlign: 'center',
					// color: theme.text[2],
				}}>
				데이터가 없습니다.
			</Text>
		</View>
	)
}

export const DefaultLoadingComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<FlatListLoadingWrapper>
			{/*<ActivityIndicator style={{ flex: children ? 0 : 1 }} color={'#000'} />*/}
			<ActivityIndicator color={'#000'} />
			{children}
		</FlatListLoadingWrapper>
	)
}

const ListWrapper = styled(View)`
	display: flex;
	flex: 1;
`

export const FlatList = <T,>({
	status,
	actions,
	renderItem,
	ListHeaderComponent,
	ListFooterComponent,
	useRefresh = true,
	ListEmptyComponent = DefaultEmptyComponent,
	ListLoadingComponent = DefaultLoadingComponent,
	// contentContainerStyle,
	// onScroll,
	contentOffset,
}: FlatListProps<T>) => {
	const [visible, setVisible] = useState(false)
	const [dataSource, setDataSource] = useState<T[]>([])
	const [refreshing, setRefreshing] = useState(false)
	const [addingItems, setAddingItems] = useState(false)

	useEffect(() => {
		const loadingReaction = reaction(
			() => status.loading,
			(value, dispose) => {
				setVisible(!value)
			},
		)

		const refreshingReaction = reaction(
			() => status.refreshing,
			(value) => {
				setRefreshing(value)
			},
		)

		const addingItemsReaction = reaction(
			() => status.addingItems,
			(value) => {
				setAddingItems(value)
			},
		)

		const dataSourceReaction = reaction(
			() => actions.items,
			(data) => {
				const list = toJS(data)
				setDataSource(list)
			},
		)

		return () => {
			loadingReaction()
			refreshingReaction()
			addingItemsReaction()
			dataSourceReaction()
		}
	}, [])

	if (!visible) {
		return <>{ListLoadingComponent}</>
	}

	return (
		<ListWrapper>
			<List itemLayout="horizontal" dataSource={dataSource} renderItem={renderItem} />
		</ListWrapper>
	)
}

const FlatListFooterWrapper = styled(View)`
	padding: 16px 24px;
`

export type ItemClickHandle<T, P = {}> = {
	(values: { item: T; metadata?: P }): Promise<void>
}

export interface FlatListActions<T, P = {}> {
	readonly items: T[]
	// getItems: () => T[]
	onLoadMore: () => Promise<void>
	onRefresh: () => Promise<void>
	onItemClick: ItemClickHandle<T, P>
}

export interface FlatListStatus {
	initialized: boolean
	addingItems: boolean
	refreshing: boolean
	loading: boolean
}

export const FlatListDefaultFooter: React.FC<{}> = () => {
	return (
		<FlatListFooterWrapper>
			<ActivityIndicator color={'#000'} />
		</FlatListFooterWrapper>
	)
}
