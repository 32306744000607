import { saveAs } from 'file-saver'

// {type: "text/plain;charset=utf-8"}

const downloadFile = (base64Data: string, contentType: string, filename: string) => {
	var blob = base64toBlob(base64Data, contentType)
	return saveAs(blob, filename)
}

function base64toBlob(base64Data: string, contentType: string) {
	contentType = contentType || ''
	var sliceSize = 1024
	var byteCharacters = atob(base64Data)
	var bytesLength = byteCharacters.length
	var slicesCount = Math.ceil(bytesLength / sliceSize)
	var byteArrays = new Array(slicesCount)

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize
		var end = Math.min(begin + sliceSize, bytesLength)

		var bytes = new Array(end - begin)
		for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0)
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes)
	}
	return new Blob(byteArrays, { type: contentType })
}

export { downloadFile }
