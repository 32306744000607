import { styled as muiStyled } from '@mui/material'
import Icon from '../icon/Icon'
import { New } from '../icon/IconList'

const CouponTag = muiStyled('span')(({ theme }) => {
	return {
		padding: '2px 6px',
		borderRadius: '4px',
		backgroundColor: theme.palette.red[0],
		color: theme.palette.red[400],
		...theme.typography.bold12,
	}
})
const EditableTag = muiStyled('span')(({ theme }) => {
	return {
		position: 'absolute',
		padding: '2px 6px',
		borderRadius: '4px',
		backgroundColor: theme.palette.mint[300],
		color: '#fff',
		boxShadow: '3px 3px 12px 0px rgba(28, 31, 46, 0.06)',
		...theme.typography.semibold14,
	}
})

const UpdateTag = muiStyled('span')(({ theme }) => {
	return {
		top: '-10px',
		left: '-8px',
		position: 'absolute',
		backgroundImage: '',
		borderRadius: '12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '18px',
	}
})
const UpdateTagWithIcon = () => {
	return (
		<UpdateTag>
			<Icon icon={New} />
		</UpdateTag>
	)
}

export { CouponTag, EditableTag, UpdateTag, UpdateTagWithIcon }
