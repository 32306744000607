import { LOGIN_PAGE } from '@/settings/constant'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

const HOME_PATH = '/'
const LOGIN_PATH = LOGIN_PAGE
export const REDIRECT_QUERY = 'redirectPath'
const useRedirect = () => {
	const router = useRouter()
	const currentPathname = router.asPath
	const loginPath = `${LOGIN_PATH}?${REDIRECT_QUERY}=${currentPathname}`

	const redirectPath = router.query[REDIRECT_QUERY]

	const redirectToLogin = useCallback(() => {
		router.push(`${LOGIN_PATH}?${REDIRECT_QUERY}=${currentPathname}`)
	}, [router, currentPathname])
	const redirectTo = useCallback(
		(path: string) => {
			router.push(`${path}?${REDIRECT_QUERY}=${currentPathname}`)
		},
		[router, currentPathname],
	)
	const redirectBack = useCallback(() => {
		if (redirectPath) {
			router.replace(redirectPath as string)
		} else {
			router.replace(HOME_PATH)
		}
	}, [router])

	const redirectAgain = useCallback(
		async (url: string, query?: object) => {
			const hasPath = !!redirectPath

			if (hasPath) {
				await router.push({
					pathname: url,
					query: {
						...query,
						[REDIRECT_QUERY]: redirectPath,
					},
				})
			} else {
				await router.push(url)
			}
		},
		[router],
	)

	return {
		loginPath,
		redirectPath: typeof redirectPath === 'object' ? redirectPath[0] : redirectPath,
		redirectToLogin,
		redirectBack,
		redirectAgain,
		redirectTo,
	}
}

export default useRedirect
