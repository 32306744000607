import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'
import * as React from 'react'
import { createContext, useContext } from 'react'

// const defaultEndpoint = require('../../config/remote.config.json')
// const stagingEndpoint = require('../../config/remote.config.staging.json')

export type APIStatusResponse = {
	code: number
	message: string
}

interface IAPIEndpoint {
	auth: string[]
	news: string[]
	payment: string[]
	market: string[]
	web: string[]
	research: string[]
}

// const useRemoteConfig = (Config.USE_REMOTE_CONFIG && Config.USE_REMOTE_CONFIG === 'true') || false
// console.log('[RestfulApiService] useRemoteConfig', useRemoteConfig)
//
// const defaultEndpoint: IAPIEndpoint = {
// 	auth: [Config.AUTH_BASE_URL],
// 	news: [Config.NEWS_BASE_URL],
// 	payment: [Config.PAYMENT_BASE_URL],
// 	market: [Config.MARKET_BASE_URL],
// 	web: [Config.FRONT_BASE_URL],
// 	research: [Config.MARKET_BASE_URL],
// }

// console.log('[RestfulApiService] defaultEndpoint', defaultEndpoint)

// # STAT
// STAT_KLIP_INTERWORKING_URL=https://dev.d2f9xji6zked30.amplifyapp.com/auth
// STAT_KLIP_WALLET_URL=https://dev.d2f9xji6zked30.amplifyapp.com/wallet
// STAT_MEMBER_GROUP_ID=st
// STAT_SERVICE_GROUP_ID=test-sg
// STAT_NFT_PURCHASE_URL=https://dev.d2f9xji6zked30.amplifyapp.com/bloomingbit/purchase
//

export interface IRestfulApiService {
	fetch<T>(url: string | URL, options: AxiosRequestConfig): AxiosPromise<T>
}

export type RestfulApiServiceOptions = {
	maxRetry: number
}

export enum RestfulApiType {
	AUTH,
	ALL,
}

export type RestfulApiTypeProps = {
	[key in keyof typeof RestfulApiType]: string[]
}

export type LoadBalanceTable = {
	[key in keyof typeof RestfulApiType]: number
}

export const FormSubmitRequest = [
	'/signin',
	'/signup/signup',
	'/signup/sendId',
	'/signup/checkUserId',
	'/signup/Vcccdeehko',
	'/signup/checkVcode',
	'/signup/sendTempPass',
	'/payment/requiredValue',
	'/more/changePassword',
	'/more/apiConnect',
	'/more/apiDisconnect',
	'/more/secession',
	'/more/subscriptionFee',
	'/rank/tradeHistory',
]

export class RestfulApiService implements IRestfulApiService {
	private options: RestfulApiServiceOptions
	private endpoints: RestfulApiTypeProps
	// private client: AxiosInstance
	private loadBalanceTable: LoadBalanceTable
	// private navigationRef: MutableRefObject<NavigationContainerRef>
	// private authStore: AuthStoreType | null
	private client: AxiosInstance

	constructor(
		options: RestfulApiServiceOptions = {
			maxRetry: 3,
		},
	) {
		// console.log('initialize restful api client')
		this.options = options
		this.loadBalanceTable = {
			AUTH: 0,
			ALL: 0,
		}

		this.initialize = this.initialize.bind(this)
		this.setAccessToken = this.setAccessToken.bind(this)
		this.getAccessToken = this.getAccessToken.bind(this)
		this.getClient = this.getClient.bind(this)
		this.setApiEndpoint = this.setApiEndpoint.bind(this)
		this.getEndpoint = this.getEndpoint.bind(this)
		this.fetch = this.fetch.bind(this)
		// this.refreshToken = this.refreshToken.bind(this)

		// this.navigationRef = null
		// this.authStore = null
	}

	// getNavigationRef() {
	// 	return this.navigationRef
	// }

	async initialize() {
		this.client = axios.create()
		this.client.defaults.baseURL = this.getEndpoint(RestfulApiType.ALL)

		this.client.defaults.withCredentials = true
		// this.client.defaults.headers.common['x-app-version'] = DeviceInfo.getVersion()
		// this.client.defaults.headers.common['x-app-platform'] = Platform.OS
		this.client.interceptors.request.use((config) => {
			// log a message before any HTTP request is sent
			// console.log('request: ', config.url, config.params, config.data)
			return config
		})
	}

	setAccessToken(accessToken: string, refreshToken: string) {
		// Alter defaults after instance has been created
		console.log(`setAccessToken ${accessToken} ${refreshToken}`)
		if (!accessToken) {
			delete this.client.defaults.headers.common['Authorization']
			// delete this.client.defaults.headers.common['x-access-token']
		} else {
			this.client.defaults.headers.common['Authorization'] = accessToken
			// this.client.defaults.headers.common['x-access-token'] = accessToken
		}
		// if (!refreshToken) {
		// 	delete this.client.defaults.headers.common['x-refresh-token']
		// } else {
		// 	this.client.defaults.headers.common['x-refresh-token'] = refreshToken
		// }
	}

	getAccessToken(): string {
		return this.client.defaults.headers?.common['Authorization']?.toString()
	}

	getCommonHeader(): Object {
		const { Accept, ...header } = this.client.defaults.headers.common
		return header
	}

	getClient() {
		return this.client
	}

	setApiEndpoint(values: RestfulApiTypeProps) {
		this.endpoints = values
	}

	getEndpoint(type: RestfulApiType) {
		const apiEndpoints = this.endpoints[RestfulApiType[type]]
		const currentIdx = this.loadBalanceTable[RestfulApiType[type]]
		const nextIdx = (currentIdx + 1) % apiEndpoints.length
		this.loadBalanceTable[RestfulApiType[type]] = nextIdx
		return apiEndpoints[currentIdx]
	}

	fetch<T>(url: string | URL, options: AxiosRequestConfig = {}): AxiosPromise<T> {
		return this.client({
			...options,
			headers: { ...options.headers },
			url: url.toString(),
			params: options.params,
			// ...options,
			// headers: { ...options.headers, ...this.client.defaults.headers },
			// url: url.toString(),
		})
	}

	// async getRefreshToken(): Promise<string | null> {
	// 	try {
	// 		return await KeychainStorage.getItem('refresh_token')
	// 	} catch (e) {
	// 		console.error(e)
	// 		return null
	// 	}
	// }
	//
	// async refreshToken(options?: { refreshToken?: string }): Promise<boolean> {
	// 	const authEndpoint = this.getEndpoint(BloomingBitApiType.AUTH)
	//
	// 	let refreshToken
	// 	if (options?.refreshToken) {
	// 		refreshToken = options.refreshToken
	// 	} else {
	// 		try {
	// 			refreshToken = await KeychainStorage.getItem('refresh_token')
	// 		} catch (e) {
	// 			console.error(e)
	// 			return false
	// 		}
	// 	}
	// 	if (!refreshToken) {
	// 		return false
	// 	}
	//
	// 	try {
	// 		const result = await axios(`${authEndpoint}/getToken`, {
	// 			method: 'POST',
	// 			data: { refresh_token: refreshToken },
	// 			headers: {
	// 				'x-error-handled': '1',
	// 			},
	// 		})
	// 		const {
	// 			data: { access_token, result_code },
	// 			status,
	// 		} = result
	// 		if (result_code !== '100') {
	// 			return false
	// 		}
	//
	// 		await KeychainStorage.setItem('access_token', access_token)
	// 		this.setAccessToken(access_token, refreshToken)
	// 		return true
	// 	} catch (e) {
	// 		console.error(e)
	// 		return false
	// 	}
	// }
}

export type RestfulApiServiceType = typeof RestfulApiService

export const RestfulContext = createContext<IRestfulApiService | null>(null)

export const RestfulApiProvider: React.FC<{
	children: React.ReactNode
	value: IRestfulApiService
}> = ({ value, children }) => {
	return <RestfulContext.Provider value={value}>{children}</RestfulContext.Provider>
}

export const useApiService = (): IRestfulApiService => {
	const store = useContext(RestfulContext)
	if (!store) {
		// this is especially useful in TypeScript so you don't need to be checking for null all the time
		throw new Error('useStore must be used within a StoreProvider.')
	}
	return store
}

export const isRestfulApiService = (value: any): value is IRestfulApiService => {
	return value instanceof RestfulApiService
}
