import { useLibrarySubject } from '@/modules/mypages/libraries/components'
import { CLASS_INDEX_PAGE } from '@/settings/constant'
import { useMutation } from '@tanstack/react-query'
import { message } from 'antd'
import { useRouter } from 'next/router'
import { FC, FormEventHandler, useCallback } from 'react'
import { postClass } from 'src/api/class'
import { LibrarySearchType } from 'src/api/library'
import useClassStore from 'src/store/class'
import styled from 'styled-components'
import { ModalTitle, NewClassActionsContainer, TextField } from '.'
import FilledButton from '../common/Button/FilledButton'

const NewClassForm = styled.form`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

const NewClassStep1: FC<{ onCancel: () => void }> = ({ onCancel }) => {
	const { name, isWithSelected } = useClassStore((state) => state.adding)
	const selected = useClassStore((state) => state.selected)
	const { setAdding, closeNewClass } = useClassStore(({ setAdding, closeNewClass }) => ({
		setAdding,
		closeNewClass,
	}))

	const { push } = useRouter()
	const subject = useLibrarySubject()
	const classMutation = useMutation(postClass)

	const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		async (e) => {
			e.preventDefault()

			if (name.length >= 3) {
				if (isWithSelected) {
					const cls = await classMutation.mutateAsync({
						name,
						subject,
						choices: Object.entries(selected).map(([k, v]) => ({
							sourceId: k,
							sourceType: v as LibrarySearchType,
						})),
					})

					closeNewClass()

					message.info('클래스가 생성됐어요.')
					push(CLASS_INDEX_PAGE.concat(`/${cls.id}`))
				} else {
					setAdding({ step: 2 })
				}
			} else {
				message.warning('클래스 이름은 최소 3글자입니다.')
			}
		},
		[name, setAdding, isWithSelected, classMutation, subject, selected, push, closeNewClass],
	)

	return (
		<>
			<NewClassForm onSubmit={handleSubmit}>
				{/* <NewClassStepper /> */}
				<NewClassName />
				<NewClassActionsContainer>
					<FilledButton type="text" variant="soft" onClick={onCancel}>
						취소
					</FilledButton>
					<FilledButton
						type="text"
						variant="solid"
						disabled={!name.length}
						htmlType="submit">
						다음
					</FilledButton>
				</NewClassActionsContainer>
			</NewClassForm>
		</>
	)
}

export default NewClassStep1

const NewClassNameFieldset = styled.fieldset`
	display: flex;
	flex-direction: column;
	gap: 4px;
	flex-grow: 1;
	margin-bottom: 60px;

	@media (max-width: 768px) {
		margin-bottom: 20px;
	}
`
const NewClassNameLegend = styled.legend`
	color: ${(props) => props.theme.new.colors.sol_gray_900};
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: -0.14px;

	.required {
		margin: 0 2px;
		color: ${(props) => props.theme.new.colors.sol_mint_400};
	}
`
const NewClassHelperText = styled.p`
	margin-left: 4px;
	color: ${(props) => props.theme.new.colors.sol_gray_500};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.14px;
`

const NewClassName: FC = () => {
	const { name } = useClassStore((state) => state.adding)

	return (
		<>
			<ModalTitle>
				새 <strong>클래스 이름</strong>을<br />
				입력해주세요.
			</ModalTitle>

			<NewClassNameFieldset>
				<NewClassNameLegend>
					클래스 이름<span className="required">*</span>
				</NewClassNameLegend>
				<TextField
					placeholder="예비 고2 선행반"
					name={'name'}
					value={name}
					maxLength={10}
				/>
				<NewClassHelperText>클래스 이름은 최대 10글자입니다.</NewClassHelperText>
			</NewClassNameFieldset>
		</>
	)
}
