import Cookies from 'js-cookie'
import { cookieOptions, otherApiClient } from '.'
import { Ticket } from './membership'

export interface MemberAddress {
	name: string
	phone: string
	address1: string
	address2: string
	postalCode: string
	message: string
}

export interface UserInfo {
	displayName: string | null | undefined
	email: string | null | undefined
	phoneNumber: string | null | undefined
	photoURL: string | null | undefined
	providerId: string | null | undefined
	uid: string | null | undefined
	id: number
	picture?: string | undefined
	emailVerified: boolean
	isAnonymous?: boolean
	providerData?: object
	role?: string | null
	scope?: string | string[] | null
	job?: string
	mainSubject?: string
	flags?: {
		[key: string]: boolean
	}
	address?: null | MemberAddress
}

export interface UserInfoRes {
	nickname: string
	email: string
	phone: string
	provider: {
		channel: string
		kakao_id: string
	}
	uid: string
	option: {
		use_lab: boolean
	}
	job: string
	mainSubject: string
	userId: number
	id: number
	picture: string
	emailVerified: boolean
	isAnonymous: boolean
	providerData?: object
	role?: string
	scope?: string
	flags?: {
		[key: string]: boolean
	}
	address: UserInfo['address']
	solvookpluses: Array<Ticket>
	tickets: Array<Ticket>
	experts: Array<Ticket>
	schoolItems?: Array<Record<'id' | 'name' | 'address', string>>
}

export const getMe = async (accessToken?: string) => {
	Cookies.set('accessToken', accessToken, {
		...cookieOptions,
	})
	const headers = {
		Authorization: accessToken,
	}
	const { data } = await otherApiClient.market.get<UserInfoRes>(`/users/meta`, { headers })

	return {
		user: {
			displayName: data.nickname,
			email: data.email,
			phoneNumber: data.phone,
			photoURL: null,
			providerId: data.provider.channel,
			uid: data.uid,
			mainSubject: data.mainSubject,
			job: data.job,
			id: data.userId,
			emailVerified: data.emailVerified,
			address: data.address,
			flags: {
				is_use_lab: data?.option?.use_lab,
			},
		},
	}
}
