import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { MyClickedProperty } from '@/lib/mixpanels/mixpanelType'
import {
	MYPAGE_COUPONS,
	MYPAGE_PAGE,
	MYPAGE_PASSES,
	MYPAGE_STORES_PRODUCTS_INDEX,
} from '@/settings/constant'
import { MenuProps } from 'antd'
import Link from 'next/link'

export const utilItems_default: MenuProps['items'] = [
	{
		key: '0',
		label: (
			<Link
				onClick={() =>
					mixpanelEvent('MyClicked', {
						'Menu Category': 'library',
					} as MyClickedProperty)
				}
				href={MYPAGE_PAGE}>
				마이쏠북
			</Link>
		),
	},
	{
		key: '1',
		label: (
			<Link
				onClick={() => {
					mixpanelEvent('MyClicked', {
						'Menu Category': 'coupons',
					} as MyClickedProperty)

					mixpanelEvent('GNB Button Clicked', {
						'Button Name': '쿠폰 관리',
					})
				}}
				href={MYPAGE_COUPONS}>
				쿠폰 관리
			</Link>
		),
	},
	{
		key: '2',
		label: (
			<Link
				onClick={() =>
					mixpanelEvent('MyClicked', {
						'Menu Category': 'packages',
					} as MyClickedProperty)
				}
				href={MYPAGE_PASSES}>
				이용권 관리
			</Link>
		),
	},

	{
		key: '4',
		label: (
			<Link
				onClick={() =>
					mixpanelEvent('MyClicked', {
						'Menu Category': 'store',
					} as MyClickedProperty)
				}
				href={MYPAGE_STORES_PRODUCTS_INDEX}>
				자료판매
			</Link>
		),
	},
	// {
	// 	key: '4',
	// 	label: (
	// 		<Link
	// 			onClick={() =>
	// 				mixpanelEvent('MyClicked', {
	// 					'Menu Category': 'account',
	// 				} as MyClickedProperty)
	// 			}
	// 			href={MYPAGE_ACCOUNTS_INDEX_PAGE}>
	// 			계정관리
	// 		</Link>
	// 	),
	// },
	{
		// todo: @button onClick 이벤트로 logout function 호출
		key: 'logout',
		label: <Link href="/">로그아웃</Link>,
	},
]
