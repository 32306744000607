import { getCookie, setCookie } from './sessions'

export const DEFAULT_FIREBASE_COOKIE = '__session' //firebase only accept __session cookie

export const setFirebaseCookie = (key: string, token: string) => {
	// get cookie from __session using getCookie function
	// parse the data from cookie
	// assign the token to the key
	// call set cookie function with the new data

	try {
		const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE)
		const data = cookieData ? JSON.parse(cookieData) : {}
		data[key] = token
		setCookie(DEFAULT_FIREBASE_COOKIE, data)
	} catch (error) {
		console.log(error, 'setFirebaseCookie')
	}
}

export const removeFirebaseCookie = (key: string) => {
	// get cookie from __session  using getCookie function
	// parse the data from cookie
	// remove the key from the data
	// call set cookie function with the new data
	try {
		const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE)
		const data = cookieData ? JSON.parse(cookieData) : {}
		if (data && data.hasOwnProperty(key)) {
			delete data[key]
			setCookie(DEFAULT_FIREBASE_COOKIE, data)
		} else {
			console.log(`${key} is probably not available inside the cookie removeFirebaseCookie`)
		}
	} catch (error) {
		console.log(error, 'removeFirebaseCookie')
	}
}

export const getFirebaseCookieFromClient = (key: string) => {
	// get cookie from __session using getCookie function
	// parse the data from cookie
	// get the relatedData using the key
	try {
		const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE)
		const data = cookieData ? JSON.parse(cookieData) : {}
		if (data && data.hasOwnProperty(key)) {
			return data[key]
		} else {
			console.log(`${key} is probably not available inside the cookie getFirebaseCookie`)
		}
	} catch (error) {
		console.log(error, 'getFirebaseCookie')
	}
}
