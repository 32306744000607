import { toQueryStringCamel } from '../utils/misc'
import { otherApiClient } from './index'
import { Subjects } from './product'

export type AdBannerPosition = 'productDetail' | 'globalMenu' | 'moSide' | 'search' | 'solvookPlus'

export interface getAdBannerRequest {
	position: AdBannerPosition
	subject?: Subjects
}

export interface IAdBannerItem {
	id: string
	link: string
	imageUrl: string
}

export interface getAdBannerItemResponse {
	items: Array<IAdBannerItem>
}

export const getAdBanner = async ({ position, subject }: getAdBannerRequest) => {
	const { data } = await otherApiClient.market<getAdBannerItemResponse>(
		`/adBanners/${toQueryStringCamel({ position, subject })}`,
	)
	return data
}
