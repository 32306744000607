import { Children, isValidElement, ReactNode, useCallback, useMemo, useState } from 'react'

interface FunnelProps<T extends readonly string[]> {
	step?: T[number]
	children?: ReactNode
}

interface StepProps<T extends readonly string[]> {
	name: T[number]
	children?: ReactNode
}

export const Funnel = <T extends readonly string[]>({ step, children }: FunnelProps<T>) => {
	const validElement = Children.toArray(children).filter(isValidElement)
	const targetElement = validElement.find((child) => (child.props as StepProps<T>)?.name === step)
	if (!targetElement) {
		return null
	}

	return <>{targetElement}</>
}

export const Step = <T extends readonly string[]>({ children }: StepProps<T>) => {
	return <>{children}</>
}

export const useFunnel = <T extends readonly string[]>(steps: T, defaultStep: T[number]) => {
	const [step, setStep] = useState(defaultStep)

	const FunnelElement = useMemo(
		() =>
			Object.assign(
				(props: Omit<FunnelProps<T>, 'step'>) => {
					return <Funnel step={step} {...props} />
				},
				{ Step: (props: StepProps<T>) => <Step<T> {...props} /> },
			),
		[step],
	)

	const setStepMemoized = useCallback((newStep: T[number]) => {
		setStep(newStep)
	}, [])

	return [FunnelElement, setStepMemoized, step] as const
}
