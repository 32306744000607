import React, { createContext, useContext, useEffect } from 'react'
import { useLocalObservable } from 'mobx-react-lite'

import App, { AppContext, AppProps } from 'next/app'
import { NextPageContext } from 'next'

import { UAParser } from 'ua-parser-js'
import { debounce } from 'debounce'

export interface UserAgent {
	readonly source: string
	readonly deviceType?: string
	readonly deviceVendor?: string
	readonly os: string
	readonly osVersion: number
	readonly browser: string
	readonly browserVersion: number
	readonly isIphone: boolean
	readonly isIpad: boolean
	readonly isMobile: boolean
	readonly isTablet: boolean
	readonly isDesktop: boolean
	readonly isBot: boolean
	readonly isChrome: boolean
	readonly isFirefox: boolean
	readonly isSafari: boolean
	readonly isIE: boolean
	readonly isMac: boolean
	readonly isChromeOS: boolean
	readonly isWindows: boolean
	readonly isIos: boolean
	readonly isAndroid: boolean
}

export type UserAgentProps = { ua?: UserAgent }

const UserAgentModel = (props: UserAgentProps) => ({
	_ua: props.ua,
	get source() {
		return this._ua?.source
	},
	get userAgent(): UserAgent | undefined {
		return this._ua
	},
	updateSource(uaString: string) {
		console.log('updateSource', uaString)
		this._ua = parse(uaString)
	},
})

export type UserAgentModelType = ReturnType<typeof UserAgentModel>

const UserAgentStore = createContext<UserAgentModelType | null>(null)

export const UserAgentProvider: React.FC<{
	children: React.ReactNode
	initialValues: UserAgentProps
}> = ({ initialValues, children }) => {
	const store = useLocalObservable(() => UserAgentModel(initialValues))
	return <UserAgentStore.Provider value={store}> {children}</UserAgentStore.Provider>
}

export const useUserAgent = () => {
	const store = useContext(UserAgentStore)
	if (!store) {
		throw new Error('useStore must be used within a StoreProvider.')
	}

	useEffect(() => {
		// window.addEventListener('orientationchange', function() {
		// 	// orientationChanged = true
		// 	console.log('orientationchange')
		// 	// setChangedView(true)
		// })
		const resize = debounce(() => {
			console.log('resized')
			store.updateSource(window.navigator.userAgent)
		}, 150)

		window.addEventListener('resize', resize)

		return () => {
			window.removeEventListener('resize', resize)
		}

		// @ts-ignore
	}, [])

	return store
}

const getDisplayName = (Component: any) => Component.displayName || Component.name || 'Unknown'

export interface WithAgentProps {
	ua: UserAgent
}

// only use appcontenx
export function withUserAgent(WrappedComponent: typeof App) {
	return class WithUserAgentComponent extends App<AppProps & { ua: UserAgent }, {}, {}> {
		static displayName = `WithUserAgent(${getDisplayName(WrappedComponent)})`

		static getInitialProps = async (pageCtx: UserAgentAppContext) => {
			const ctx = 'Component' in pageCtx ? pageCtx.ctx : pageCtx
			// const ctx = 'Component' in pageCtx ? pageCtx.ctx : pageCtx

			let uaString
			if (typeof window === 'undefined') {
				// @ts-ignore
				uaString = ctx.req?.headers['user-agent']
			} else {
				uaString = window.navigator.userAgent
			}

			const pageProps = await WrappedComponent.getInitialProps(pageCtx as any)
			if (uaString) {
				const ua: UserAgent = parse(uaString!)

				// if (WrappedComponent as NextPage) {
				// const nextPage: NextPage = WrappedComponent as NextPage
				// if (nextPage && nextPage.getInitialProps) {

				// @ts-ignore
				ctx.ua = ua
				return {
					...pageProps,
					ua,
				}
			} else {
				return {
					...pageProps,
				}
			}
		}

		render() {
			const props = { ...this.props }
			return <WrappedComponent {...(props as AppProps)} />
		}
	}
}

export interface UserAgentPageContext extends NextPageContext {
	ua: UserAgent
}
export interface UserAgentAppContext extends AppContext {
	ctx: UserAgentPageContext
}
export declare type UserAgentContext = UserAgentAppContext | UserAgentPageContext

/**
 * Get the information of an useragent string.
 *
 * @param phase user agent strings.
 * @returns parsed information.
 */
export function parse(phase: string): UserAgent {
	const result = new UAParser(phase).getResult()

	const regex = new RegExp(`(${BOT_UA.join('|')})`, 'ig')
	const isBot = phase ? regex.test(phase.toLowerCase()) : false

	const browser: string = result.browser.name!
	const deviceType: string = result.device.type!
	const os: string = result.os.name!
	const isMobile: boolean = deviceType === 'mobile'
	const isTablet: boolean = deviceType === 'tablet'
	const isIos: boolean = os === 'iOS'

	const ua: UserAgent = Object.freeze({
		browser,
		deviceType,
		os,
		isMobile,
		isTablet,
		isIos,
		source: phase,
		deviceVendor: result.device.vendor,
		osVersion: parseInt(result.os.version!, 10),
		browserVersion: parseFloat(result.browser.version!),
		isIphone: isMobile && isIos,
		isIpad: isTablet && isIos,
		isDesktop: !isMobile && !isTablet,
		isChrome: browser === 'Chrome',
		isFirefox: browser === 'Firefox',
		isSafari: browser === 'Safari',
		isIE: browser === 'IE',
		isMac: os === 'Mac OS',
		isChromeOS: os === 'Chromium OS',
		isWindows: os === 'Windows',
		isAndroid: os === 'Android',
		isBot: isBot,
	})

	return ua
}

export const BOT_UA = [
	'\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
	'googlebot',
	'baiduspider',
	'gurujibot',
	'yandexbot',
	'slurp',
	'msnbot',
	'bingbot',
	'facebookexternalhit',
	'linkedinbot',
	'twitterbot',
	'slackbot',
	'telegrambot',
	'applebot',
	'pingdom',
	'tumblr',
]
