import defaultTheme from './default.theme'

declare module 'styled-components' {
	export interface ITheme {
		breakpoints?: string[]
		background?: string[]
		primary?: string[]
		color?: string[]
		info?: string[]
		warning?: string[]
		success?: string[]
		error?: string[]
		text?: string[]
		border?: string[]
		boxShadow?: string[]
		font?: string[]
		prices?: string[]
		black?: string[]
		white?: string[]
		red?: string[]
		blue?: string[]
		green?: string[]
		new: {
			colors: {
				white: string
				primary: string
				subPrimary: string
				darkBlack: string
				black: string
				gray900: string
				gray700: string
				gray650: string
				gray600: string
				gray500: string
				gray400: string
				gray350: string
				gray300: string
				gray290: string
				gray270: string
				gray250: string
				gray230: string
				gray200: string
				gray180: string
				gray150: string
				gray100: string
				gray50: string
				gray30: string
				blue800: string
				blue700: string
				blue500: string
				blue200: string
				blue100: string
				blue50: string
				indigo: string
				indigo900: string
				indigo800: string
				indigo700: string
				indigo600: string
				indigo500: string
				indigo200: string
				indigo50: string
				sky300: string
				sky200: string
				sky100: string
				mint600: string
				mint500: string
				mint400: string
				mint300: string
				mint200: string
				mint100: string
				mint50: string
				mint: string
				green: string
				green200: string
				green700: string
				red: string
				yellow: string
				yellow500: string
				yellow400: string
				yellow300: string
				yellow50: string
				pink: string
				orange: string
				gold: string
				silver: string
				bronze: string
				brown: string
				sol_dim_gray: string
				sol_gray_0: string
				sol_gray_50: string
				sol_gray_100: string
				sol_gray_200: string
				sol_gray_300: string
				sol_gray_400: string
				sol_gray_500: string
				sol_gray_600: string
				sol_gray_700: string
				sol_gray_800: string
				sol_gray_900: string
				sol_indigo_0: string
				sol_indigo_50: string
				sol_indigo_100: string
				sol_indigo_200: string
				sol_indigo_300: string
				sol_indigo_400: string
				sol_indigo_500: string
				sol_indigo_600: string
				sol_indigo_700: string // primary
				sol_indigo_800: string
				sol_indigo_900: string
				sol_mint_0: string
				sol_mint_50: string
				sol_mint_100: string
				sol_mint_200: string
				sol_mint_300: string // subPrimary
				sol_mint_400: string
				sol_mint_500: string
				sol_mint_600: string
				sol_mint_700: string
				sol_mint_800: string
				sol_mint_900: string
				sol_blue_0: string
				sol_blue_50: string
				sol_blue_100: string
				sol_blue_200: string
				sol_blue_300: string
				sol_blue_400: string
				sol_blue_500: string
				sol_blue_600: string
				sol_blue_700: string
				sol_blue_800: string
				sol_blue_900: string
				sol_yellow_0: string
				sol_yellow_50: string
				sol_yellow_100: string
				sol_yellow_200: string
				sol_yellow_300: string
				sol_yellow_400: string
				sol_yellow_500: string
				sol_yellow_600: string
				sol_yellow_700: string
				sol_yellow_800: string
				sol_yellow_900: string
				sol_red_0: string
				sol_red_50: string
				sol_red_100: string
				sol_red_200: string
				sol_red_300: string
				sol_red_400: string
				sol_red_500: string
				sol_red_600: string
				sol_red_700: string
				sol_red_800: string
				sol_red_900: string
				sol_dim_white_90: string
				border: string
				bg: {
					blue: string
					gray: string
					green: string
					yellow: string
					red: string
				}
			}
		}
	}
}

const index = {
	defaultTheme,
}
export default index
