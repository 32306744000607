import { styled, Typography } from '@mui/material'

export const ChipItem = ({ children, bgColor, color }) => {
	return <Chip style={{ backgroundColor: bgColor, color: color }}>{children}</Chip>
}
export const Chip = styled('span')(({ theme }) => {
	return {
		padding: '2px 8px',
		borderRadius: '4px',
		marginBottom: '8px',
		display: 'inline-block',
		...theme.typography.subtitle2,
	}
})

export const KeywordChip = ({ text, onClick }) => {
	return (
		<Typography
			onClick={onClick}
			component={'li'}
			variant={'subtitle1'}
			sx={(theme) => {
				return {
					backgroundColor: '#fff',
					color: theme.palette.secondary['main'],
					padding: '8px 12px',
					borderRadius: '6px',
					border: `1px solid ${theme.palette.grey[200]}`,
					cursor: 'pointer',
					display: 'flex',
					'&:hover': {
						backgroundColor: theme.palette.grey[50],
					},
				}
			}}>
			#
			<Typography
				variant={'body1'}
				sx={(theme) => {
					return {
						marginLeft: '6px',
						color: theme.palette.text['title'],
					}
				}}>
				{text}
			</Typography>
		</Typography>
	)
}
