import { PropsWithChildren, ReactNode } from 'react'

import { OnClickProps } from '../../utils/misc'
import Overlay, { OverlayClose, OverlayProps, useCloseOverlay } from '../common/Overlay'
import styled, { css } from 'styled-components'

type Props = {
	title?: string | ReactNode
	width?: number
	modalBgColor?: string
}

export type ModalProps = OverlayProps

const Modal = ({
	title,
	width,
	children,
	modalBgColor,
	...props
}: PropsWithChildren<Props> & ModalProps) => {
	const { open } = props

	return (
		<StyledOverlay {...props}>
			<StyledDiv open={open} width={width} style={{ backgroundColor: modalBgColor }}>
				{title && (
					<header>
						<h4>{title}</h4>
						<OverlayClose {...props} />
					</header>
				)}
				<div>{children}</div>
			</StyledDiv>
		</StyledOverlay>
	)
}

export default Modal
const StyledDiv = styled.div<{ open: boolean; width?: number }>`
	position: relative;
	border-radius: 12px;
	background-color: #fff;
	color: #222;
	${({ width }) => width && `width: ${width}px;`}
	//max-width: 90%;
	max-height: 100%;
	margin: 0 auto;
	transition: 0.3s;
	will-change: transform;
	overflow: hidden;
	@media (max-width: 1200px) {
		${({ width }) => width && `width: ${width}px;`}
	}
	${({ open }) =>
		open
			? css`
					transform: scale(1);
					visibility: visible;
			  `
			: css`
					transition: 0.25s;
					transform: scale(0.4);
					opacity: 0;
					visibility: hidden;
			  `};
	header {
		position: relative;
		justify-content: space-between;
		padding: 0 16px;
		height: 44px;
		border-bottom: 1px solid #e3e3e3;
		background-color: #fff;

		h4 {
			text-align: center;
			line-height: 44px;
			margin-bottom: 0;
		}

		button {
			position: absolute;
			top: 10px;
			right: 15px;
		}
	}

	> div {
		overflow: auto;
	}
`
const StyledOverlay = styled(Overlay)<{ open: boolean }>`
	display: flex;
	align-items: center;
	padding: 40px;
`
/** Button */

export type ButtonProps = {
	// type?: ButtonType;
	text?: string
	color?: string
	backgroundColor?: string
	disabled?: boolean
	children?: ReactNode
}

export const ModalButton = ({ onClick, ...props }: ButtonProps & OnClickProps) => {
	const closeOverlay = useCloseOverlay()

	return (
		<StyledModalButton>
			<button {...props} onClick={onClick ?? (() => closeOverlay())} />
		</StyledModalButton>
	)
}
const StyledModalButton = styled.div`
	margin-top: 24px;
`
type ModalTitleProps = {
	text: string | ReactNode
}

export const ModalTitle = ({ text }: ModalTitleProps) => {
	return <StyledModal className="multiline">{text}</StyledModal>
}

const StyledModal = styled.h4`
	text-align: center;
	padding-bottom: 24px;
`
