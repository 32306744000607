import { Button, TextField } from '@bookips/solvook-ui-library'
import { MenuItem, styled } from '@mui/material'
import { treeItemClasses } from '@mui/x-tree-view'
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2'

export const ButtonWithDropdownMenu = styled(Button)(({ theme }) => ({
	border: `1px solid ${theme.palette.grey[100]}`,
	backgroundColor: '#fff',
	borderRadius: '6px',
	padding: '6px 12px',
	color: theme.palette.grey[500],
	transition: 'all 0.3s ease',
	...theme.typography.regular16,
	svg: {
		marginLeft: '4px',
	},
	'&.selected, [aria-expanded="false"]': {
		fontWeight: 600,
		color: theme.palette.primary['main'],
		backgroundColor: theme.palette.indigo[0],
		borderColor: theme.palette.indigo[200],
		svg: {
			stroke: theme.palette.primary['main'],
		},
	},
	'&[aria-expanded="true"]': {
		fontWeight: 600,
		color: '#fff',
		backgroundColor: theme.palette.primary['main'],
		svg: {
			transform: 'rotate(180deg)',
			transition: 'all 0.3s ease',
			stroke: '#fff',
		},
	},
	'&:hover': {
		backgroundColor: theme.palette.indigo[0],
		color: theme.palette.primary['main'],
		fontWeight: 600,
		svg: {
			stroke: theme.palette.primary['main'],
		},
	},
}))

export const CustomTreeItem = styled(TreeItem2)(({ theme }) => {
	return {
		[`& .${treeItemClasses.iconContainer}`]: {
			'& .close': {
				opacity: 0.3,
			},
		},
		'&.MuiTreeItem-root': {
			display: 'flex',
			flexDirection: 'column',
			gap: '16px',

			[`& .${treeItemClasses.content}`]: {
				padding: '0',
				flexDirection: 'row-reverse',
				':hover': {
					backgroundColor: 'transparent',
					'& .MuiTreeItem-label': {
						transition: 'all 0.3s',
						color: theme.palette.text['title'],
					},
					i: {
						svg: {
							transition: 'all 0.3s',
							stroke: theme.palette.text['title'],
						},
					},
				},
				'& .MuiTreeItem-label': {
					color: theme.palette.text['secondary'],
					...theme.typography.body2,
				},
				'&.Mui-focused': {
					backgroundColor: 'transparent',
				},
				'&.Mui-expanded': {
					'.MuiTreeItem-label': {
						color: theme.palette.text['title'],
					},
				},
				'&.Mui-selected': {
					backgroundColor: 'transparent !important',

					'& .MuiTreeItem-label': {
						fontWeight: '600',
						color: theme.palette.primary['main'],
					},
				},
				[`&.Mui-selected)`]: {
					'& .MuiTreeItem-label': {
						fontWeight: '600',
						color: theme.palette.text['title'],
					},
				},
			},
		},
		'& .MuiCollapse-root': {
			paddingLeft: '16px',
			'& .MuiCollapse-wrapperInner': {
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			},
		},
	}
})
export const CustomTextField = styled(TextField)(({ theme }) => ({
	fieldset: {
		border: 'none',
	},
	'.MuiInputBase-input': {
		...theme.typography.body2,
		color: theme.palette.text['secondary'],
	},
	'.MuiOutlinedInput-root': {
		backgroundColor: 'transparent',
		'> div': {
			paddingTop: '0px',
			paddingBottom: '0px',
			paddingRight: '20px !important',
		},
		'.MuiSelect-icon': {
			top: 'unset',
			color: theme.palette.text['secondary'],
			right: 0,
		},
	},
	'& .MuiSelect-root': {
		'.MuiSelect-select': {
			paddingTop: '0',
			paddingBottom: '0',
		},
	},
}))
export const CustomSelectMenuItem = styled(MenuItem)(({ theme }) => ({
	'&.select': {
		padding: '5px 12px',
	},
	...theme.typography.body2,
	color: theme.palette.text['secondary'],
	'.MuiMenuItem-root': {
		padding: '5px 12px',
	},
	li: {
		padding: '5px 12px !important',
	},
}))

export const CustomSelect = () => {
	return <></>
}
CustomSelect.CustomTextField = CustomTextField
CustomSelect.CustomSelectMenuItem = CustomSelectMenuItem

export const SearchResultAmount = styled('div')(({ theme }) => ({
	color: theme.palette.text['secondary'],
	...theme.typography.body2,
}))

export const SearchResultDescWrap = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: 12px;
	width: 100%;
	margin-top: 24px;
`
