import { Skeleton, styled } from '@mui/material'

export const CategoryListPageSkeletonItem = () => {
	return (
		<SkeletonItemContainer>
			<Skeleton variant="rectangular" height={224} width={'100%'} />
			<Skeleton variant="rectangular" height={26} width={137} sx={{ marginTop: '8px' }} />
			<Skeleton variant="rectangular" height={48} width={'100%'} sx={{ marginTop: '8px' }} />
			<Skeleton variant="rectangular" height={20} width={64} sx={{ marginTop: '4px' }} />
			<Skeleton variant="rectangular" height={26} width={144} sx={{ marginTop: '16px' }} />
		</SkeletonItemContainer>
	)
}
export const SkeletonItemContainer = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 180px;
`
