import React from 'react'
import { AdBannerList, AdBannerShape, useAdBannerQuery } from '.'
import { getAdBannerRequest } from '../../../api/adBanner'

export interface AdBannerProps extends getAdBannerRequest {
	shape?: AdBannerShape
	mixpanelPosition: string
}

//디폴트 배너
export const AdBanner = ({
	shape = 'default',
	position,
	subject,
	mixpanelPosition,
}: AdBannerProps) => {
	const { isAdBannerLoading, isAdBannerError, adBannerResponse, isAdBannerSuccess, noAdItems } =
		useAdBannerQuery({ position, subject })
	if (noAdItems) return null
	return (
		<AdBannerList position={position}>
			{adBannerResponse.items.map((banner, index) => {
				const { link, imageUrl, id } = banner
				return (
					<AdBannerList.Item
						link={link}
						imageUrl={imageUrl}
						shape={shape}
						key={'adbanner-' + id}
						id={id}
						mixpanelPosition={mixpanelPosition}
					/>
				)
			})}
		</AdBannerList>
	)
}
