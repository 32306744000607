import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import { themeGet } from '@styled-system/theme-get'

export interface DefaultLoadingContainerProps {
	showIndicator?: boolean
	height?: number
	title: string
}

const DefaultLoadingContainerWrapper = styled.div``

const LoadingWrapper = styled.div<{ height?: number }>`
	min-height: ${({ height }) => (height ? height : 480)}px;
	height: 100%;
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;
`

const LoadingTitle = styled.p`
	margin-top: 24px;
	color: ${themeGet('text.2', '#1B1B29')};
`

export const DefaultLoadingContainer: React.FC<DefaultLoadingContainerProps> = ({
	title,
	height,
	showIndicator = true,
}) => {
	return (
		<DefaultLoadingContainerWrapper>
			<LoadingWrapper height={height}>
				<Spin />
				{/*{showIndicator && <ActivityIndicator fontSize={24} />}*/}
				<LoadingTitle>{title}</LoadingTitle>
			</LoadingWrapper>
		</DefaultLoadingContainerWrapper>
	)
}
