import { Class } from 'src/api/class'
import { Library } from 'src/api/library'
import { itemDTO } from 'src/api/main'
import { create } from 'zustand'

export interface ClassStoreState {
	adding: { name?: string; step?: number; open?: boolean; isWithSelected?: boolean }
	mine?: Array<Class>
	selected: Record<Library['sourceId'], Library['sourceType']>
	userSourceBooks: { type?: 'new' | 'modify'; books?: Array<itemDTO> }
}
export interface ClassStoreActions {
	setAdding: (newValue: ClassStoreState['adding']) => void
	openNewClass: (isWithSelected?: boolean) => void
	closeNewClass: () => void

	setMine: (classes: Array<Class>) => void
	setSelected: (selected: ClassStoreState['selected']) => void
	toggleSelected: (library: Pick<Library, 'sourceId' | 'sourceType'>) => void
	setUserSourceBooks: (userSourceBooks: ClassStoreState['userSourceBooks']) => void
}

const useClassStore = create<ClassStoreState & ClassStoreActions>((set) => ({
	adding: { name: '', step: 0 },
	selected: {},
	userSourceBooks: {},

	setAdding: (newValue) =>
		set((state) => ({ ...state, adding: { ...state.adding, ...newValue } })),
	openNewClass: (isWithSelected) =>
		set((state) => ({
			...state,
			adding: { ...state.adding, open: true, isWithSelected: !!isWithSelected },
		})),
	closeNewClass: () => set((state) => ({ ...state, adding: { ...state.adding, open: false } })),

	setMine: (classes) => set((state) => ({ ...state, mine: classes })),
	setSelected: (selected) => set((state) => ({ ...state, selected })),
	toggleSelected: ({ sourceId, sourceType }) =>
		set((state) => {
			const result = { ...state.selected }

			if (result[sourceId]) {
				delete result[sourceId]
			} else {
				result[sourceId] = sourceType
			}

			return { ...state, selected: result }
		}),

	setUserSourceBooks: (userSourceBooks) =>
		set((state) => ({
			...state,
			userSourceBooks: { ...state.userSourceBooks, ...userSourceBooks },
		})),
}))

export default useClassStore
