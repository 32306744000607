import { HandoutCoupon } from 'src/api/coupon'
import { Ticket } from 'src/api/membership'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export enum PassTab {
	USETICKET,
	BUYSINGLE,
}

export interface PassSectionState {
	currentTab: PassTab
	selectedPass: number | null
	isShowNotAvailablePass: boolean
	tickets: Array<Ticket>
	solvook: Array<Ticket>
	handoutCoupons: Array<HandoutCoupon>
	isCouponDialogOpen: boolean
}
export interface PassSectionAction {
	setState: <T extends keyof PassSectionState>(key: T, param: PassSectionState[T]) => void
	reset: () => void
}

export const useMembershipStore = create<PassSectionState & PassSectionAction>()(
	devtools((set, get) => ({
		currentTab: PassTab.BUYSINGLE,
		selectedPass: null,
		isShowNotAvailablePass: false,
		tickets: [],
		solvook: [],
		handoutCoupons: [],
		isCouponDialogOpen: false,
		setState: (key, param) => {
			set((state) => ({ ...state, [key]: param }))
		},
		reset: () => {
			set((state) => ({
				...state,
				currentTab: PassTab.BUYSINGLE,
				isShowNotAvailablePass: false,
				// selectedPass: null,
				// tickets: [],
				// solvook: [],
			}))
		},
	})),
)
