import { ReactNode } from 'react'

interface StepProps {
	condition: boolean
	children: ReactNode
}
//스텝 렌더 컴포넌트
export const Step: React.FC<StepProps> = ({ condition, children }) => {
	if (condition) return <>{children}</>
	return <></>
}
