import Icon from '@/components/icon/Icon'
import { ArrowRight, Sizes } from '@/components/icon/IconList'
import Link from 'next/link'
import React, { ReactNode, useRef } from 'react'
import { useTheme } from 'styled-components'
import { useResizeObserver } from 'usehooks-ts'
import { WarnBoxLayout } from '../../styles/ProductDetail.style'

interface WarnBoxProps {
	productType: string
}
const WarnBox: React.FC<WarnBoxProps> = ({ productType }) => {
	const theme = useTheme()
	const ref = useRef<HTMLDivElement>(null)

	const { width = 0 } = useResizeObserver({
		ref,
		box: 'border-box',
	})
	const DefaultWarnBox = ({ children }: { children: ReactNode }) => {
		return (
			<WarnBoxLayout ref={ref}>
				<div className={'license'}>
					<h5>구매 시 확인해주세요!</h5>
					{children}
				</div>
			</WarnBoxLayout>
		)
	}
	switch (productType) {
		case 'aladinbook':
			return (
				<DefaultWarnBox>
					<p>
						<b>실물 교재 혹은 도서를 배송 받는 상품입니다.</b>&nbsp;상품 수령까지 영업일
						기준 2~4일 정도 소요됩니다.
					</p>
				</DefaultWarnBox>
			)

		case 'ebookUniversity':
		case 'ebook':
			return (
				<DefaultWarnBox>
					<p>
						<b>굿노트6 앱 (iOS)에서만 열리는 eBook 상품입니다.</b>
						&nbsp;아이폰, 아이패드, 맥북 내 굿노트 6 앱이 설치되어 있어야하며 굿노트
						계정(무료계정 가능)이 필요합니다.
					</p>
				</DefaultWarnBox>
			)

		case 'workbook':
		case 'textbook':
			return (
				<DefaultWarnBox>
					<p>
						<b>해당 상품은 수업자료가 아닙니다.</b>
						저작 및 판매 등의 이용 권리를 1년 단위로 계약하는 라이선스 상품입니다.
					</p>
					<Link
						className={width > 400 ? 'link-default' : null}
						href={'https://solvookguide.oopy.io/qna/8'}
						target={'_blank'}>
						자세히 보기{' '}
						<Icon
							icon={ArrowRight}
							size={Sizes.xsmall}
							color={theme.new.colors.sol_gray_600}
						/>
					</Link>
				</DefaultWarnBox>
			)

		default:
			return null
	}
}

export default WarnBox
