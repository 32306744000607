import { Button } from 'antd'
import styled from 'styled-components'

const NoPadButton = styled(Button)`
	display: flex;
	align-items: center;
	height: auto;
	padding: 0 !important;
`

export default NoPadButton
