import Icon from '@/components/icon/Icon'
import { Close, Search, Sizes } from '@/components/icon/IconList'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Input } from 'antd'
import { TextAreaProps } from 'antd/es/input'
import {
	ButtonHTMLAttributes,
	createContext,
	Dispatch,
	FC,
	InputHTMLAttributes,
	MouseEvent,
	MutableRefObject,
	PointerEventHandler,
	PropsWithChildren,
	RefCallback,
	SetStateAction,
	TextareaHTMLAttributes,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react'
import styled, { useTheme } from 'styled-components'

const InputWrapper = styled.div`
	border-radius: 6px;
	padding: 0 16px 0 8px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_50};
	width: 100%;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;
	border: 1px solid transparent;
	&:hover,
	&.active {
		border: 1px solid ${(props) => props.theme.new.colors.sol_gray_700};
	}
	&.active {
		background-color: ${(props) => props.theme.new.colors.white};
	}
	input,
	textarea {
		padding: 8px 0 8px 8px;
		line-height: 1.5;
		width: 100%;
		min-height: auto;
		background-color: transparent;
		border: unset;
		font-size: 16px;
		&:focus {
			outline: none !important;
		}

		&::placeholder {
			color: ${(props) => props.theme.new.colors.sol_gray_500};
		}
	}

	button {
		all: unset;
		height: 20px;
		display: flex;
		align-items: center;
	}
	.delete-btn {
		margin-left: 8px;
		i {
			background-color: ${(props) => props.theme.new.colors.sol_gray_800};
			border-radius: 50%;
			padding: 2px;
		}
		div {
			margin: 0 12px;
		}
	}
	@media (max-width: 1200px) {
	}
	@media (max-width: 768px) {
		max-width: unset;
		input {
			font-size: 14px;
			line-height: 20px;
		}
	}
`

export interface CommonTextFieldProps extends Pick<TextAreaProps, 'autoSize'> {
	value: string
	onClear?: () => void
	search?: boolean
	onBlur?: () => void
	onClick?: (ev: MouseEvent) => void
	onSearchClick?: PointerEventHandler<HTMLButtonElement>
	searchButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type']
	fieldRef?: RefCallback<HTMLInputElement | HTMLTextAreaElement>

	placeholder?: string
	readOnly?: boolean
	textAreaProps?: Pick<TextAreaProps, 'rows'>
}
interface InputRef {
	element: HTMLInputElement | HTMLTextAreaElement
	focusTimeout: NodeJS.Timeout | null
}
interface TextFieldContext {
	inputRef: MutableRefObject<InputRef>
	focused: boolean
	setFocused: Dispatch<SetStateAction<boolean>>
	handleFocus: () => void
	handleBlur: () => void
}

const TextFieldContext = createContext<TextFieldContext>({} as TextFieldContext)

const InputContainer: FC<PropsWithChildren<CommonTextFieldProps>> = ({
	children,
	value,
	onBlur,
	onClear,
	search,
	onSearchClick,
	searchButtonType,
}) => {
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const isMobile = useMemo(() => width < 769, [width])

	const inputRef = useRef<InputRef>({
		element: null,
		focusTimeout: null,
	})

	const [focused, setFocused] = useState(false)
	const handleFocus = useCallback(() => {
		clearTimeout(inputRef.current.focusTimeout)
		setFocused(true)
	}, [])

	const handleBlur = useCallback(() => {
		inputRef.current.focusTimeout = setTimeout(() => {
			setFocused(false)
		}, 100)

		if (onBlur) {
			onBlur()
		}
	}, [onBlur])

	const handleClear = useCallback<PointerEventHandler<HTMLButtonElement>>(() => {
		if (onClear) {
			onClear()
		}
		if (inputRef.current.element) {
			inputRef.current.element.focus()
		}
	}, [onClear])

	return (
		<TextFieldContext.Provider
			value={{ inputRef, focused, setFocused, handleFocus, handleBlur }}>
			<InputWrapper className={'textfield '.concat((focused || value?.length) && 'active')}>
				{children}
				{!!onClear && value?.length > 0 && (
					<button className={'delete-btn'} onClick={handleClear} type={'button'}>
						<Icon
							icon={Close}
							size={isMobile ? Sizes.xxsmall : Sizes.xsmall}
							color={'#fff'}
						/>
						{(onSearchClick || search) && <div>ㅣ</div>}
					</button>
				)}
				{(onSearchClick || search) && (
					<button onClick={onSearchClick} type={searchButtonType || 'submit'}>
						<Icon
							icon={Search}
							size={isMobile ? Sizes.small : Sizes.xmedium}
							color={theme.new.colors.sol_gray_800}
						/>
					</button>
				)}
			</InputWrapper>
		</TextFieldContext.Provider>
	)
}

export type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'crossOrigin'> &
	CommonTextFieldProps
const TextField: FC<TextFieldProps> = ({ onClick, onChange, fieldRef, search, ...props }) => {
	const { inputRef, handleFocus, handleBlur } = useContext(TextFieldContext)

	const handleClick = useCallback<PointerEventHandler<HTMLInputElement>>(
		(ev) => {
			onClick?.(ev)
		},
		[onClick],
	)

	const setupRef = useCallback<RefCallback<HTMLInputElement>>(
		(el) => {
			if (inputRef) {
				inputRef.current.element = el
			}
			fieldRef?.(el)
		},
		[fieldRef],
	)

	const formProps = { onBlur: handleBlur, onClick: handleClick, onFocus: handleFocus, onChange }

	return (
		<InputContainer {...props}>
			<input ref={setupRef} {...formProps} {...(search && { type: 'search' })} {...props} />
		</InputContainer>
	)
}

export default TextField

export type TextAreaFieldProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'crossOrigin'> &
	CommonTextFieldProps
export const TextAreaField: FC<TextAreaFieldProps> = ({
	onClick,
	onChange,
	fieldRef,
	search,
	...props
}) => {
	const { inputRef, handleFocus, handleBlur } = useContext(TextFieldContext)

	const handleClick = useCallback<PointerEventHandler<HTMLTextAreaElement>>(
		(ev) => {
			onClick?.(ev)
		},
		[onClick],
	)

	const setupRef = useCallback<RefCallback<HTMLTextAreaElement>>(
		(el) => {
			if (inputRef) {
				inputRef.current.element = el
			}
			fieldRef?.(el)
		},
		[fieldRef],
	)

	const formProps = { onBlur: handleBlur, onClick: handleClick, onFocus: handleFocus, onChange }

	return (
		<InputContainer {...props}>
			<Input.TextArea
				ref={setupRef}
				{...formProps}
				{...(search && { type: 'search' })}
				{...props}
			/>
		</InputContainer>
	)
}
