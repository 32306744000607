import { DEFAULT_BOOK_COVER } from '@/settings/constant'
import NextImage, { ImageProps } from 'next/image'
import { useCallback, useEffect, useState } from 'react'

const Image = ({ src: _src, ...props }: ImageProps) => {
	const [src, setSrc] = useState(_src || DEFAULT_BOOK_COVER)
	const onError = useCallback(() => {
		setSrc(DEFAULT_BOOK_COVER)
	}, [])

	//_src만 변경된 경우
	useEffect(() => {
		if (_src) setSrc(_src)
	}, [_src])
	return <NextImage {...props} src={src} onError={onError} quality={props.quality || 90} />
}

export default Image
