import React, { MouseEvent, ReactNode, useCallback } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export function ActiveLink({
	className,
	children,
	href,
	passHref,
	legacyBehavior,
	onClick,
}: {
	className?: string
	href: string
	children: ReactNode
	passHref?: boolean
	legacyBehavior?: boolean
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}) {
	const router = useRouter()

	const handleClick = useCallback(
		(e: MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault()
			onClick?.(e)
			mixpanel(href)
			router.push(href)
		},
		[href],
	)
	const mixpanel = (href) => {
		switch (href) {
			case '/introduce':
				return mixpanel('Introduce Clicked from Header')
			case '/protection':
				return mixpanelEvent('Protection Clicked from Header')
			case '/b2b':
				return mixpanel('B2B Clicked from Header')
			default:
				return null
		}
	}
	const options = {}
	if (typeof children !== 'string') {
		options['passHref'] = true
		options['legacyBehavior'] = true
	}

	return (
		<Link
			{...options}
			className={className}
			href={href}
			onClick={handleClick}
			passHref={passHref}
			legacyBehavior={legacyBehavior}>
			{children}
		</Link>
	)
}
