import { useLibrarySubject } from '@/modules/mypages/libraries/components'
import { CLASS_INDEX_PAGE } from '@/settings/constant'
import { useMutation } from '@tanstack/react-query'
import { message } from 'antd'
import { useRouter } from 'next/router'
import {
	Dispatch,
	FC,
	SetStateAction,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react'
import { postClass } from 'src/api/class'
import { itemDTO } from 'src/api/main'
import { IProduct } from 'src/api/product'
import useClassStore from 'src/store/class'
import { useMainPageStore } from 'src/store/main'
import { useModal } from 'src/utils/overlay'
import { StyledModal } from '.'
import NewClassStep1 from './NewClassStep1'

interface DataProps {
	step: number
	name: string
	search: string
	items: Array<IProduct>
	selected: Record<IProduct['id'], null>
}
interface NewClassContext extends DataProps {
	setData: Dispatch<SetStateAction<DataProps>>
}

export const NewClassContext = createContext<NewClassContext>(null)

const NewClass: FC = () => {
	const [isSelectBooksModalOpen, { open: openSelectBooksModal, close: closeSelectBooksModal }] =
		useModal('selectBooksModal')

	const data = useClassStore((state) => state.adding)
	const userSourceBooks = useClassStore((state) => state.userSourceBooks)
	const { setAdding, openNewClass, closeNewClass, setUserSourceBooks } = useClassStore(
		({ setAdding, openNewClass, closeNewClass, setUserSourceBooks }) => ({
			setAdding,
			openNewClass,
			closeNewClass,
			setUserSourceBooks,
		}),
	)

	const { setSubject } = useMainPageStore(({ setSubject }) => ({ setSubject }))
	const subjectOnLibrary = useLibrarySubject()
	const subjectOnMain = useMainPageStore((state) => state.subject)
	const { subject, isNewClassOpen } = useMemo(
		() => ({ subject: subjectOnLibrary || subjectOnMain, isNewClassOpen: data.open }),
		[subjectOnLibrary, subjectOnMain, data],
	)

	useEffect(() => {
		if (isNewClassOpen) {
			setAdding({ name: '' })
			setUserSourceBooks({ type: 'new', books: undefined })

			if (subject) {
				setSubject(subject)
			}
		}
	}, [isNewClassOpen, setAdding, setSubject, setUserSourceBooks, subject])

	useEffect(() => {
		if (data.step === 2) {
			openSelectBooksModal()
		}
	}, [data.step, openSelectBooksModal])

	useEffect(() => {
		if (isSelectBooksModalOpen) {
			closeNewClass()
		} else {
			setAdding({ step: 1 })
		}
	}, [isSelectBooksModalOpen, closeNewClass, setAdding])

	const classMutation = useMutation(postClass)
	const { push } = useRouter()

	const handleSubmitSelectedBookList = useCallback(
		async (selected: itemDTO[]) => {
			// console.log(selected, data)

			if (!subject) {
				return
			}

			const cls = await classMutation.mutateAsync({
				name: data.name,
				subject,
				choices: selected.map(({ id }) => ({ sourceId: id, sourceType: 'workbook' })),
			})

			setUserSourceBooks({ books: undefined })

			closeSelectBooksModal()
			message.info('클래스가 생성됐어요.')
			push(CLASS_INDEX_PAGE.concat(`/${cls.id}`))
		},
		[subject, classMutation, data, push, setUserSourceBooks, closeSelectBooksModal],
	)

	const isSubmitting = useRef(false)

	useEffect(() => {
		if (userSourceBooks.books) {
			if (userSourceBooks.type === 'new' && !isSubmitting.current) {
				isSubmitting.current = true
				handleSubmitSelectedBookList(userSourceBooks.books)
			}
		} else {
			isSubmitting.current = false
		}
	}, [userSourceBooks, handleSubmitSelectedBookList])

	return (
		<>
			{data.step === 1 && (
				<StyledModal
					open={isNewClassOpen}
					closable={false}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}>
					<NewClassStep1 onCancel={closeNewClass} />
					{/* {data.step === 1 && <NewClassStep1 onCancel={close} />} */}
					{/* {data.step === 2 && <NewClassStep2 />} */}
				</StyledModal>
			)}
		</>
	)
}

export default NewClass
