import NewClass from '@/components/Class/NewClass'
import FilledButton from '@/components/common/Button/FilledButton'
import Icon from '@/components/icon/Icon'
import { Class1, Library, PlayableMedia, Plus1_5 } from '@/components/icon/IconList'
import { UserSourceStepModal } from '@/components/Landing/modals/UserSourceStepModal'
import { GNBHeight } from '@/containers/Header/GNB.style'
import { useAuthStore } from '@/lib/auth/AuthStore'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { CLASS_INDEX_PAGE, LIBRARIES_INDEX_PAGE } from '@/settings/constant'
import { useQuery } from '@tanstack/react-query'
import { Divider } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react'
import { Class, fetchClasses } from 'src/api/class'
import { itemDTO } from 'src/api/main'
import useClassStore from 'src/store/class'
import { useMainPageStore } from 'src/store/main'
import { useModal } from 'src/utils/overlay'
import styled from 'styled-components'
import { useLibrarySubject } from '.'

// export const libraryLnbDimensions = { width: 270, gap: 40, widthOnTablet: 92 }
export const libraryLnbDimensions = { width: 0, gap: 0, widthOnTablet: 0 }
const libraryLnbID = 'library-lnb'
const LibraryLNBWrapper = styled.aside`
	width: ${libraryLnbDimensions.width}px;
	padding: 20px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	border-radius: 10px;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.14px;

	dt,
	dd p {
		color: ${(props) => props.theme.new.colors.sol_gray_300};
	}
	dt {
		padding: 8px 12px;
		font-weight: 600;
	}
	dd {
		margin-bottom: 0;
		padding-top: 4px;

		p {
			padding: 4px 12px;
		}
	}

	@media (max-width: 1200px) {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: ${libraryLnbDimensions.widthOnTablet}px;
		padding: 20px 8px;
		border-radius: 0;

		body:has(.gnb) & {
			top: ${GNBHeight}px;
		}

		dl {
			padding-bottom: 8px !important;
		}
		dt {
			display: none;
		}
		dd {
			padding-top: 0;
		}

		ul {
		}
		li {
			margin-bottom: 8px !important;
		}

		button {
			padding: 11px 4px;
			flex-direction: column;
			font-size: 12px;
			font-weight: 400;

			&.ant-btn-text:has(label) {
				background-color: transparent;
			}
			svg {
				width: 32px;
				height: 32px;
				margin-right: 0;
				margin-bottom: 4px;
			}
			span {
				display: block;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;

				&.extra {
					display: none;
				}
			}
		}
	}

	@media (max-width: 768px) {
		display: none;
	}
`
const LibraryDividerWrapper = styled.div`
	padding: 0 4px;

	.ant-divider {
		margin: 10px 0 18px;
		border-color: ${(props) => props.theme.new.colors.sol_gray_100};
	}
	@media (min-width: 1201px) {
		display: none;
	}
`
const LibraryLNBDefinitionList = styled.dl<{ height?: CSSProperties['height'] }>`
	max-height: ${(props) => props.height || 'auto'};
	margin-bottom: 0;

	&:not(:last-of-type) {
		padding-bottom: 40px;
	}
	&:last-of-type {
		padding-bottom: 16px;
	}
`
const NewClassButton = styled(FilledButton)`
	svg {
		width: 18px;
		height: 18px;
		margin-right: 4px;
	}
`
const LinkButton = styled(FilledButton)`
	justify-content: flex-start !important;
	padding: 8px 12px !important;
	background-color: transparent !important;
	border-radius: 4px !important;
	color: ${(props) => props.theme.new.colors.sol_gray_500} !important;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.16px !important;

	&.active,
	&:hover {
		background-color: rgba(169, 175, 199, 0.1) !important;
		color: ${(props) => props.theme.new.colors.sol_gray_900} !important;
	}
	svg {
		width: 22px;
		height: 22px;
		margin-right: 4px;
	}
`

const LibraryLNB: FC = () => {
	const { pathname } = useRouter()

	const [isSelectBooksModalOpen, { open: openSelectBooksModal, close: closeSelectBooksModal }] =
		useModal('selectBooksModal')

	const { isLoggedIn } = useAuthStore()
	const subjectOnLibrary = useLibrarySubject()
	const subjectOnMain = useMainPageStore((state) => state.subject)

	const { subject, search } = useMemo(() => {
		const subject = subjectOnLibrary || subjectOnMain
		const searchParams = new URLSearchParams()
		searchParams.set('subject', subject)
		return { subject, search: '?'.concat(searchParams.toString()) }
	}, [subjectOnLibrary, subjectOnMain])

	const { data, refetch } = useQuery(
		['myClasses', subject],
		() => fetchClasses({ offset: 0, limit: 9999999, subject }),
		{ enabled: isLoggedIn && !!subject },
	)

	const items = useClassStore((state) => state.mine)
	const selected = useClassStore((state) => state.selected)
	const { openNewClass, setMine } = useClassStore(({ openNewClass, setMine }) => ({
		openNewClass,
		setMine,
	}))

	useEffect(() => {
		if (!data) {
			return
		}

		setMine(data.data)
	}, [data, setMine])

	useEffect(() => {
		if (!selected || !isSelectBooksModalOpen) {
			refetch()
		}
	}, [selected, isSelectBooksModalOpen])

	const { setUserSourceBooks } = useClassStore(({ setUserSourceBooks }) => ({
		setUserSourceBooks,
	}))

	const handleSubmitSelectedBookList = useCallback(
		async (selected: itemDTO[]) => {
			setUserSourceBooks({ books: selected })
		},
		[setUserSourceBooks],
	)

	return (
		<LibraryLNBWrapper id={libraryLnbID}>
			<LibraryLNBDefinitionList>
				<dt>내 콘텐츠</dt>
				<dd>
					<Link href={LIBRARIES_INDEX_PAGE.concat(search)}>
						<LinkButton
							type="text"
							block
							className={pathname.startsWith(LIBRARIES_INDEX_PAGE) && 'active'}>
							<Icon icon={Library} />
							라이브러리
						</LinkButton>
					</Link>
				</dd>
			</LibraryLNBDefinitionList>

			<LibraryDividerWrapper>
				<Divider />
			</LibraryDividerWrapper>

			<LibraryLNBDefinitionList className="scrollable">
				<dt>내 클래스</dt>
				<dd>
					{items && items.length ? (
						<MyClassContainer>
							{items.map(({ id, name }) => (
								<MyClass key={id} id={id} name={name} />
							))}
						</MyClassContainer>
					) : (
						<p>아직 생성한 클래스가 없습니다.</p>
					)}
				</dd>
			</LibraryLNBDefinitionList>

			<NewClassButton variant="soft" type="text" block onClick={() => openNewClass()}>
				<Icon icon={Plus1_5} />
				<label>
					새 클래스<span className="extra"> 만들기</span>
				</label>
			</NewClassButton>

			<NewClass />

			<UserSourceStepModal
				closeSelectBooksModal={closeSelectBooksModal}
				isSelectBooksModalOpen={isSelectBooksModalOpen}
				modalType={'selectBooksModal'}
				onSubmitSelectedBookList={handleSubmitSelectedBookList}
			/>
		</LibraryLNBWrapper>
	)
}

export default LibraryLNB

const MyClassContainer = styled.ul`
	max-height: 340px;
	overflow: auto;
	margin-right: -2px;
	padding-right: 2px;
	li:not(:last-of-type) {
		margin-bottom: 4px;
	}
`
const MyClassButton = styled(LinkButton)`
	svg {
		color: ${(props) => props.theme.new.colors.sol_gray_300};
		transition: 0.2s all;
	}

	&.active,
	&:hover {
		svg {
			color: ${(props) => props.theme.new.colors.sol_gray_900};
		}
	}
`
const MyClass: FC<Pick<Class, 'id' | 'name'>> = ({ id, name }) => {
	const { width } = useWindowDimensions()
	const isTablet = width < 1201

	const { asPath } = useRouter()
	const { href, matched } = useMemo(() => {
		const href = `${CLASS_INDEX_PAGE}/${id}`
		return { href, matched: asPath.endsWith(href) }
	}, [id, asPath])

	return (
		<li>
			<Link href={href}>
				<MyClassButton type="text" block className={matched && 'active'}>
					<Icon icon={isTablet ? Class1 : PlayableMedia} />
					{name}
				</MyClassButton>
			</Link>
		</li>
	)
}
