import numeral from 'numeral'
import dayjs from 'dayjs'

export enum PhoneType {
	default,
}

export const priceFormatter = (price: string | number) => {
	return numeral(price).format('0,0')
}
export const priceWithStringFormatter = (price: string | number) => {
	return numeral(price).format('0,0') + '원'
}
export const numberFormatter = (price: string | number) => {
	return numeral(price).format('0,0')
}

export const dateFormatter = (date: string | Date) => {
	return dayjs(date).format('YYYY. MM. DD. A HH:mm')
}

export const dateTransFormatter = (date: string | Date) => {
	return dayjs(date).format('YYYY. MM. DD')
}

export function phoneFormatter(num: string, type: PhoneType = PhoneType.default) {
	var formatNum = ''

	if (num.length === 11) {
		if (type === 0) {
			formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
		} else {
			formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
		}
	} else if (num.length === 8) {
		formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
	} else {
		if (num.indexOf('02') === 0) {
			if (type === 0) {
				formatNum = num.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-****-$3')
			} else {
				formatNum = num.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3')
			}
		} else {
			if (type === 0) {
				formatNum = num.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-***-$3')
			} else {
				formatNum = num.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
			}
		}
	}

	return formatNum
}
