import React from 'react'
import { SearchTextField } from '@/components/common/TextField/searchTextField'
import { SearchRecommendDialog } from '@/components/SearchRecommend/SearchRecommendDialog'
import { useSearchWithSearchDialog } from '@/components/Search/useSearchWithSearchTerms'

export interface SearchFormDataProps {
	keyword: string
}

export type SearchFormProps = {}
export const SearchRequestForm: React.FC<SearchFormProps> = ({}) => {
	const {
		openSearchDialog,
		recommendSearchTermList,
		currentSearchTerm,
		isOpenSearchDialog,
		closeSearchDialog,
		searchTermValue,
		onClearSearchTerm,
		onChangeSearchTerm,
		handleSelectedSubject,
		selectedSubject,
		onSubmitSearchTerm,
		onSelectRecommendSearchTerm,
	} = useSearchWithSearchDialog()

	return (
		<>
			<SearchTextField
				readOnly={true}
				onClickForm={openSearchDialog}
				placeholder={recommendSearchTermList.placeHolder}
				name={'keyword'}
				value={currentSearchTerm}
			/>
			<SearchRecommendDialog
				isOpen={isOpenSearchDialog}
				onClose={closeSearchDialog}
				modalValue={searchTermValue}
				onClear={onClearSearchTerm}
				onChange={onChangeSearchTerm}
				currentSubject={selectedSubject}
				handleCurrentSubject={handleSelectedSubject}
				onSubmit={onSubmitSearchTerm}
				onClick={onSelectRecommendSearchTerm}
				recommendSearchTermList={recommendSearchTermList}
			/>
		</>
	)
}
