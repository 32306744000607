import * as React from 'react'
import Link from 'next/link'
import LogoArea from './Logo.style'
import { UrlObject } from 'url'
import Image from 'next/image'

export interface LogoProps {
	className?: string
	withLink?: boolean
	src?: string
	title: string
	linkTo?: string | UrlObject
	size?: { width: number; height: number }
}

export const Logo: React.FC<LogoProps> = ({
	className,
	withLink,
	title,
	src,
	linkTo = '#',
	size,
}) => {
	return (
		<LogoArea className={className}>
			{withLink ? (
				<Link href={linkTo} passHref legacyBehavior>
					{src ? <Image src={src} alt={title} {...size} /> : <h3>{title}</h3>}
				</Link>
			) : (
				<>
					<a>{src ? <Image src={src} alt={title} {...size} /> : <h3>{title}</h3>}</a>
				</>
			)}
		</LogoArea>
	)
}
