import { otherApiClient } from '.'
import { RankingItemDTO } from './main'

export interface Category {
	id: string
	name: string
	items?: Category[]
}

export interface LinkMenu {
	id: string
	link: string
	name: string
}

export interface GetCategoryResponse {
	depthItems: Array<Category>
	linkItems: Array<LinkMenu>
	selectedId?: string
}

export const getCategoryMenu = async (categoryId?: string) => {
	const { data } = await otherApiClient.market<GetCategoryResponse>(`/menuCategories`, {
		params: {
			categoryId,
		},
	})
	return data
}

export interface GetCategoryListRequest {
	id: string
	offset: number
	limit: number
	'author[]'?: Array<string>
	'dataType[]'?: Array<string>
	'unit[]'?: Array<string>
	sort?: SortType
}

export const SortType = {
	POPULAR: '인기순',
	NEW: '최신순',
	LOWPRICE: '낮은가격순',
	NAME: '이름순',
} as const
export type SortType = typeof SortType[keyof typeof SortType]
export const DetailFilterKeys = {
	UNIT: '단원',
	DATATYPE: '자료유형',
	AUTHOR: '제작자',
} as const
export const CategoryQueryKey = {
	CATEGORY: 'id',
	SORT: 'sort',
} as const
export type CategoryQueryKey = typeof CategoryQueryKey[keyof typeof CategoryQueryKey]
export type DetailFilterKeys = typeof DetailFilterKeys[keyof typeof DetailFilterKeys]

export interface GetCategoryListResponse {
	totalCount: number
	items: Array<RankingItemDTO>
	filterItems: Array<{
		name: string
		items: Array<string>
	}>
	sortItems: Array<string>
}

export const getCategoryList = async ({ id, ...rest }: GetCategoryListRequest) => {
	const { data } = await otherApiClient.market<GetCategoryListResponse>(`/menuCategories/${id}`, {
		params: {
			...rest,
		},
	})
	return data
}
