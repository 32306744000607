import { DefaultLoadingContainer } from '@/components/DefaultLoadingContainer'
import PassSection from '@/components/Package/PassSection'
import Icon from '@/components/icon/Icon'
import { Close } from '@/components/icon/IconList'
import { PriceSection } from '@/modules/products/components/PriceSection'
import Image from 'next/image'
import React, { useEffect } from 'react'
import { isBooksProduct } from 'src/api/booksProducts'
import { HandoutDTO, IProduct } from 'src/api/product'
import styled from 'styled-components'
import { styled as muiStyled, Typography } from '@mui/material'
import useHandoutProductStore from '../../../store/relatedProduct'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export interface MainPreviewModalProps {
	onClose: () => void
	data: IProduct
}

export const PreviewBookModal: React.FC<MainPreviewModalProps> = ({ data, onClose }) => {
	const source = data.source as HandoutDTO
	const productType = isBooksProduct(data) ? data.productType : data.source_type
	const { updateCurrentProduct } = useHandoutProductStore((state) => ({
		updateCurrentProduct: state.updateCurrentProduct,
	}))
	useEffect(() => {
		updateCurrentProduct(data)
	}, [data])
	return (
		<MainPreviewModalWrapper>
			<div className={'left-align'}>
				<p>{[data?.title]}</p>
				<div className={'image-list'}>
					{!data && <DefaultLoadingContainer title="데이터를 불러오는 중입니다." />}
					{(!source?.images?.previews || source?.images?.previews.length === 0) && (
						<EmptyContainer>
							<Typography variant={'body1'} color={'rgba(106, 112, 138, 1)'}>
								등록된 샘플이 없어요.
							</Typography>
						</EmptyContainer>
					)}
					{source?.images?.previews.map((image, index) => (
						<Image width={788} height={1124} key={index} src={image.url} alt={''} />
					))}
				</div>
			</div>
			<div className={'cart-wrapper'}>
				<button
					className={'close-button'}
					onClick={() => {
						onClose()
						mixpanelEvent('Close UI', {
							Type: '닫기버튼',
							Position: 'preview modal',
						})
					}}>
					<Icon icon={Close} />
				</button>
				{productType === 'handout' ? (
					<PassSection product={data} className="previewMode" />
				) : (
					<PriceSection product={data} />
				)}
			</div>
		</MainPreviewModalWrapper>
	)
}
const EmptyContainer = muiStyled('div')(({ theme }) => {
	return {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '6px',
		// margin: '0 auto',
		width: 'revert-layer',
		height: '100%',
		flex: '1 1 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
})
const MainPreviewModalWrapper = styled.div`
	display: flex;
	gap: 72px;
	width: 100%;
	justify-content: center;

	.left-align {
		display: flex;
		max-width: 788px;
		flex-direction: column;
		flex: 1;

		> p {
			color: #fff;

			font-size: 20px;

			font-weight: 700;
			line-height: 30px;
			margin-bottom: 20px;
		}

		.image-list {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			height: calc(100vh - 120px);
			gap: 20px;
			overscroll-behavior: contain;
			width: 100%;
		}
	}

	.cart-wrapper {
		width: 340px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> section {
			margin-top: 40px;
		}

		> div {
			:first-of-type {
				width: 100%;
				position: relative;
			}

			:last-of-type {
				width: 100%;
			}
		}
	}

	.close-button {
		all: unset;
		position: absolute;
		top: 20px;
		right: 0;
		padding: 8px;
		background-color: white;
		border-radius: 6px;
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
`
