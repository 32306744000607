import { NextPageContext } from 'next'
import { AuthInfo } from '@/lib/auth/AuthStore'
import { create } from 'zustand'

export interface AppRootContext extends NextPageContext {
	auth?: AuthInfo
}

interface IRouteObject {
	pathname: string
	query: {
		[key: string]: string | string[]
	}
}

type prevUrlStore = {
	prevUrl: IRouteObject | undefined
	setPrevUrl: (prevUrl: IRouteObject) => void
}

export const usePrevUrlStore = create<prevUrlStore>((set) => ({
	prevUrl: undefined,
	setPrevUrl(prevUrl) {
		set({ prevUrl })
	},
}))
