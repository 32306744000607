import { styled as muiStyled } from '@mui/material'
import styled from 'styled-components'

export const CategoryContainer = styled.div`
	width: 100%;
	height: 100vh;

	gap: 16px;
	top: 72px;
	position: fixed;
	background: rgba(28, 31, 46, 0.5);
	z-index: 11;
	display: flex;
	justify-content: center;

	overflow: hidden;

	@media (max-width: 1180px) {
		display: none;
	}
`
export const CategoryBox = styled.section`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 412px;

	width: 100%;
	height: 100%;
	max-height: 600px;
	max-width: 832px;

	background-color: #fff;
	border-radius: 6px;

	margin: 16px 0;
`

export const RowContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(600px - 76px);
	overflow-y: auto;

	&.container-mobile {
		height: 100%;
		padding: 20px 0;
		flex-grow: 1;
	}

	@media (max-width: 1199px) {
		overflow-y: auto;

		&.depth-one-container {
			height: calc(100vh - 368px);
			max-height: calc(var(--svh, 1svh) * 100 - 268px);
		}

		&.depth-two-row-container {
			height: 100%;
		}
	}
`
export const BoxContainer = styled.div``
export const MenuLinkBox = muiStyled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',

	flexDirection: 'column',
	borderRight: `1px solid ${theme.palette.grey[50]}`,

	'&:last-of-type': {
		borderRight: 'none',
	},

	'&.link-box-mobile ': {
		height: '100%',
		maxHeight: 'calc(var(--svh, 1svh) * 100 - 268px)',
		width: '120px',
		flexShrink: 0,
		borderRight: `1px solid ${theme.palette.grey[50]}`,
	},
	'&._depth-two': {
		maxHeight: 'calc(var(--svh, 1svh) * 100 - 58px)',
	},

	'&.depth-one-big-width': {
		padding: '20px 0',
	},
}))

export const Box = muiStyled('ul')(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	padding: '0 16px',

	'@media (max-width: 768px)': {
		padding: '0 8px',
	},
}))
export const ButtonBox = muiStyled('li')(({ theme }) => ({
	a: {
		width: '100%',
	},

	button: {
		width: '100%',
		height: '32px',
		backgroundColor: 'transparent',
		borderRadius: '4px',
		border: `1px solid ${theme.palette.grey['200']}`,
		cursor: 'pointer',
		...theme.typography.semibold14,
		color: theme.palette.text.secondary,
		boxSizing: 'border-box',
		whiteSpace: 'nowrap',
		padding: 0,

		':hover': {
			backgroundColor: theme.palette.grey[50],
		},
	},

	'&.active': {
		button: {
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
	},
}))

export const TwoColumnBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`

export const BoxColumn = muiStyled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	padding: '8px 16px',

	'&.active, &:hover': {
		backgroundColor: 'rgba(169, 175, 199, 0.20)',
		cursor: 'pointer',
		p: {
			color: theme.palette.indigo['500'],
			...theme.typography.semibold16,
		},
	},
	'@media (max-width: 1199px)': {
		padding: '12px 20px',
		backgroundColor: 'transparent',
		p: {
			color: theme.palette.text.primary,
			...theme.typography.regular16,
		},
	},
}))

export const BoxTitle = muiStyled('p')(({ theme }) => ({
	...theme.typography.semibold16,
	padding: '0 16px',
	color: theme.palette.grey['700'],
	marginBottom: '12px',
	a: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		':hover': {
			textDecoration: '2px underline',
		},
	},
	'@media (max-width: 1199px)': {
		padding: '20px 16px 12px 16px',
		marginBottom: '0',
	},
}))

export const LabelBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	width: 90%;
	word-break: auto-phrase;

	@media (max-width: 1199px) {
		width: 100%;
	}
`

export const Label = muiStyled('p')(({ theme }) => ({
	...theme.typography.regular16,
	color: theme.palette.grey['500'],
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}))

export const MenuAdBox = styled.div`
	display: flex;
	height: fit-content;
	max-height: 600px;
	flex-direction: column;
	gap: 16px;
	flex-shrink: 0;

	margin: 16px 0;
`
export const AdStack = styled.div`
	padding: 0 32px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_50};
	border-radius: 6px;

	:hover {
		background-color: ${(props) => props.theme.new.colors.sol_gray_100};
	}
`
