import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

const useCloseHandler = () => {
	const router = useRouter()
	const { closeSideMenu } = useSideCategoryMenuStore()

	const isSamePath = useCallback(
		(nextUrl: string) => {
			return router.asPath === nextUrl
		},
		[router.asPath],
	)
	const handleRouteChange = useCallback(
		(path: string) => {
			if (isSamePath(path)) return
			closeSideMenu()
		},
		[isSamePath],
	)
	useEffect(() => {
		router.events.on('routeChangeStart', handleRouteChange)
		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	}, [router.events, handleRouteChange])
}

export default useCloseHandler
