import { ITheme } from 'styled-components'

const defaultTheme: ITheme = {
	breakpoints: ['481px', '768px', '992px', '1201px', '1441px', '1601px'],
	primary: [
		'#6857E5', // 0: Default
		'#00BFC8', // 1: Chart
		'rgba(0, 244, 255, 0.05)', // 2: Chart Hover
	],
	background: [
		'#FDFDFF', // 0: Page
		'#F5F8FB', // 1: Box
		'#F0FAFC', // 2: Box2
		'rgba(27, 27, 41, 0.03)', // 3: Black 30%
		'#E9EBEE', // -1: Default
	],
	color: [
		'#1B1B29', // 0: Black
		'#ffffff', // 1: White
		'#F7F7F7', // 2: Dropdown Hover
		'#E9E8E8', // 3: Bullet bg
		'#FC5C63', // 4: Active Favorite
		'rgba(0, 0, 0, 0.25)', // 5: Inactive Favorite
		'#273343', // 6: Agent Social Icon
		'#3b5998', // 7: Facebook
		'#55ADEE', // 8: Twitter
		'#FFF900', // 9: Snapchat
		'#dd4b39', // 10: Google Plus
		'#F61C0D', // 11: Youtube
		'#e4405f', // 12: Instagram
		'#E2E2E2', // 13: Range Color
		'#00ACEE', // 14: Github Color
		'#FFCB2B', // 15: Firebase Color
		'#484848', // 16: Link button
	],
	info: [
		'#1890FF', // 0: Info
	],
	warning: [
		'#FF5252', // 0: Warning
	],
	success: [
		'#51C200', // 0: Success
	],
	error: [
		'#FF5252', // 0: Error
		'rgba(249, 80, 61, 0.08)', // 0: Error Light Bg
	],
	text: [
		'#1B1B29', // 0: Heading
		'#1B1B29', // 1: Text normal
		'#5F5F69', // 2: Meta Text
		'#BBBBBF', // 3: Text Info
		// '#FFFFFF', // 4: Button Ghost
	],
	border: [
		'#F3F3F3', // 0: Light
		'#E8E8EA', // 1: Default
		'#1B1B29', // 2: Active Light
	],
	boxShadow: [
		'rgba(26, 68, 116, 0.16)', // 0: Card hover
		'rgba(0, 0, 0, 0.16)', // 1: Carousel Button shadow
	],
	font: [
		'Pretendard Variable, Pretendard, -apple-system, BlinkMacSystemFont, sans-serif',
		'Lato, Noto Sans KR, sans-serif', // for korean
	],
	prices: [
		'#3f8600', // up
		'#cf1322', // down
	],
	black: [
		'#1B1B29', // 0: Black
		'#5F5F69', // 1: Black 70%
		'#BBBBBF', // 2: Black 30%
		'#E8E8EA', // 3: Black 10%
	],
	blue: [
		'#1890FF', // 0: Primary
		'#91D5FF', // 1: Secondary
		'#E6F7FF', // 2: BG
	],
	green: [
		'#27AE60', // 0: Primary
		'#27AE60', // 1: Secondary
		'#27AE60', // 2: BG
	],
	red: [
		'#FF5252', // 0: Primary
		'#FFA39E', // 1: Secondary
		'#FFF1F0', // 2: BG
	],
	white: [
		'#FFFFFF', // 0: White
	],
	new: {
		colors: {
			white: '#ffffff',
			primary: '#002FA8',
			subPrimary: '#00bfc8',
			darkBlack: '#000',
			black: '#1b1b29',
			gray900: '#1C1F2E',
			gray700: '#3B4054',
			gray650: '#5a5f6b',
			gray600: '#4F556B',
			gray500: '#6A708A',
			gray400: '#8990AB',
			gray350: '#bbbbbf',
			gray300: '#b5b8bf',
			gray290: '#cccccc',
			gray270: '#e2e3e5',
			gray250: '#e5e5e5',
			gray230: '#e8e8ea',
			gray200: '#CACEE0',
			gray180: '#eaeae8',
			gray150: '#ebebec',
			gray100: '#efeff0',
			gray50: '#F2F3F7',
			gray30: '#fdfdff',
			blue800: '#113ac3',
			blue700: '#1890ff',
			blue500: '#326EE5',
			blue200: '#94CCFF',
			blue100: '#bdd3e9',
			blue50: '#E8EEFA',
			indigo: '#021852',
			indigo900: '#1E1C38',
			indigo800: '#2C265E',
			indigo700: '#403391',
			indigo600: '#5843BF',
			indigo500: '#6857E5',
			indigo200: '#C7C6F7',
			indigo50: '#F0F0FC',
			sky300: '#afccf7',
			sky200: '#EDF2FF',
			sky100: '#cce2fa',
			mint600: '#186150',
			mint500: '#2D8069',
			mint400: '#07d3de',
			mint300: '#57C29E',
			mint200: '#A3D9C7',
			mint100: '#CEEDE3',
			mint50: '#E9F5F1',
			mint: '#E6F8FA',
			green: '#298006',
			green200: '#a0cdaa',
			green700: '#329e08',
			red: '#f25252',
			pink: '#e0b4e1',
			yellow: '#FCF2E1',
			yellow500: '#E5AB22',
			yellow400: '#F2C14E',
			yellow300: '#FAD378',
			yellow50: '#FAF2E1',
			orange: '#e9c5ab',
			gold: '#ffb938',
			silver: '#ffb938',
			bronze: '#c27a08',
			brown: '#c27a08',
			sol_dim_gray: 'rgba(169, 175, 199, 0.20)',
			sol_gray_0: '#F7F8FA',
			sol_gray_50: '#F0F1F5',
			sol_gray_100: '#E1E4ED',
			sol_gray_200: '#CACEE0',
			sol_gray_300: '#AEB4C5',
			sol_gray_400: '#8990AB',
			sol_gray_500: '#6A708A',
			sol_gray_600: '#4F556B',
			sol_gray_700: '#3B4054',
			sol_gray_800: '#292E40',
			sol_gray_900: '#1C1F2E',
			sol_indigo_0: '#F5F5FC',
			sol_indigo_50: '#F0F0FA',
			sol_indigo_100: '#E1E1FA',
			sol_indigo_200: '#C7C6F7',
			sol_indigo_300: '#A09BFA',
			sol_indigo_400: '#8378FA',
			sol_indigo_500: '#6950E5', // primary
			sol_indigo_600: '#513CBA',
			sol_indigo_700: '#403391',
			sol_indigo_800: '#2C265E',
			sol_indigo_900: '#1E1C38',
			sol_mint_0: '#F0F7F5',
			sol_mint_50: '#E9F7F2',
			sol_mint_100: '#CEEDE3',
			sol_mint_200: '#A3D9C7',
			sol_mint_300: '#57C29E', // subPrimary
			sol_mint_400: '#3EA381',
			sol_mint_500: '#29876E',
			sol_mint_600: '#186150',
			sol_mint_700: '#12473D',
			sol_mint_800: '#0E332D',
			sol_mint_900: '#0C211E',
			sol_blue_0: '#F0F4FC',
			sol_blue_50: '#E8EEFA',
			sol_blue_100: '#D5E0F7',
			sol_blue_200: '#B9CFFA',
			sol_blue_300: '#82AAFA',
			sol_blue_400: '#578FFF',
			sol_blue_500: '#326EE5',
			sol_blue_600: '#2453B2',
			sol_blue_700: '#1A3C80',
			sol_blue_800: '#1A2C57',
			sol_blue_900: '#141D33',
			sol_yellow_0: '#FCF7EB',
			sol_yellow_50: '#FAF2E1',
			sol_yellow_100: '#FAE9C3',
			sol_yellow_200: '#FAE0A2',
			sol_yellow_300: '#FAD378',
			sol_yellow_400: '#F2C14E',
			sol_yellow_500: '#E5AB22',
			sol_yellow_600: '#B88E2E',
			sol_yellow_700: '#806526',
			sol_yellow_800: '#473919',
			sol_yellow_900: '#2E2612',
			sol_red_0: '#FAF1F0',
			sol_red_50: '#F7EAE9',
			sol_red_100: '#F7DBD7',
			sol_red_200: '#F7BDB5',
			sol_red_300: '#FF9180',
			sol_red_400: '#F26A55',
			sol_red_500: '#D14D38',
			sol_red_600: '#A83725',
			sol_red_700: '#7A291C',
			sol_red_800: '#4D211A',
			sol_red_900: '#2E1612',
			sol_dim_white_90: 'rgba(255, 255, 255, 0.90)',
			border: '#D1D3D6',
			bg: {
				blue: '#F5F8FB',
				gray: '#f7f7f8',
				green: '#e0f6cd',
				yellow: '#fff2db',
				red: '#FFF0F0',
			},
		},
	},
}

export default defaultTheme
