import React, { useState } from 'react'
import styled from 'styled-components'
import {
	ActiveTitle,
	Col,
	InfoDefaultText,
	InfoPointText,
	InfoSubText,
	Row,
	SubscriptionInfoText,
} from '.'
import Icon from '@/components/icon/Icon'
import { InfoCircle, Sizes } from '@/components/icon/IconList'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { PassCategory, Ticket } from 'src/api/membership'
import usePassStore from '../../../store/pass'
import {
	PassSectionAction,
	PassSectionState,
} from '@/components/Package/PassSection/store/passStore'
import { SubscribeDialog } from '../SubscribeToPass/SubscribeDialog'
import { PassDialogButton, PassDialogButtonContainer } from '@/components/Package'

export interface PassRadioFieldProps {
	passStatus: PassSectionState & PassSectionAction
	pass: Ticket
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const PassRadioField: React.FC<PassRadioFieldProps> = ({ passStatus, pass, onChange }) => {
	const openPassInfoModal = usePassStore((state) => state.openPassInfoModal)
	const [isOpenSubscribeInfoModal, setIsOpenSubscribeInfoModal] = useState(false)
	const UNLIMITED_COUNT = 999999
	return (
		<Row
			onClick={() => passStatus.setState('selectedPass', pass.membershipId)}
			className={passStatus.selectedPass === pass.membershipId ? 'active' : ''}
			key={pass.membershipId}>
			<Col>
				<ActiveTitle>
					{pass.productInfo.title}
					<Icon
						icon={InfoCircle}
						size={Sizes.small}
						onClick={(e) => {
							e.stopPropagation()
							mixpanelEvent('Product Detail Button Clicked', {
								//사용할 수 없는 이용권 상세보기 mx-2
								'Product ID': pass.productInfo.id,
								'Product Categories': pass.productInfo.category,
								'Product Title': pass.productInfo.title,
								'Product Price': pass.productInfo.price,
								'Button UI': 'InfoIcon',
							})
							if (pass.productInfo.category === PassCategory.Subscription) {
								setIsOpenSubscribeInfoModal(true)
								return
							}
							openPassInfoModal({
								passInfo: pass.productInfo,
								userPassInfo: pass,
							})
						}}
					/>
				</ActiveTitle>

				{pass.productInfo.category === PassCategory.Subscription ? (
					<InfoSubText>
						<SubscriptionInfoText>
							<span>
								{pass.productInfo.availableCount === null ||
								pass.productInfo.availableCount === UNLIMITED_COUNT
									? '조건 내 무제한 사용'
									: pass.productInfo.availableCount + '회'}
							</span>
						</SubscriptionInfoText>
					</InfoSubText>
				) : (
					<InfoSubText>
						{pass.productInfo.category === PassCategory.Solvookplus ? (
							<InfoPointText>
								남은 횟수 :{' '}
								<span>
									{pass.productInfo.availableCount === null ||
									pass.productInfo.availableCount === UNLIMITED_COUNT
										? '조건 내 무제한 사용'
										: pass.productInfo.availableCount
										? pass.productInfo.availableCount + '회'
										: '-'}
								</span>
							</InfoPointText>
						) : (
							<InfoPointText>
								남은 횟수 : <span>{pass.remainCount}회</span>
							</InfoPointText>
						)}

						<InfoDefaultText>{`${pass.remainDay}`}일 남음</InfoDefaultText>
					</InfoSubText>
				)}
			</Col>
			<RadioWrapper>
				<Radio
					type="radio"
					value={pass.membershipId}
					onChange={onChange}
					checked={passStatus.selectedPass === pass.membershipId}
				/>
			</RadioWrapper>
			<SubscribeDialog
				open={isOpenSubscribeInfoModal}
				onClose={() => setIsOpenSubscribeInfoModal(false)}
				contentInfo={{ ...pass.productInfo, expiredAt: pass.expiredAt }}
				footer={
					<PassDialogButtonContainer>
						<PassDialogButton
							variant="strokeSecondary"
							onClick={() => {
								window.open(
									'https://solvookguide.oopy.io/78b924d5-140f-4475-bd9b-d13d99bedb2b',
									'_blank',
								)
							}}>
							이용방법 보기
						</PassDialogButton>
						<PassDialogButton
							variant="solid"
							onClick={() => {
								setIsOpenSubscribeInfoModal(false)
							}}>
							확인
						</PassDialogButton>
					</PassDialogButtonContainer>
				}
			/>
		</Row>
	)
}
export const Radio = styled.input`
	appearance: none;
	border: 2px solid ${(props) => props.theme.new.colors.sol_gray_100};
	border-radius: 50%;
	width: 21px;
	height: 21px;
	box-sizing: border-box;
	cursor: pointer;
	margin-left: 20px;

	&:checked {
		border: 6px solid ${(props) => props.theme.new.colors.sol_indigo_500};
	}
`

const RadioWrapper = styled.div`
	width: 41px;
	height: 21px;
`
