import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
} from '@mui/material'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import Icon from '../icon/Icon'
import { Close, Sizes } from '../icon/IconList'

interface DefaultDialogProps extends DialogProps {
	subParagraph?: React.ReactNode
	footer?: React.ReactNode
}

const SimpleDialog = ({
	open,
	onClose,
	children,
	footer,
	scroll,
	subParagraph,
	title,
	...props
}: DefaultDialogProps) => {
	return (
		<DialogContainer open={open} onClose={onClose} scroll={scroll} {...props}>
			<DialogTitle id="scroll-dialog-title">
				<DialogHeader>
					{title}
					<IconButton
						aria-label="close"
						onClick={(e) => onClose(e, 'backdropClick')}
						sx={(theme) => ({
							height: 'fit-contents',
							padding: '4px',
							':hover': {
								background: 'none',
							},
						})}>
						<Icon icon={Close} size={Sizes.medium} />
					</IconButton>
				</DialogHeader>
				{
					// subParagraph이 string일 경우에만 ExplainText로 감싸줌
					// subParagraph이 ReactNode일 경우에는 그대로 렌더링
					typeof subParagraph === 'string' ? (
						<ExplainText>{subParagraph}</ExplainText>
					) : (
						subParagraph
					)
				}
			</DialogTitle>
			<DialogContent>
				<ContentBox>{children}</ContentBox>
			</DialogContent>

			<DialogActions>{footer}</DialogActions>
		</DialogContainer>
	)
}

const Title = ({ children }: PropsWithChildren<{}>) => {
	return <DialogHeader>{children}</DialogHeader>
}

SimpleDialog.Title = Title

const DialogContainer = styled(Dialog)`
	.MuiTypography-root,
	.MuiDialogContent-root {
		padding: 28px;
	}

	.MuiPaper-root {
		width: 520px;

		max-height: calc(100vh - 144px);
		margin: 72px 0;

		@media (max-width: 768px) {
			padding: 20px !important;
		}
	}

	.MuiDialogActions-root {
		padding: 0;
		background-color: ${(props) => props.theme.new.colors.sol_dim_white_90};
	}
`

const ContentBox = styled.section`
	height: 100%;
`
const ExplainText = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;
	color: ${(props) => props.theme.new.colors.sol_gray_700};
`
const DialogHeader = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px; /* 150% */
	color: ${(props) => props.theme.new.colors.sol_gray_900};

	margin-bottom: 8px;
`

export default SimpleDialog
