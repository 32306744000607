import React, { createContext, useContext } from 'react'
import { useLocalObservable } from 'mobx-react-lite'

export interface GlobalConfigInfo {
	defaultPageLimit: number
	defaultPageMaxLimit: number
}

const createGlobalConfigStore = (initialValues: Partial<GlobalConfigInfo>) => {
	return {
		defaultPageLimit: initialValues.defaultPageLimit || 10,
		defaultPageMaxLimit: initialValues.defaultPageMaxLimit || 50,
	}
}

export type GlobalConfigStoreType = ReturnType<typeof createGlobalConfigStore>

export const GlobalConfigContext = createContext<GlobalConfigStoreType | null>(null)

export const GlobalConfigProvider: React.FC<{
	children: React.ReactNode
	initialValues?: Partial<GlobalConfigInfo>
}> = ({ children, initialValues = {} }) => {
	const store = useLocalObservable<GlobalConfigStoreType>(() =>
		createGlobalConfigStore(initialValues),
	)
	return <GlobalConfigContext.Provider value={store}>{children}</GlobalConfigContext.Provider>
}

export const useGlobalConfigStore = () => {
	const store = useContext(GlobalConfigContext)
	if (!store) {
		// this is especially useful in TypeScript so you don't need to be checking for null all the time
		throw new Error('useStore must be used within a StoreProvider.')
	}
	return store
}
