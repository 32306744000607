import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { DEFAULT_BOOK_COVER } from '@/settings/constant'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Modal, Segmented } from 'antd'
import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'
import { itemDTO } from 'src/api/main'
import { CurriculumsWithString, Subjects, SubjectsWithString } from 'src/api/product'
import { useMainPageStore, useSavedUserSourceStore } from 'src/store/main'
import styled, { css, useTheme } from 'styled-components'
import {
	getUserSourcesSelection,
	patchUserMeta,
	putUserSource,
	UserMeta,
	UserType,
	userTypeWithKorean,
} from '../../api/users'
import { useForm } from '../../utils/form'
import { Step } from '../common/Step'
import TextField from '../common/TextField'
import Icon from '../icon/Icon'
import { ArrowLeft, Check, Close, Sizes } from '../icon/IconList'

export type ModalType = 'selectBooksModal' | 'registeredModal'
type SelectBooksModalStepType = 'userType' | 'subject' | 'book' | 'mobileSelectedBook'

export interface SelectingBooksModalProps {
	modalType: ModalType
	onClose?: () => void
	isSelectBooksModalOpen: boolean
	onSubmitSelectedBookList?: (selected: Array<itemDTO>) => void
}

export const SelectingBooksModal: React.FC<SelectingBooksModalProps> = ({
	modalType = 'selectBooksModal',
	onClose,
	isSelectBooksModalOpen,
	onSubmitSelectedBookList,
}) => {
	const { isLoggedIn, user } = useAuthStore()
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const {
		userMeta,
		setUserMeta,
		subject,
		setSubject,
		selectedBookList,
		setAllSelectedBookList,

		initialSelectedBookList,
	} = useMainPageStore(
		({
			userMeta,
			setUserMeta,
			subject,
			selectedBookList,
			setSelectedBookList,
			setAllSelectedBookList,
			setSubject,

			initialSelectedBookList,
		}) => ({
			userMeta,
			setUserMeta,
			subject,
			selectedBookList,
			setSelectedBookList,
			setAllSelectedBookList,
			setSubject,

			initialSelectedBookList,
		}),
	)
	const { savedUserSource, setSavedUserSource, updateUserMeta } = useSavedUserSourceStore(
		({ savedUserSource, setSavedUserSource, updateUserMeta }) => ({
			savedUserSource,
			setSavedUserSource,
			updateUserMeta,
		}),
	)
	const [step, setStep] = useState<SelectBooksModalStepType>(
		modalType === 'selectBooksModal' ? 'book' : 'userType',
	)
	const [isError, setIsError] = useState(false)
	const [selectedPublisher, setSelectedPublisher] = useState<itemDTO>(null)
	const [selectedCurriculum, setSelectedCurriculum] = useState<itemDTO>(null)
	const [isEmptyBookList, setIsEmptyBookList] = useState(false)
	const [searchBookList, setSearchBookList] = useState<itemDTO[]>([])

	//맞춤 교재 등록
	const userSourceMutation = useMutation(putUserSource)
	//job, subject 등록
	const userMetaMutation = useMutation(patchUserMeta)

	useEffect(() => {
		if (isMobile && modalType === 'selectBooksModal' && selectedBookList.length > 0) {
			setStep('mobileSelectedBook')
			return
		}
		setStep(modalType === 'selectBooksModal' ? 'book' : 'userType')
	}, [modalType, width])
	useEffect(() => {
		setSelectedPublisher(null)
		setSelectedCurriculum(null)
	}, [subject])

	useEffect(() => {
		setTempSelectedBookList(initialSelectedBookList || selectedBookList)
	}, [isSelectBooksModalOpen, initialSelectedBookList, selectedBookList])

	const [tempSelectedBookList, setTempSelectedBookList] = useState<itemDTO[]>([])
	const isSubmitButtonDisabled = tempSelectedBookList.length === 0

	const submitSelectedBookList = useCallback(() => {
		if (isMobile && step === 'book') {
			//모바일이면, 다음 스텝으로
			setStep('mobileSelectedBook')
			return
		}

		if (onSubmitSelectedBookList) {
			onSubmitSelectedBookList(tempSelectedBookList)
			return
		}

		//저장하기
		setAllSelectedBookList(tempSelectedBookList)
		if (!isLoggedIn) {
			//비로그인시 브라우저에 저장하기
			setSavedUserSource({
				subject,
				selectedBookList: tempSelectedBookList,
			})
			onClose()
			return
		} else {
			//로그인시 서버에 저장
			userSourceMutation.mutate({
				subject: subject,
				items: tempSelectedBookList.map((item) => ({
					id: item.id,
					type: item.type,
				})),
			})
		}
		//유저 메타 저장
		if (modalType === 'registeredModal') {
			//등록된 유저일 경우 메타 저장하기
			userMetaMutation.mutate({ ...userMeta })
			updateUserMeta({
				mainSubject: userMeta.mainSubject,
				job: userMeta.job,
			})
		}
		onClose()
		tempSelectedBookList.map((item) => {
			mixpanelEvent('SelectedBookSelectButtonClicked', {
				subject: subject,
				selectedBookId: item.id,
				selectedBookTitle: item.title,
			})
		})
	}, [
		onSubmitSelectedBookList,
		tempSelectedBookList,
		isMobile,
		step,
		setAllSelectedBookList,
		isLoggedIn,
		setSavedUserSource,
		subject,
		onClose,
		userSourceMutation,
		modalType,
		userMetaMutation,
		userMeta,
		updateUserMeta,
	])
	//selected 로직
	const { data, isLoading } = useQuery(
		['userSourcesSelection', selectedCurriculum, selectedPublisher],
		() =>
			getUserSourcesSelection({
				curriculum: selectedCurriculum ? selectedCurriculum?.id.toString() : null,
				publisher: selectedPublisher ? selectedPublisher?.id.toString() : null,
				subject: subject,
			}),
		{
			onSuccess: (data) => {
				//최초 진입시
				if (!selectedPublisher) {
					//첫번째 출판사를 보여줌
					// 가장 많은 curriculum을 보여줌
					setSelectedPublisher(data.publisher.items[0])
					setSelectedCurriculum({
						id: data.sourceData.selectedCurriculum,
						title: CurriculumsWithString[data.sourceData.selectedCurriculum],
					})
				}
				//출판사 선택후
				if (!selectedCurriculum) {
					//최초에 가장 많은 curriculum을 보여줌
					setSelectedCurriculum({
						id: data.sourceData.selectedCurriculum,
						title: CurriculumsWithString[data.sourceData.selectedCurriculum],
					})
				}
				//검색결과가 없을 경우
				if (
					data.sourceData.workbook.items.length === 0 &&
					data.sourceData.textbook.items.length === 0
				) {
					setIsEmptyBookList(true)
				} else setIsEmptyBookList(false)
			},
			keepPreviousData: true,
			enabled: isSelectBooksModalOpen,
		},
	)
	const handlePublisher = (publisher: itemDTO) => {
		setSelectedPublisher(publisher)
		setSelectedCurriculum(null)
		setKeyword('')
		mixpanelEvent('SelectedBookCategoryClicked', {
			publisher: publisher.title,
			categoryDepth: '1depth',
			subject: subject,
		})
	}
	const handleCurriculum = (curriculum: itemDTO) => {
		setSelectedCurriculum(curriculum)
		setKeyword('')
		mixpanelEvent('SelectedBookCategoryClicked', {
			publisher: curriculum.title,
			categoryDepth: '1depth',
			subject: subject,
		})
	}
	const handleBookList = (book: itemDTO) => {
		const isExist = tempSelectedBookList.find((item) => item.id === book.id)
		if (isExist) {
			setTempSelectedBookList(tempSelectedBookList.filter((item) => item.id !== book.id))
		} else {
			setTempSelectedBookList([...tempSelectedBookList, book])
		}
	}
	const [keyword, setKeyword] = useState('')
	const handleSearch = (keyword: string) => {
		setKeyword(keyword)
		const result = data.sourceData.workbook.items
			.concat(data.sourceData.textbook.items)
			.filter((item) => item.title.includes(keyword))
		setSearchBookList(result)
		mixpanelEvent('SelectedBookSearch', {
			keyword: keyword,
			subject: subject,
			category1Depth: selectedPublisher?.title,
			category2Depth: selectedCurriculum?.title,
			searchResult: result.length,
			searchResultList: result.map((item) => item.id),
		})
	}
	return (
		<SelectingBooksModalWrapper>
			<Step condition={step === 'userType'}>
				<UserTypeStep
					onNext={() => setStep('subject')}
					userMeta={userMeta}
					setUserMeta={setUserMeta}
					onClick={(userType) => {
						setUserMeta({ ...userMeta, job: userType as UserType })
						//onNext()
						setStep('subject')
						mixpanelEvent('UserTypeSelectButtonClicked', { userType: userType })
					}}
				/>
			</Step>
			<Step condition={step === 'subject'}>
				<SubjectStep
					onPrev={() => setStep('userType')}
					onNext={() => setStep('book')}
					isError={isError}
					userMeta={userMeta}
					onClick={(subject) => {
						mixpanelEvent('SubjectSelectButtonClicked', { subject: subject })
						if (!subjectList.includes(subject)) {
							setIsError(true)
							return
						}
						setIsError(false)
						setUserMeta({ ...userMeta, mainSubject: subject })
						setSubject(subject)
						//onNext
						setStep('book')
					}}
				/>
			</Step>
			<Step condition={step === 'book'}>
				<BookStep
					onClose={onClose}
					onNext={() => setStep('mobileSelectedBook')}
					onPrev={() => setStep('subject')}
					modalType={modalType}
					isLoading={isLoading}
					isSubmitButtonDisabled={isSubmitButtonDisabled}
					selectedBookList={tempSelectedBookList}
					setSelectedBookList={handleBookList}
					submitSelectedBookList={submitSelectedBookList}
					isEmptyBookList={isEmptyBookList}
					data={data}
					handlePublisher={handlePublisher}
					selectedPublisher={selectedPublisher}
					setSelectedCurriculum={handleCurriculum}
					selectedCurriculum={selectedCurriculum}
					handleSearch={handleSearch}
					searchBookList={searchBookList}
					keyword={keyword}
					setKeyword={setKeyword}
				/>
			</Step>
			<Step condition={step === 'mobileSelectedBook'}>
				<MobileSelectedBook
					onClose={onClose}
					onNext={() => setStep(null)}
					onPrev={() => setStep('book')}
					modalType={modalType}
					isSubmitButtonDisabled={isSubmitButtonDisabled}
					selectedBookList={tempSelectedBookList}
					setSelectedBookList={handleBookList}
					submitSelectedBookList={submitSelectedBookList}
				/>
			</Step>
		</SelectingBooksModalWrapper>
	)
}

interface CommonModalProps {
	onNext: () => void
	onPrev?: () => void
	modalType?: ModalType
	onClose?: () => void
}

interface UserTypeStepProps extends CommonModalProps {
	setUserMeta: (userMeta: UserMeta) => void
	userMeta: UserMeta
	onClick: (userType: string) => void
}

//유저 타입 선택
const userTypeList: UserType[] = ['teacher', 'principal', 'student', 'parent', 'etc']
const UserTypeStep: React.FC<UserTypeStepProps> = ({ onNext, setUserMeta, userMeta, onClick }) => {
	return (
		<StepModalWrapper>
			<StepModalStatus currentStep={'userType' as SelectBooksModalStepType} />
			<StepModalHeader className={'header'}>
				<h5>
					맞춤 솔루션을 제공하기 위해 <br />
					몇가지 정보가 필요해요
				</h5>
				<p>
					입력하신 정보는 다른 사람에게 노출되지 <br /> 않으니 걱정하지 마세요
				</p>
			</StepModalHeader>
			<div className={'button-wrapper'}>
				{userTypeList.map((user, idx) => {
					return (
						<Button
							key={`userType-${idx}`}
							aria-selected={userMeta.job === user}
							onClick={() => onClick(user)}>
							{userTypeWithKorean[user]}
						</Button>
					)
				})}
			</div>
		</StepModalWrapper>
	)
}

//과목 선택
interface SubjectStepProps extends CommonModalProps {
	onClick: (subject: Subjects) => void
	isError: boolean
	userMeta: UserMeta
}

const subjectList: Subjects[] = [
	Subjects.EN,
	Subjects.KO,
	Subjects.MA,
	// Subjects.SC,
	// Subjects.SO,
	// Subjects.SL,
]
const SubjectStep: React.FC<SubjectStepProps> = ({
	onNext,
	onPrev,
	onClick,
	isError,
	userMeta,
}) => {
	return (
		<StepModalWrapper>
			<StepModalStatus onPrev={onPrev} currentStep={'subject' as SelectBooksModalStepType} />
			<StepModalHeader>
				<h5>과목을 선택해주세요</h5>
				<p>선택하신 과목으로 메인페이지를 설정해드려요</p>
			</StepModalHeader>
			<div className={'button-wrapper'}>
				{subjectList.map((subject, idx) => {
					return (
						<Button
							key={`subject-${idx}`}
							aria-selected={userMeta.mainSubject === subject}
							onClick={() => onClick(subject)}>
							{SubjectsWithString[subject]}
						</Button>
					)
				})}
				<div className={'small-button-wrapper'}>
					<Button onClick={() => onClick(Subjects.SO)}>사회</Button>
					<Button onClick={() => onClick(Subjects.SC)}>과학</Button>
				</div>
				<div className={'small-button-wrapper'}>
					<Button onClick={() => onClick(Subjects.HI)}>역사</Button>
					<Button onClick={() => onClick(Subjects.SL)}>제2외국어</Button>
				</div>
				{isError && <p className={'error-text'}> 해당 과목은 준비중 입니다. </p>}
			</div>
		</StepModalWrapper>
	)
}

//교재 선택
interface BookStepProps extends CommonModalProps {
	isLoading: boolean
	isSubmitButtonDisabled: boolean
	selectedBookList: itemDTO[]
	setSelectedBookList: (book: itemDTO) => void
	submitSelectedBookList: () => void
	isEmptyBookList: boolean
	data: {
		publisher: { items: itemDTO[] }
		curriculum: { items: itemDTO[] }
		sourceData: {
			workbook: {
				items: itemDTO[]
			}
			textbook: {
				items: itemDTO[]
			}
		}
	}
	handlePublisher: (publisher: itemDTO) => void
	selectedPublisher: itemDTO
	setSelectedCurriculum: (curriculum: itemDTO) => void
	selectedCurriculum: itemDTO
	handleSearch: (keyword: string) => void
	searchBookList: itemDTO[]
	keyword: string
	setKeyword: (keyword: string) => void
}

const textMapper = {
	textbook: '교과서',
	workbook: '참고서',
	search: '검색결과',
}
const BookStep: React.FC<BookStepProps> = ({
	onNext,
	onPrev,
	modalType,
	onClose,
	isLoading,
	isSubmitButtonDisabled,
	selectedBookList,
	setSelectedBookList,
	submitSelectedBookList,
	isEmptyBookList,
	data,
	handlePublisher,
	selectedPublisher,
	setSelectedCurriculum,
	selectedCurriculum,
	handleSearch,
	searchBookList,
	keyword,
	setKeyword,
}) => {
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const { values, setValue, submit, isSubmitting, isValidated, reset } = useForm(
		{
			keyword: '',
		},
		{
			validator: (values) => {
				const isValid = values.keyword.trim().length > 0
				return [isValid, isValid ? undefined : '검색어를 1글자 이상 입력해주세요.']
			},
			onSubmit: async (done, { isValid, message }) => {
				if (!isValid) {
					Modal.warn({ title: message })
					done()
					return
				}
				handleSearch(values.keyword)
				done()
			},
		},
	)
	useEffect(() => {
		if (keyword.length === 0) reset()
	}, [keyword])
	if (isLoading || !data) return <></>
	const { publisher, curriculum, sourceData } = data
	const renderBookSection = (title, items) => {
		return (
			<>
				<p>{textMapper[title]}</p>
				<div className={'grid-book-list'}>
					{items.map((product, idx) => {
						const isSelected = selectedBookList.some((item) => item.id === product.id)
						const imageSrc = product.imageUrl.includes('http')
							? product.imageUrl
							: DEFAULT_BOOK_COVER
						return (
							<div
								key={idx}
								className={`book-item ${isSelected ? 'selected' : ''}`}
								onClick={() => setSelectedBookList({ ...product, type: title })}>
								{isSelected && <CheckboxIcon />}
								<div className={'book-cover'}>
									<Image
										unoptimized={true}
										src={imageSrc}
										width={68}
										height={96}
										alt={'book-thumbr'}
									/>
								</div>
								<p className={'book-title'}>{product.title}</p>
							</div>
						)
					})}
				</div>
			</>
		)
	}
	return (
		<StepModalBookSelectWrapper>
			{modalType === 'selectBooksModal' ? (
				<CloseButton onClick={onClose}>
					<Icon
						icon={Close}
						size={isMobile ? Sizes.xmedium : Sizes.medium}
						color={theme.new.colors.sol_gray_700}
					/>
				</CloseButton>
			) : (
				<StepModalStatus onPrev={onPrev} currentStep={'book' as SelectBooksModalStepType} />
			)}
			<StepModalHeader>
				<h5>
					찾고 싶은 교재를 <div className={'block_m'} />
					<strong>1개 이상 선택해주세요</strong>
				</h5>
				<p>선택하신 교재들로 수업자료를 추천해드려요</p>
			</StepModalHeader>
			<SelectBooksContainer modalType={modalType}>
				<ul>
					{publisher.items.map((publisher, idx) => {
						return (
							<li
								onClick={() => handlePublisher(publisher)}
								className={selectedPublisher?.id === publisher.id ? 'active' : ''}
								key={'publisher-' + idx}>
								{publisher.title}
							</li>
						)
					})}
				</ul>
				<div className={'book-select-wrapper'}>
					<div className={'book-select-publisher'}>{selectedPublisher?.title}</div>
					<form
						className={'book-select-list'}
						onSubmit={(e) => {
							e.preventDefault()
							submit()
						}}>
						<TextField
							placeholder="교재명을 입력해주세요"
							name={'keyword'}
							value={values.keyword}
							search
							onChange={(ev) => setValue('keyword', ev.target.value)}
							onClear={() => {
								setValue('keyword', '')
							}}
						/>
						<Segmented
							onChange={(e) =>
								setSelectedCurriculum(
									data.curriculum.items.find((item) => item.id === e),
								)
							}
							value={selectedCurriculum?.id}
							onResize={() => {}} //타입에러로 인한추가
							onResizeCapture={() => {}} //타입에러로 인한추가
							options={[
								{
									value: '30',
									label: '고등',
								},
								{
									value: '20',
									label: '중등',
								},
								{
									value: '10',
									label: '초등',
								},
							]}
							block
						/>
					</form>
					<div className={'book-list-section'}>
						{keyword.length > 0 ? (
							// 키워드가 있는 경우의 렌더링
							searchBookList.length === 0 ? (
								<p className={'empty-text'}>
									<strong>{keyword}</strong>의 검색결과가 없습니다 <br />
									출판사 및 학년이 맞게 선택되어 있는지 확인해주세요.
								</p>
							) : (
								renderBookSection('search', searchBookList)
							)
						) : // 키워드가 없는 경우의 렌더링
						isEmptyBookList ? (
							<p className={'empty-text'}>
								해당 학년의 교재가 아직 등록되지 않았어요
							</p>
						) : (
							<>
								{sourceData.workbook.items.length > 0 &&
									renderBookSection('workbook', sourceData.workbook.items)}
								{sourceData.textbook.items.length > 0 &&
									renderBookSection('textbook', sourceData.textbook.items)}
							</>
						)}
					</div>
				</div>
				<SelectedBookWrapper className={'selected-book-wrapper'} modalType={modalType}>
					<p>나의 교재</p>
					<div className={'selected-book-list'}>
						{selectedBookList &&
							selectedBookList.map((product, idx) => {
								return (
									<div key={idx} className={'selected-book-item'}>
										<Image
											unoptimized={true}
											src={product.imageUrl}
											width={44}
											height={62}
											alt={'book-cover'}
										/>
										<div>
											<p className={'book-title'}>{product.title}</p>
											<Button onClick={() => setSelectedBookList(product)}>
												삭제
											</Button>
										</div>
									</div>
								)
							})}
					</div>
					<div className={'bottom-section'}>
						<Button
							type={'primary'}
							disabled={isSubmitButtonDisabled}
							onClick={submitSelectedBookList}>
							{isSubmitButtonDisabled
								? '1개 이상 선택해주세요'
								: `${selectedBookList.length}개 등록하기`}
						</Button>
					</div>
				</SelectedBookWrapper>
			</SelectBooksContainer>
			<div className={'bottom-section mobile'}>
				<Button
					type={'primary'}
					disabled={isSubmitButtonDisabled}
					onClick={submitSelectedBookList}>
					{isSubmitButtonDisabled
						? '1개 이상 선택해주세요'
						: `${selectedBookList.length}개 ${isMobile ? '선택 완료' : '등록하기'}`}
				</Button>
			</div>
		</StepModalBookSelectWrapper>
	)
}

interface MobileSelectedBookProps extends CommonModalProps {
	selectedBookList: itemDTO[]
	setSelectedBookList: (book: itemDTO) => void
	isSubmitButtonDisabled: boolean
	submitSelectedBookList: () => void
	modalType: ModalType
}

const MobileSelectedBook: React.FC<MobileSelectedBookProps> = ({
	onNext,
	onPrev,
	onClose,
	selectedBookList,
	setSelectedBookList,
	isSubmitButtonDisabled,
	submitSelectedBookList,
	modalType,
}) => {
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	useEffect(() => {
		// 뷰포트 높이를 기반으로 --vh 변수를 설정하는 함수
		const setElementHeight = () => {
			const vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
			window.addEventListener('resize', setElementHeight)
		}
		setElementHeight()

		return () => {
			window.removeEventListener('resize', setElementHeight)
		}
	}, [selectedBookList])

	return (
		<MobileSelectedBookWrapper>
			{modalType === 'selectBooksModal' ? (
				<CloseButton onClick={onClose}>
					<Icon
						icon={Close}
						size={isMobile ? Sizes.xmedium : Sizes.medium}
						color={theme.new.colors.sol_gray_700}
					/>
				</CloseButton>
			) : (
				<StepModalStatus onPrev={onPrev} currentStep={'book' as SelectBooksModalStepType} />
			)}
			<SelectedBookWrapper className={'selected-book-wrapper mobile'} modalType={modalType}>
				<p>선택한 나의 교재를 확인해 주세요</p>
				<div className={'selected-book-list mobile'}>
					{selectedBookList &&
						selectedBookList.map((product, idx) => {
							return (
								<div key={idx} className={'selected-book-item'}>
									<Image
										unoptimized={true}
										src={product.imageUrl}
										width={44}
										height={62}
										alt={'book-cover'}
									/>
									<div>
										<p className={'book-title'}>{product.title}</p>
										<Button onClick={() => setSelectedBookList(product)}>
											삭제
										</Button>
									</div>
								</div>
							)
						})}
				</div>
				<div className={'bottom-section'}>
					<Button type={'primary'} ghost onClick={onPrev}>
						다른 교재 추가하기
					</Button>
					<Button
						type={'primary'}
						disabled={isSubmitButtonDisabled}
						onClick={submitSelectedBookList}>
						{isSubmitButtonDisabled
							? '1개 이상 선택해주세요'
							: `${selectedBookList.length}개 등록하기`}
					</Button>
				</div>
			</SelectedBookWrapper>
		</MobileSelectedBookWrapper>
	)
}
const MobileSelectedBookWrapper = styled.div`
	padding-top: 20px;
`
export const CloseButton = styled.button`
	border: none;
	display: block;
	position: absolute;
	background: transparent;
	top: 0px;
	right: 0px;
	font-size: 0px;
	width: 64px;
	height: 64px;
	@media (max-width: 1200px) {
	}
	@media (max-width: 768px) {
		z-index: 200;
		top: 10px;
		right: 15px;
		font-size: 0px;
		width: 20px;
		height: 20px;
	}
`
const CheckboxIcon = () => {
	return (
		<CheckboxWrapper>
			<Icon icon={Check} size={Sizes.xxsmall} color={'#fff'} />
		</CheckboxWrapper>
	)
}
const CheckboxWrapper = styled.div`
	width: 18px;
	height: 18px;
	background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 7px;
	top: 7px;
`

const StepModalStatus = ({
	currentStep,
	onPrev,
}: {
	currentStep: SelectBooksModalStepType
	onPrev?: () => void
}) => {
	const theme = useTheme()
	return (
		<StepStatusWrapper currentStep={currentStep}>
			{currentStep !== 'userType' && (
				<button className={'go-back'} onClick={onPrev}>
					<Icon
						icon={ArrowLeft}
						size={Sizes.medium}
						color={theme.new.colors.sol_gray_800}
					/>
				</button>
			)}
			<div className={'step userType'} />
			<div className={'step subject'} />
			<div className={'step book'} />
		</StepStatusWrapper>
	)
}

const SelectBooksContainer = styled.div<{ modalType: ModalType }>`
	border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	display: flex;
	//height: 602px;
	overflow: hidden;

	ul {
		padding: 20px 0;
		width: 150px;
		border-right: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		white-space: pre;

		li {
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			padding: 8px 20px;
			text-align: left;
			cursor: pointer;

			:hover {
				background-color: ${(props) => props.theme.new.colors.sol_gray_0};
			}

			&.active {
				background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
				color: ${(props) => props.theme.new.colors.sol_indigo_500};
				font-weight: 700;
			}
		}

		@media (max-width: 768px) {
			padding: 0;
			li {
				padding: 8px 16px;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	.book-select-wrapper {
		width: 584px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_0};
		border-right: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

		.book-select-publisher {
			padding: 12px 20px;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
			background-color: #fff;
		}

		.book-select-list {
			background-color: ${(props) => props.theme.new.colors.sol_gray_0};
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			.textfield {
				background-color: ${(props) => props.theme.new.colors.white};
			}

			input {
				padding-top: 12px;
				padding-bottom: 12px;
			}

			.ant-segmented {
				padding: 4px;
				border-radius: 4px;
				background-color: transparent;

				.ant-segmented-item:after,
				.ant-segmented-item-selected,
				.ant-segmented-item-input,
				.ant-segmented-thumb {
					background-color: transparent;
					//border-bottom: 2px solid ${(props) => props.theme.new.colors.sol_gray_900};
					box-shadow: none;
					//border-radius: 4px;
				}

				.ant-segmented-item-selected .ant-segmented-item-label {
					color: ${(props) => props.theme.new.colors.sol_gray_900};
					padding: 0;
					background-color: transparent;
				}

				.ant-segmented-group {
					display: flex;
					gap: 20px;

					.ant-segmented-item {
						border-bottom: 2px solid transparent;
					}

					.ant-segmented-item-selected {
						border-bottom: 2px solid ${(props) => props.theme.new.colors.sol_gray_900};
						background-color: transparent;
						box-shadow: none;
						color: ${(props) => props.theme.new.colors.sol_gray_900};
					}

					> label {
						padding: 4px 13px;
						//border-radius: 4px;

						> div {
							color: ${(props) => props.theme.new.colors.sol_gray_300};
							font-size: 16px;
							font-weight: 700;
							padding: 0;
						}
					}
				}

				@media (max-width: 768px) {
					padding: 3px;
					.ant-segmented-item-label {
						padding: 0;
					}

					.ant-segmented-group {
						> label {
							padding: 2px 16px;

							> div {
								font-size: 14px;
							}
						}
					}
				}
			}
		}

		.book-list-section {
			//height: 492px;
			height: ${(props) =>
				props.modalType === 'selectBooksModal'
					? 'calc(var(--vh, 1vh) * 100 - 426px)'
					: 'calc(var(--vh, 1vh) * 100 - 450px)'};
			overflow-y: auto;
			overscroll-behavior: contain;
			padding: 0 20px;
			position: relative;
			//display: flex; //
			//align-items: center;
			> p {
				color: ${(props) => props.theme.new.colors.sol_gray_700};
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				margin-bottom: 8px;
			}

			.empty-text {
				color: ${(props) => props.theme.new.colors.sol_gray_500};
				text-align: center;
				font-weight: 600;
				line-height: 24px;
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@media (max-width: 768px) {
				height: ${(props) =>
					props.modalType === 'selectBooksModal'
						? 'calc(var(--vh, 1vh) * 100 - 407px)'
						: 'calc(var(--vh, 1vh) * 100 - 420px)'};
				padding: 0 12px;
				> p {
					font-size: 14px;
				}
			}
		}

		.grid-book-list {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 8px;
			@media (max-width: 1200px) {
				grid-template-columns: repeat(3, 1fr);
			}
			@media (max-width: 1060px) {
				grid-template-columns: repeat(2, 1fr);
			}

			.book-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				//width: 130px;
				gap: 16px;
				padding: 16px 8px;
				border-radius: 6px;
				position: relative;
				cursor: pointer;
				height: 100%;

				:hover {
					background-color: ${(props) => props.theme.new.colors.sol_gray_50};
				}

				.book-cover {
					height: 96px;
					display: flex;
					align-items: center;
				}

				img {
					border-radius: 2px;
					box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
				}

				.book-title {
					color: ${(props) => props.theme.new.colors.sol_gray_700};
					text-align: center;
					font-size: 14px;
					line-height: 22px; /* 157.143% */
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}

				&.selected {
					background-color: ${(props) => props.theme.new.colors.sol_indigo_50};

					.book-title {
						color: ${(props) => props.theme.new.colors.sol_indigo_500};
					}
				}
			}
		}

		@media (max-width: 768px) {
			.book-select-publisher {
				display: none;
			}

			.book-select-list {
				padding: 16px 12px;
			}

			.grid-book-list {
				.book-item {
					//width: 100%;
					img {
						width: 44px;
					}
				}
			}
		}
	}
`
const SelectedBookWrapper = styled.div<{ modalType: ModalType }>`
	max-width: 335px;
	@media (max-width: 1200px) {
		max-width: 100%;
	}
	@media (max-width: 768px) {
		&.mobile {
			display: block;

			> p {
				padding: 48px 0 16px;
				text-align: center;
				font-size: 20px;
			}

			.selected-book-list {
				&.mobile {
					height: ${(props) =>
						props.modalType === 'selectBooksModal'
							? 'calc(var(--vh, 1vh) * 100 - 232px)'
							: 'calc(var(--vh, 1vh) * 100 - 257px)'};
				}
			}

			.bottom-section {
				padding: 20px;
				display: flex;
				gap: 12px;

				.ant-btn {
					min-width: unset;

					&.ant-btn-background-ghost {
						background-color: ${(props) => props.theme.new.colors.sol_indigo_100};
					}
				}
			}
		}

		display: none;
	}

	> p {
		color: ${(props) => props.theme.new.colors.sol_gray_900};
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		padding: 12px 20px;
		border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		border-top: none;
	}

	.selected-book-list {
		height: ${(props) =>
			props.modalType === 'selectBooksModal'
				? 'calc(var(--vh, 1vh) * 100 - 368px)'
				: 'calc(var(--vh, 1vh) * 100 - 392px)'};
		overflow: auto;
		overscroll-behavior: contain;
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};

		.selected-book-item {
			background-color: #fff;
			display: flex;
			padding: 16px 20px;
			gap: 16px;
			max-width: 335px;

			> img {
				height: 62px;
				box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
			}

			border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

			> div {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;
				width: 100%;
				align-items: flex-end;

				p {
					text-align: left;
					overflow: hidden;
					color: ${(props) => props.theme.new.colors.sol_gray_700};
					text-overflow: ellipsis;
					font-size: 14px;
					line-height: 22px;
					width: 100%;
				}

				button {
					align-items: flex-end;
					height: unset;
					display: inline;
					width: fit-content;
					padding: 2px 8px;
					border: none;
					background-color: ${(props) => props.theme.new.colors.sol_gray_0};
					border-radius: 4px;

					> span {
						color: ${(props) => props.theme.new.colors.sol_gray_500};
						font-size: 14px;
						line-height: 22px; /* 157.143% */
					}

					:hover,
					:active {
						transition: all 0.5s ease;
						background-color: ${(props) => props.theme.new.colors.sol_red_400};

						> span {
							transition: all 0.5s;
							color: #fff;
							font-weight: 600;
						}
					}
				}
			}
		}

		@media (max-width: 768px) {
			.selected-book-item {
				max-width: unset;
			}
		}
	}

	.bottom-section {
		width: 336px;
		padding: 20px 20px 20px 20px;
		border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		background-color: #fff;

		@media (max-width: 1020px) {
			width: 100%;
		}

		.ant-btn {
			padding: 12px 20px;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			height: 48px;
			width: 100%;
			border-radius: 6px;
			border: transparent;

			:hover,
			:active {
				background-color: ${(props) => props.theme.new.colors.sol_indigo_600};
			}

			:disabled {
				border: transparent;
				background-color: ${(props) => props.theme.new.colors.sol_gray_100};
				color: ${(props) => props.theme.new.colors.sol_gray_300};
			}

			@media (max-width: 1020px) {
				min-width: 210px;
			}
		}
	}

	//}
`
const StepStatusWrapper = styled.div<{ currentStep: SelectBooksModalStepType }>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	position: relative;
	height: 24px;

	.go-back {
		all: unset;
		position: absolute;
		left: -24px;
	}

	.step {
		width: 8px;
		height: 8px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_200};
		border-radius: 16px;
	}

	${({ currentStep }) =>
		currentStep === 'userType'
			? css`
					.userType {
						background-color: ${(props) => props.theme.new.colors.sol_gray_800};
					}
			  `
			: currentStep === 'subject'
			? css`
					.userType,
					.subject {
						background-color: ${(props) => props.theme.new.colors.sol_gray_800};
					}
			  `
			: css`
					.userType,
					.subject,
					.book {
						background-color: ${(props) => props.theme.new.colors.sol_gray_800};
					}
			  `}
`

const StepModalWrapper = styled.div`
	width: 480px;
	height: 588px;
	padding: 20px 40px 0;

	.button-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		justify-content: center;
		align-items: center;

		button {
			width: 100%;
			height: 100%;
			padding: 15px 16px;
			border-radius: 4px;
			background-color: ${(props) => props.theme.new.colors.sol_gray_0};
			color: ${(props) => props.theme.new.colors.sol_gray_700};
			font-size: 18px;
			font-weight: 700;
			border: transparent;
			line-height: 26px;

			:hover {
				color: ${(props) => props.theme.new.colors.sol_gray_700};
				background-color: ${(props) => props.theme.new.colors.sol_gray_50};
			}

			&[aria-selected='true'] {
				background-color: ${(props) => props.theme.new.colors.sol_gray_100};
			}
		}
	}

	.small-button-wrapper {
		display: flex;
		gap: 8px;
		width: 100%;

		.ant-btn {
			width: 50%;
		}
	}

	.error-text {
		color: ${(props) => props.theme.new.colors.sol_red_400};
		font-size: 14px;
		font-weight: 600;
		line-height: 22px; /* 157.143% */
		padding: 9px 0;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`
const StepModalHeader = styled.div`
	all: unset;
	margin: 32px 0;
	display: block;

	h5 {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		line-height: 32px; /* 133.333% */
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		text-align: center;

		font-size: 14px;

		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}

	strong {
		font-weight: 700;
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
	}

	.block_m {
		display: none;
	}

	@media (max-width: 768px) {
		h5 {
			font-size: 20px;
			line-height: 1.5;
		}

		p {
			font-size: 14px;
			line-height: 1.5;
		}

		.block_m {
			display: block;
		}
	}
`
const StepModalBookSelectWrapper = styled.div`
	max-width: 1060px;
	padding-top: 20px;

	.go-back {
		left: 40px;
	}

	.mobile {
		display: none;
		@media (max-width: 768px) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 12px 20px;
			background-color: white;
			display: flex;
			> .ant-btn {
				flex: 1;
				width: 100%;
				padding: 12px 20px;
				height: 48px;
				font-size: 16px;
				border-radius: 6px;
				font-weight: 600;
				line-height: 24px;
				border: transparent;

				:disabled {
					border: transparent;
					background-color: ${(props) => props.theme.new.colors.sol_gray_100};
					color: ${(props) => props.theme.new.colors.sol_gray_300};
				}
			}
		}
	}
`
const SelectingBooksModalWrapper = styled.div``
