export const includesPath = (path: string, target: string) => {
	// 연구소 페이지 [] 텍스트로 인해 수정
	const escapedTarget = target.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
	// return !!new RegExp(`^${target}`).exec(path)
	return !!new RegExp(`^${escapedTarget}`).exec(path)
}

export const toQueryString = (obj: object | null, withQuerySymbol = true) => {
	if (obj === null) {
		return ''
	}

	const query = Object.keys(obj)
		.filter((key) => key !== '' && obj[key] !== undefined && obj[key] !== '')
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
		.join('&')

	if (!query) {
		return ''
	}

	return withQuerySymbol ? `?${query}` : query
}

export const getUrlStringToParams = (url: string): Record<string, any> => {
	const params = {}
	const urlSplit = url.split('?')
	if (urlSplit.length > 1) {
		const paramsSplit = urlSplit[1].split('&')
		paramsSplit.forEach((param) => {
			const paramSplit = param.split('=')
			params[paramSplit[0]] = paramSplit[1]
		})
	}
	return params
}
