import { useState } from 'react'

export interface MobileFilterSideSheetValue {
	isOpen: boolean
	value: string
}

export interface MobileFilterSideSheetType {
	mobileFilterSideSheet: MobileFilterSideSheetValue
	handleMobileSideSheet: (isOpen: boolean, value: string) => void
}

export const useMobileFilterSideSheet = (): MobileFilterSideSheetType => {
	const [mobileFilterSideSheet, setMobileFilterSideSheet] = useState<MobileFilterSideSheetValue>({
		isOpen: false,
		value: '',
	})
	const handleMobileSideSheet = (isOpen: boolean, value: string) => {
		setMobileFilterSideSheet({ isOpen, value })
	}
	return {
		mobileFilterSideSheet,
		handleMobileSideSheet,
	}
}
