import React from 'react'
import styled, { useTheme } from 'styled-components'
import Icon from '@/components/icon/Icon'
import { Close, Sizes } from '@/components/icon/IconList'
import { Drawer } from '@mui/material'

export interface MobileFilterSliderProps {
	title?: string
	children: React.ReactNode
	sliderOpen: { isOpen: boolean; value: string }
	openSlider: (isOpen: boolean, title: string) => void
}

export const MobileFilterSideSheet: React.FC<MobileFilterSliderProps> = ({
	title,
	children,
	sliderOpen,
	openSlider,
}) => {
	const theme = useTheme()
	const onClose = () => {
		openSlider(false, '')
	}
	const titleComponent = (
		<div className={'title'}>
			<p>{title}</p>
			<Icon
				icon={Close}
				size={Sizes.small}
				color={theme.new.colors.sol_gray_900}
				onClick={onClose}
			/>
		</div>
	)

	return (
		<MobileFilterSliderWrapper
			anchor="right"
			onClose={onClose}
			className={'mobile-filter-slider'}
			open={sliderOpen.isOpen}>
			{titleComponent}
			{children}
		</MobileFilterSliderWrapper>
	)
}

const MobileFilterSliderWrapper = styled(Drawer)`
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 16px;
		border-bottom: 1px solid rgb(232, 232, 234);
		font-weight: 600;
		font-size: 16px;
	}

	.MuiPaper-root {
		width: 50%;
		@media (max-width: 768px) {
			width: 80%;
		}
	}
`
