import { create } from 'zustand'

export interface LoginDialogState {
	isOpenLoginDialog: boolean

	isOpenSignUpDialog: boolean // 회원가입에서 사용할 다이얼로그 상태와 내용
	openSignUpDialogContents: {
		title: string
		content: string
		btnLabel?: string
		severity?: 'success' | 'caution'
		onOk?: () => void
	}
}

export interface LoginDialogActions {
	closeLoginDialog: () => void
	openLoginDialog: () => void
	setState: <T extends keyof LoginDialogState>(key: T, param: LoginDialogState[T]) => void
}

const LoginDialog = create<LoginDialogState & LoginDialogActions>((set) => ({
	isOpenLoginDialog: false,
	closeLoginDialog: () => set({ isOpenLoginDialog: false }),
	openLoginDialog: () => set({ isOpenLoginDialog: true }),

	isOpenSignUpDialog: false,
	openSignUpDialogContents: { title: '', content: '', btnLabel: '' },
	setState: (key, param) => {
		set((state) => ({ ...state, [key]: param }))
	},
}))
export default LoginDialog
