import Image from 'next/image'
import Link from 'next/link'
import { Category } from 'src/api/category'

import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { BOOKS_LANDING, EBOOKS_LANDING, EBOOKS_UNIVERSITY_LANDING } from '@/settings/constant'
import { Divider, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getAdBanner } from 'src/api/adBanner'
import { useSideCategoryMenuStore } from '../SideSheets/store/useCategoryMenuStore'
import Icon from '../icon/Icon'
import { ArrowRight, GoodnoteIcon, Sizes } from '../icon/IconList'
import useCategoryPanelData from './hooks/useCategoryPanelData'
import useCloseHandler from './hooks/useCloseHandler'
import {
	AdStack,
	Box,
	BoxColumn,
	BoxContainer,
	BoxTitle,
	ButtonBox,
	CategoryBox,
	CategoryContainer,
	Label,
	LabelBox,
	MenuAdBox,
	MenuLinkBox,
	RowContainer,
	TwoColumnBox,
} from './index.style'

export const MenuColumn = ({
	data,
	onColumnClick,
	isActive,
	suffix,
}: {
	data: Category
	onColumnClick?: () => void
	isActive: boolean
	suffix?: boolean
}) => {
	return (
		<BoxColumn onClick={onColumnClick} className={isActive ? 'active' : ''}>
			<LabelBox>
				<Label>
					{data.name}
					{suffix && <Icon icon={ArrowRight} size={Sizes.small} />}
				</Label>
			</LabelBox>
		</BoxColumn>
	)
}

const CategoryMenu = ({ onClose }: { onClose: () => void }) => {
	useCloseHandler()
	const { closeSideMenu } = useSideCategoryMenuStore()
	const { data: menuAd } = useQuery(
		['menupanAd'],
		() => getAdBanner({ position: 'globalMenu' }),
		{ retry: 3 },
	)

	const {
		oneDepthId,
		setOneDepthId,
		twoDepthId,
		setTwoDepthId,
		threeDepthId,
		setThreeDepthId,
		depthData,
	} = useCategoryPanelData()

	//카테고리 닫아주기 위한 이벤트
	const linkIsNewTab = (link: string) => {
		return link.includes('https://solvook.com')
	}
	const categoryMenuMixpanel = (depth, title, url) => {
		mixpanelEvent('Category Menu Clicked', {
			Depth: depth,
			'Menu Name': title,
			Url: url,
		})
	}
	const categoryButtonMixpanel = (type, name, url) => {
		mixpanelEvent('Category Button Clicked', {
			'Button Type': type,
			'Menu Name': name,
			Url: url,
		})
	}
	if (!depthData.origin) return null
	return (
		<CategoryContainer
			onClick={(e) => {
				if (e.target === e.currentTarget) onClose()
			}}>
			<CategoryBox>
				<MenuLinkBox className="depth-one-big-width">
					<BoxTitle>과목</BoxTitle>

					<RowContainer>
						<BoxContainer>
							{depthData.origin.depthItems.map((menu) => (
								<MenuColumn
									key={menu.id}
									data={menu}
									onColumnClick={() => {
										categoryMenuMixpanel('one', menu.name, '')
										setOneDepthId(menu.id)
									}}
									isActive={oneDepthId === menu.id}
								/>
							))}
						</BoxContainer>
						<Box>
							{/* 원뎁스 상대경로 링크 */}
							<ButtonBox className={'active'}>
								<Typography
									variant="subtitle2"
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '4px',
										alignItems: 'center',
										marginBottom: '8px',
									}}>
									<Icon icon={GoodnoteIcon} size={Sizes.small} />
									굿노트6 eBook
								</Typography>
								<Link
									href={EBOOKS_UNIVERSITY_LANDING}
									onClick={() =>
										categoryButtonMixpanel(
											'goodnotes',
											'대학교재',
											EBOOKS_UNIVERSITY_LANDING,
										)
									}>
									<button>대학 교재</button>
								</Link>
							</ButtonBox>
							<ButtonBox>
								<Link
									href={EBOOKS_LANDING}
									onClick={() =>
										categoryButtonMixpanel(
											'goodnotes',
											'중고등 교재',
											EBOOKS_LANDING,
										)
									}>
									<button>중고등 교재</button>
								</Link>
							</ButtonBox>
							<Divider />
							<ButtonBox>
								<Link
									href={BOOKS_LANDING}
									onClick={() =>
										categoryButtonMixpanel('books', '배송 도서', BOOKS_LANDING)
									}>
									<button>배송 도서</button>
								</Link>
							</ButtonBox>
							{/* 외부링크, 서버에서 받은 절대경로 링크의 경우 */}
							{/* {depthData.origin.linkItems.map((item, idx) => (
								<ButtonBox key={item.id}>
									<Link
										href={item.link}
										key={idx}
										target={linkIsNewTab(item.link) ? '_self' : '_blank'}>
										<button key={idx}>{item.name}</button>
									</Link>
								</ButtonBox>
							))} */}
						</Box>
					</RowContainer>
				</MenuLinkBox>
				{depthData.two && ( //ex. 고등, 중등, 참고서, ebs
					<MenuLinkBox className="depth-one-big-width">
						<BoxTitle>{depthData.two.name}</BoxTitle>
						<RowContainer>
							<BoxContainer>
								{depthData.two.items.map((menu, idx) => (
									<MenuColumn
										key={menu.id}
										data={menu}
										onColumnClick={() => {
											categoryMenuMixpanel('two', menu.name, '')
											setTwoDepthId(menu.id)
										}}
										isActive={twoDepthId === menu.id}
									/>
								))}
							</BoxContainer>
						</RowContainer>
					</MenuLinkBox>
				)}
				{depthData.three && ( // ex. 능률, ybm, 지학사
					<MenuLinkBox className="depth-one-big-width">
						<BoxTitle>
							<Link
								href={`/categories?id=${depthData.three.id}`}
								onClick={() =>
									categoryMenuMixpanel('three', depthData.three.name, '')
								}>
								{depthData.three.name}
								<Icon icon={ArrowRight} size={Sizes.smallHalf} />
							</Link>
						</BoxTitle>
						<RowContainer>
							<BoxContainer>
								{depthData.three.items.map((menu, idx) => (
									<MenuColumn
										key={menu.id}
										data={menu}
										onColumnClick={() => {
											categoryMenuMixpanel('tree', menu.name, '')
											setThreeDepthId(menu.id)
										}}
										isActive={threeDepthId === menu.id}
									/>
								))}
							</BoxContainer>
						</RowContainer>
					</MenuLinkBox>
				)}
				{depthData.four && ( // ex. 능률 하위
					<MenuLinkBox className="depth-one-big-width">
						<BoxTitle>
							<Link
								href={`/categories?id=${depthData.four.id}`}
								onClick={() =>
									categoryMenuMixpanel(
										'four',
										depthData.four.name,
										`/categories?id=${depthData.four.id}`,
									)
								}>
								{depthData.four.name}
								<Icon icon={ArrowRight} size={Sizes.smallHalf} />
							</Link>
						</BoxTitle>
						<RowContainer>
							<TwoColumnBox>
								{depthData.four.items.map((menu, idx) => (
									<Link
										key={menu.id}
										href={`/categories?id=${menu.id}`}
										onClick={closeSideMenu}>
										<MenuColumn
											key={menu.id}
											data={menu}
											onColumnClick={() => {
												categoryMenuMixpanel(
													'tree',
													menu.name,
													`/categories?id=${menu.id}`,
												)
											}}
											isActive={threeDepthId === menu.id}
										/>
									</Link>
								))}
							</TwoColumnBox>
						</RowContainer>
					</MenuLinkBox>
				)}
			</CategoryBox>
			{menuAd && (
				<MenuAdBox>
					{menuAd.items.map((ad, idx) => (
						<AdStack key={idx}>
							<Link
								href={ad.link}
								target={linkIsNewTab(ad.link) ? '_self' : '_blank'}
								onClick={() => {
									mixpanelEvent('AD Banner Clicked', {
										//mx-18 메뉴판 광고 버튼 클릭 시
										ID: ad.id,
										'Button URL': ad.link,
										Position: 'globalMenu',
									})
								}}>
								<Image
									width={316}
									src={ad.imageUrl}
									height={80}
									alt="ad banner image"
								/>
							</Link>
						</AdStack>
					))}
				</MenuAdBox>
			)}
		</CategoryContainer>
	)
}

export default CategoryMenu
