import Router from 'next/router'
import { NextPageContext } from 'next'

function redirect(target: string, ctx?: NextPageContext, options?: { redirectUrl?: string }) {
	if (ctx && ctx.res) {
		// server
		// 303: "See other"
		// 302: "Found"
		ctx.res.writeHead(302, { Location: target })
		// ctx.res.finished = true
		ctx.res.end()
	} else {
		// import Router from 'next/router'
		// In the browser, we just pretend like this never even happened ;)

		if (options?.redirectUrl) {
			Router.replace(`${target}?redirect_url=${options.redirectUrl}`)
		} else {
			Router.replace(`${target}`)
		}
	}
}

export { redirect }
