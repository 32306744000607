import { useMutation } from '@tanstack/react-query'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { createStore, StoreApi, useStore } from 'zustand'
import { getProduct } from '../../api/product'
import Modal from '../modal/Modal'
import { PassInfoModal } from '../Package/PassInfoModal'
import { PreviewBookModal } from '../Landing/modals/PreviewBookModal'
import { styled } from '@mui/material'
import { useRouter } from 'next/router'

const PreviewDialogContext =
	createContext<StoreApi<PreviewDialogActions & PreviewDialogStates>>(null)

export interface PreviewDialogStates {}

export interface PreviewDialogActions {
	openPreviewDialog: ({ id }: { id: string }) => void
	closePreviewDialog: () => void
}

export const PreviewDialogProvider = ({ children }) => {
	const storeRef = useRef<StoreApi<PreviewDialogActions & PreviewDialogStates>>()
	const router = useRouter()
	const [productDetail, setProductDetail] = useState(null)
	const [previewModalOpen, setPreviewModalOpen] = useState(false)
	const getProductMutation = useMutation(getProduct)
	const openPreviewDialog = async ({ id }: { id: string }) => {
		try {
			const response = await getProductMutation.mutateAsync({ id })
			setProductDetail(response)
			setPreviewModalOpen(true)
		} catch (e) {
			window.alert('교재 정보를 불러오는데 실패했습니다.')
		}
	}
	const closePreviewDialog = () => {
		setPreviewModalOpen(false)
		setProductDetail(null)
	}
	if (!storeRef.current) {
		storeRef.current = createStore<PreviewDialogActions & PreviewDialogStates>((set) => ({
			openPreviewDialog,
			closePreviewDialog,
		}))
	}
	useEffect(() => {
		//페이지 이동시 닫혀야함.
		closePreviewDialog()
	}, [router])
	return (
		<PreviewDialogContext.Provider value={storeRef.current}>
			{children}
			{productDetail && (
				<>
					<StyledModal
						modalBgColor={'transparent'}
						disableOutsideClick={false}
						onClose={closePreviewDialog}
						open={previewModalOpen}
						name="mainPreviewModal">
						<PreviewBookModal
							data={productDetail.product}
							onClose={closePreviewDialog}
						/>
					</StyledModal>
					<PassInfoModal />
				</>
			)}
		</PreviewDialogContext.Provider>
	)
}
const StyledModal = styled(Modal)`
	width: 100%;

	> div {
		width: 100%;
	}
`
export const usePreviewDialogStore = () => {
	const store = useContext(PreviewDialogContext)
	if (!store) {
		throw new Error('Missing PreviewDialogProvider')
	}
	return useStore(store)
}
