import { FilterQueryHandlerType } from '@/hooks/useCategoryFilterHandler'
import * as React from 'react'
import { MobileFilterSideSheetValue } from '@/hooks/useMobileFilterSideSheet'
import { SelectedFilterWrapper } from './SelectedFilter'
import { DetailFilterButtonList } from '@/components/Categories/Menu/DetailFilterButton'
import {
	CustomSelectMenuItem,
	CustomTextField,
	SearchResultAmount,
	SearchResultDescWrap,
} from '@/components/Categories/Menu/index'
import { GetCategoryListResponse } from '../../../api/category'

export interface CategoryFilterSectionViewProps {
	filterItems: GetCategoryListResponse['filterItems']
	hasFilterItems: boolean
	handleMobileSlider: (isOpen: boolean, value: string) => void
	selectedFilterList: Array<{ key: string; value: string }>
	filterQueryHandler: (value: {
		filterType: FilterQueryHandlerType
		filterName?: string
		value?: string
	}) => void
	totalCount: number
	sortItems: Array<string>
	selectedSort: string
	isCheckFilterItem: (filterName: string, value: string) => boolean
	isCheckFilterCount: (filterName: string) => number
	mobileFilterSideSheet: MobileFilterSideSheetValue
}

export const CategoryFilterSectionView = ({
	filterItems,
	hasFilterItems,
	handleMobileSlider,
	selectedFilterList,
	filterQueryHandler,
	totalCount,
	sortItems,
	selectedSort,
	isCheckFilterItem,
	isCheckFilterCount,
	mobileFilterSideSheet,
}: CategoryFilterSectionViewProps) => {
	return (
		<>
			{hasFilterItems && (
				<DetailFilterButtonList
					filterItems={filterItems}
					handleMobileSlider={handleMobileSlider}
					isCheckFilterItem={isCheckFilterItem}
					isCheckFilterCount={isCheckFilterCount}
					selectedFilterList={selectedFilterList}
					mobileFilterSideSheet={mobileFilterSideSheet}
					filterQueryHandler={filterQueryHandler}
				/>
			)}
			{selectedFilterList.length > 0 && (
				<SelectedFilterWrapper
					className={'category'}
					position={undefined}
					button={
						<SelectedFilterWrapper.ResetButton
							handleResetFilter={() =>
								filterQueryHandler({
									filterType: FilterQueryHandlerType.RESET,
								})
							}
						/>
					}>
					{selectedFilterList.map((item, idx) => {
						return (
							<SelectedFilterWrapper.SelectedItem
								className={'category current-btn'}
								key={'selectedItem' + idx}
								label={item.value}
								onRemove={() =>
									filterQueryHandler({
										filterType: FilterQueryHandlerType.DETAILFILTER,
										filterName: item.key,
										value: item.value,
									})
								}
							/>
						)
					})}
				</SelectedFilterWrapper>
			)}

			<SearchResultDescWrap>
				<SearchResultAmount>총 {totalCount}건</SearchResultAmount>
				{sortItems.length > 0 && (
					<CustomTextField
						select
						value={selectedSort ?? sortItems[0]}
						onChange={(e) => {
							filterQueryHandler({
								filterType: FilterQueryHandlerType.SORT,
								value: e.target.value,
							})
						}}>
						{sortItems.map((option) => {
							return (
								<CustomSelectMenuItem
									key={option}
									value={option}
									className={'select'}>
									{option}
								</CustomSelectMenuItem>
							)
						})}
					</CustomTextField>
				)}
			</SearchResultDescWrap>
		</>
	)
}
