import { create } from 'zustand'
import { Subjects } from '../../../api/product'
import { Category, DetailFilter, filterType, SearchType, SortType } from '../../../api/search'

export interface SearchListType {
	key: SearchType
	label: string
	subject: Subjects[]
}

export const SortTypeToString = {
	[SortType.POPULAR]: '인기순',
	[SortType.LATEST]: '최신순',
	[SortType.PRICE_ASC]: '가격순',
	[SortType.TITLE]: '제목순',
}

export interface filterGroupType {
	title: string
	content: filterType[]
}

export interface filterGroupListType {
	[key: string]: filterGroupType
}

export interface SelectedFilterType {
	key: string
	value: string
}

export interface defaultParams {
	search_type?: SearchType
	q?: string
	license?: string
	filter?: string
	brands?: string
	units?: string
	data_types?: string
	sort?: SortType
	paging?: string
	parsing?: string
	subjects?: string
	curriculums?: string
	publishers?: string
	content_types?: string
	subject?: Subjects
	eras?: string
	genres?: string
	use_books?: string
}

export type SearchStoreStatus = {
	items: any[]
	query: defaultParams
	filters: DetailFilter
	selectedFilters: SelectedFilterType[]
	baseLicenses: Category[]
	search_type: SearchType
	search_keyword: string
	prevKeyword: string
	prevBaseLicense: number
	subject: Subjects
	loadingTemp: string
	totalCount: string
	filter_parsing: boolean
	emptyTooltipOpen: boolean
	sliderOpen: {
		isOpen: boolean
		value: string
	}
	count: {
		[key: string]: string
	}
	updateFilters: (filters: DetailFilter) => void
	updateSelectedFilters: (filters: SelectedFilterType[]) => void
	updateBaseLicenses: (licenses: Category[]) => void
	updateQuery: (query: defaultParams) => void
	updateSearchType: (value: string) => void
	updateSubject: (value: Subjects) => void
	updateKeyword: (value: string) => void
	updatePrevKeyword: (value: string) => void
	updatePrevBaseLicense: (value: number) => void
	updateLoadingTemp: (value: string) => void
	generateUrl: (query: defaultParams) => string
	updateTotalCount: (value: string) => void
	updateFilterParsing: (value: boolean) => void
	updateEmptyTooltipOpen: (value: boolean) => void
	openSlider: (isOpen: boolean, value: string) => void
	updateCount: (value: { [key: string]: string }) => void
}

export const useSearchStore = create<SearchStoreStatus>((set) => ({
	items: [],
	query: {},
	filters: {},
	selectedFilters: [],
	baseLicenses: [],
	search_type: SearchType.HANDOUT,
	search_keyword: '',
	prevKeyword: '',
	prevBaseLicense: undefined,
	subject: undefined,
	loadingTemp: '',
	totalCount: '0',
	filter_parsing: false,
	emptyTooltipOpen: false,
	sliderOpen: {
		isOpen: false,
		value: '',
	},
	count: {
		[Subjects.EN]: '0',
		[Subjects.KO]: '0',
		[Subjects.MA]: '0',
	},
	updateFilters: (newFilters) => {
		set({ filters: newFilters })
	},
	updateBaseLicenses: (newLicenses: Category[]) => {
		set({ baseLicenses: newLicenses })
	},
	updateSelectedFilters: (newSelectedFilters: SelectedFilterType[]) => {
		set({ selectedFilters: newSelectedFilters })
	},
	updateQuery: (query: defaultParams) => {
		set({ query: query })
	},
	updateKeyword: (value: string) => {
		set({ search_keyword: value })
	},
	updatePrevKeyword: (value: string) => {
		set({ prevKeyword: value })
	},
	updateSubject: (value: Subjects) => {
		set({ subject: value })
	},
	updatePrevBaseLicense: (value: number) => {
		set({ prevBaseLicense: value })
	},
	updateSearchType: (value: SearchType) => {
		set({ search_type: value })
	},
	updateLoadingTemp: (value: string) => {
		set({ loadingTemp: value })
	},
	generateUrl: (query: defaultParams) => {
		let path = '/search?'
		if (!query) return path

		Object.keys(query).forEach((key) => {
			query[key] && (path += key + '=' + query[key] + '&')
		})
		return path
	},
	updateTotalCount: (value: string) => {
		set({ totalCount: value })
	},
	updateFilterParsing: (value: boolean) => {
		set({ filter_parsing: value })
	},
	updateEmptyTooltipOpen: (value: boolean) => {
		set({ emptyTooltipOpen: value })
	},
	openSlider: (isOpen, value) => {
		set({ sliderOpen: { isOpen, value } })
	},
	updateCount: (value) => {
		set({ count: value })
	},
}))
