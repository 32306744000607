import { PaginationInput, PaginationResult } from '@/lib/pagination'
import { ColProps } from 'antd'
import { IQueryResponse, otherApiClient } from '.'
import { Subjects } from './product'

export interface Library {
	licenseKey: string // "3234917179056981616-handout-18349",
	id: string // "3239992962314142980",
	title: string // "[편집가능] 영어 한상호 8과 [어법][킬러문항]",
	thumbImg: string // "https://static.v2.solvook.com/handouts/3234917179056981616/%E1%84%89%E1%85%B5%E1%84%83%E1%85%B3%E1%84%8B%E1%85%B5%E1%86%BC%E1%84%80%E1%85%B3%E1%86%AF%E1%84%85%E1%85%B5%E1%84%89%E1%85%B1_%E1%84%80%E1%85%A9%E1%84%83%E1%85%B3%E1%86%BC%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%A5%28%E1%84%92%E1%85%A1%E1%86%AB%29.png",
	author: string // "시드 잉글리쉬",
	subject: string // "EN",
	sourceId: string // "3234917179056981616",
	sourceType: string // "handout",
	baseSourceTitle: string // "[YBM] 고등 영어 (한상호)",
	unit: string // "8과",
	dataType: Array<number> // [ 30 ]
	publisher: string
	membershipId: string
	studioVersion?: string
	object?: {
		isParsedByMl?: boolean
	}
	canReview: boolean
	productId: string
	baseSourceProductId: string
	baseSourceProductPublisher: string
}

export interface LibraryFilter {
	uid: number
	id: number | string // 23393, "3011242260990789123"
	value: string // '굿윌북'
	// count: number // 1
}
export interface LibraryBrandFilter {
	initial: string // 'ᄀ'
	values: Array<LibraryFilter> // [{ id: 23393; value: '굿윌북'; count: 1 }]
}
export interface LibraryBaseSourceFilter {
	source_type: string // "work"
	values: Array<LibraryFilter> // [{ "id": "3246439120259516153", "value": "백화", "count": 1 }]
}
export function isLibraryGeneralFilter(
	value: LibraryFilter | LibraryBrandFilter | LibraryBaseSourceFilter,
): value is LibraryFilter {
	return value['id'] !== undefined
}

export interface LibraryFilters {
	brands: Array<LibraryBrandFilter>
	dataTypes: Array<LibraryFilter>
	units: Array<LibraryFilter>
	publishers: Array<LibraryFilter>
	curriculums: Array<LibraryFilter>
	genres: Array<LibraryFilter>
	eras: Array<LibraryFilter>
	useBooks: Array<LibraryFilter>
	filterParsing: boolean
	baseSources: Array<LibraryBaseSourceFilter>
}
export type LibraryFilterKeys = keyof LibraryFilters
export const LibraryFiltersMap: Record<
	LibraryFilterKeys,
	{ label: string; colSpan: ColProps['span'] }
> = {
	brands: { label: '제작자', colSpan: 12 },
	dataTypes: { label: '자료유형', colSpan: 24 },
	units: { label: '단원', colSpan: 24 },
	publishers: { label: '출판사', colSpan: 24 },
	curriculums: { label: '학년', colSpan: 24 },
	genres: { label: '장르', colSpan: 12 },
	eras: { label: '시대', colSpan: 12 },
	useBooks: { label: '이용 교재', colSpan: 24 },
	filterParsing: { label: '편집 가능', colSpan: 24 },
	baseSources: { label: '연관 교재', colSpan: 24 },
}

export const LibrarySearchType = {
	수업자료: 'handout',
	교과서: 'textbook',
	참고서: 'workbook',
	문학: 'literature',
	비문학: 'non_literature',
} as const
export type LibrarySearchType = typeof LibrarySearchType[keyof typeof LibrarySearchType]
export function isLibrarySearchType(value: string | null): value is LibrarySearchType {
	return !!Object.values(LibrarySearchType).find((v) => v === value)
}
export const getLibrarySearchTypeName = (key: LibrarySearchType) =>
	Object.keys(LibrarySearchType).find((k) => LibrarySearchType[k] === key)

export interface GetLibraryFiltersRequest {
	subject: Subjects
	searchType: LibrarySearchType
}
// export interface GetLibraryFiltersResponse extends IQueryResponse {
// 	filters: LibraryFilters
// }
export const fetchLibraryFilters = async (params: GetLibraryFiltersRequest) => {
	const { data } = await otherApiClient.market<LibraryFilters>(`/libraries/filters`, {
		params: { ...params, searchType: params.searchType.replaceAll('_', '-') },
	})
	return data
}

export interface LibrarySlots {
	libraryReviewButton: { link: string; text: string }
}
export type LibraryMetadata = Record<LibrarySearchType, number>

export type GetLibraryMetadataRequest = Pick<GetLibraryFiltersRequest, 'subject'>
export interface GetLibraryMetadataResponse extends LibrarySlots {
	metadata: LibraryMetadata
}
export const fetchLibraryMetadata = async (params: GetLibraryMetadataRequest) => {
	const { data } = await otherApiClient.market<GetLibraryMetadataResponse>(
		`/libraries/metadata`,
		{ params },
	)
	return data
}

export interface GetLibrariesRequest extends PaginationInput {
	searchType: LibrarySearchType
	subject: Subjects
	parsing?: boolean
	brand?: Array<string>
	dataType?: Array<number>
	curriculum?: Array<number>
	unit?: Array<string>
	publisher?: Array<string>
	genre?: Array<string>
	era?: Array<string>
	useBook?: Array<string>
	baseSource?: Array<string>
}
export const LibraryFilterRequestMap: Record<LibraryFilterKeys, keyof GetLibrariesRequest> = {
	brands: 'brand',
	dataTypes: 'dataType',
	units: 'unit',
	publishers: 'publisher',
	curriculums: 'curriculum',
	genres: 'genre',
	eras: 'era',
	useBooks: 'useBook',
	filterParsing: 'parsing',
	baseSources: 'baseSource',
}
export interface GetLibrariesResponse extends IQueryResponse {
	data: Array<Library>
	pagination: PaginationResult
}
export const fetchLibraries = async (params: GetLibrariesRequest) => {
	const { data } = await otherApiClient.market<GetLibrariesResponse>(`/libraries`, {
		params: { ...params, searchType: params.searchType.replaceAll('_', '-') },
	})
	return data
}

export interface LibraryDetail {
	// 유효한 라이선스 정보
	license: {
		id: string // 유효한 라이선스 ID
		expiredAt: string // 만료일
		version: 'separate' | 'solvookplus' // separate: 단건결제 | solvookplus : 쏠북+ 이용하기로 담은 상품
	}

	// 연결된 상품 정보
	product: {
		productId: string // 상품 ID
		sourceId: string // 소스 ID
		sourceType: string // 소스 타입
		title: string // 타이틀
		thumbImg: string // 썸네일 이미지
		author: string // 저자
		createdAt: string // 생성일
		publisher?: string // 출판사, sourceType: handout인 수업자료에선 존재하지 않음
		publishedAt?: string // 출판일, sourceType: handout인 수업자료에선 존재하지 않음
		isPdfExist?: boolean // pdf파일 존재여부
		baseSourceTitle?: string // sourceType: handout인 수업자료의 경우 출처교재의 타이틀을 가져옴
		dataTypes?: Array<number> // sourceType: handout인 수업자료의 경우 자료유형에 해당하는 데이터
	}
	attachment?: {}
	subItems?: Array<{}>
}

export interface GetLibraryRequest {
	extended?: boolean
}
export interface GetLibraryResponse extends IQueryResponse {
	data: Array<Library>
	pagination: PaginationResult
}

export interface GetLibraryLicenseRequest {
	id: string
	isStudio?: boolean
}
export interface GetLibraryLicenseResponse {
	canOpen: boolean
	showCreditPopUp: boolean
}
export const fetchLibraryLicense = async ({ id, ...params }: GetLibraryLicenseRequest) => {
	const { data } = await otherApiClient.market<GetLibraryLicenseResponse>(
		`/libraries/${id}/check-can-open`,
		{ params },
	)
	return data
}
