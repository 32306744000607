import { Modal } from 'antd'
import { FC, PointerEventHandler, useCallback, useMemo } from 'react'
import useClassStore from 'src/store/class'
import styled from 'styled-components'
import NoPadButton from '../common/Button/NoPadButton'
import _TextField, { TextFieldProps } from '../common/TextField'
import Icon from '../icon/Icon'
import { ArrowLeft1_5, PickBadgeText } from '../icon/IconList'

export const StyledModal = styled(Modal)`
	.ant-modal-content {
		display: flex;
		min-height: 500px;
		padding: 44px 40px 40px;
		border-radius: 12px;
	}
	.ant-modal-body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.ant-modal-close svg {
		width: 24px;
		height: 24px;
	}

	@media (max-width: 1200px) {
		.ant-modal-content {
			min-height: auto;
		}
	}
	@media (max-width: 768px) {
		.ant-modal-content {
			padding: 28px 20px;
		}
	}
`

export const ModalTitle = styled.h4`
	margin: 28px 0 20px;
	color: ${(props) => props.theme.new.colors.sol_gray_800};
	text-align: center;
	font-size: 24px;
	line-height: 32px;

	&,
	strong {
		font-weight: 700;
	}
	strong {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
	}

	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.2px;
	}
`

export const NewClassActionsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;

	button {
		min-width: 120px;
	}
`
const NewClassStepperWrapper = styled.div<{ backButton: boolean }>`
	display: flex;
	padding-right: 4px;

	${(props) =>
		!props.backButton
			? `
    padding-left: 4px;

    .back-button {
        display: none;
    }`
			: ''}
`
const BackButton = styled(NoPadButton)`
	margin-left: -20px;
	border: none;
	color: ${(props) => props.theme.new.colors.sol_gray_800};
`
const NewClassStepperIndicatorWrapper = styled.div<{ activeStep: number }>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	height: 24px;
	gap: 12px;

	& > div:nth-child(${(props) => props.activeStep}) {
		background-color: ${(props) => props.theme.new.colors.sol_gray_800};
	}
`
const NewClassStepperIndicator = styled.div`
	width: 8px;
	height: 8px;

	background-color: ${(props) => props.theme.new.colors.sol_gray_200};
	border-radius: 16px;
	line-height: 8px;
`

export const NewClassStepper: FC = () => {
	const { step } = useClassStore((state) => state.adding)
	const { setAdding } = useClassStore(({ setAdding }) => ({ setAdding }))
	const handleClick = useCallback<PointerEventHandler<HTMLButtonElement>>(() => {
		setAdding({ step: step - 1 })
	}, [setAdding, step])
	const array = useMemo(() => new Array(2).fill(null), [])

	return (
		<NewClassStepperWrapper backButton={step > 1}>
			<BackButton className="back-button" type="link" onClick={handleClick}>
				<Icon icon={ArrowLeft1_5} />
			</BackButton>
			<NewClassStepperIndicatorWrapper activeStep={step}>
				{array.map((_, idx) => (
					<NewClassStepperIndicator key={idx} />
				))}
			</NewClassStepperIndicatorWrapper>
		</NewClassStepperWrapper>
	)
}

export const TextField: FC<Omit<TextFieldProps, 'onChange' | 'onClear'>> = ({
	name,
	value,
	...props
}) => {
	const { setAdding } = useClassStore(({ setAdding }) => ({ setAdding }))

	const handleChange = useCallback<TextFieldProps['onChange']>(
		(ev) => {
			setAdding({ [name]: ev.target.value })
		},
		[name, setAdding],
	)

	const handleClear = useCallback(() => {
		setAdding({ [name]: '' })
	}, [name, setAdding])

	return (
		<_TextField
			{...props}
			name={name}
			value={value}
			onChange={handleChange}
			onClear={handleClear}
		/>
	)
}

const PickChipContainer = styled.div`
	display: inline-block;
	padding: 3px 5.203px 3.906px 5.5px;
	background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
	border-radius: 16px;
	color: ${(props) => props.theme.new.colors.white};
	line-height: 9px;

	svg {
		width: 23.297px;
		height: 9.094px;
	}
`
export const PickChip = () => {
	return (
		<PickChipContainer>
			<Icon icon={PickBadgeText} />
		</PickChipContainer>
	)
}

export const ClassSectionContainer = styled.dl`
	&:not(:last-of-type) {
		margin-bottom: 60px;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
	& > dt {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		color: ${(props) => props.theme.new.colors.sol_gray_700};
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;

		.ant-btn {
			margin-left: 2px;
		}
	}
`
