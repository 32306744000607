import theme from '@/themes/uiLibraryTheme'
import * as React from 'react'
import { useMemo } from 'react'
import { styled, Typography } from '@mui/material'
import { Pagination } from '@bookips/solvook-ui-library'
import { useCategoryFilterHandler } from '@/hooks/useCategoryFilterHandler'
import { CategoryProductListView } from '@/components/Categories/Menu/CategoryProductListView'
import { CategoryFilterSectionView } from '@/components/Categories/Menu/CategoryFilterSectionView'
import { useMobileFilterSideSheet } from '@/hooks/useMobileFilterSideSheet'
import { UsePaginationNew } from '@/hooks/usePaginationNew'
import { GetCategoryListResponse } from '../../../api/category'
import { PreviewDialogProvider } from '../../Search/PreviewDialogProvider'

export interface CategoryListViewProps
	extends GetCategoryListResponse,
		Omit<UsePaginationNew, 'offset'> {
	isLoadingListItem: boolean
	categoryName: string
	limit: number
}

export const CategoryList = ({
	items = [],
	totalCount = 0,
	filterItems = [],
	sortItems = [],
	isLoadingListItem,
	categoryName,
	limit,
	currentPage,
	totalPage,
	onChangePage,
}: CategoryListViewProps) => {
	const { palette } = theme
	const {
		selectedFilterList,
		selectedSort,
		filterQueryHandler,
		isCheckFilterItem,
		isCheckFilterCount,
	} = useCategoryFilterHandler()

	const hasFilterItems = useMemo(() => {
		return filterItems.some((filter) => filter.items.length > 0)
	}, [filterItems])

	const { mobileFilterSideSheet, handleMobileSideSheet } = useMobileFilterSideSheet()
	return (
		<PreviewDialogProvider>
			<CategoryListViewContainer>
				<Typography
					component="h5"
					typography={'h5'}
					color={palette.text['title']}
					style={{ textAlign: 'left', width: '100%' }}>
					{categoryName}
				</Typography>
				<CategoryFilterSectionView
					filterItems={filterItems}
					hasFilterItems={hasFilterItems}
					handleMobileSlider={handleMobileSideSheet}
					selectedFilterList={selectedFilterList}
					filterQueryHandler={filterQueryHandler}
					totalCount={totalCount}
					sortItems={sortItems}
					selectedSort={selectedSort}
					isCheckFilterItem={isCheckFilterItem}
					isCheckFilterCount={isCheckFilterCount}
					mobileFilterSideSheet={mobileFilterSideSheet}
				/>
				<CategoryProductListView
					isLoadingListItem={isLoadingListItem}
					items={items}
					limit={limit}
				/>
				<PaginationContainer>
					<Pagination
						page={currentPage}
						onChange={(event, page) => onChangePage(page)}
						count={totalPage}
						size="medium"
					/>
				</PaginationContainer>
			</CategoryListViewContainer>
		</PreviewDialogProvider>
	)
}

const CategoryListViewContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const PaginationContainer = styled('div')`
	margin-top: 32px;
`
