import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosRequestTransformer,
	AxiosResponseTransformer,
} from 'axios'
import Cookies from 'js-cookie'

type Transformer = AxiosRequestTransformer | AxiosResponseTransformer
export const cookieOptions = {
	path: '/',
	secure: true,
	domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
	// sameSite: 'Lax',
	// expires: new Date(Date.now() + 24 * 60 * 60 * 1000 * 60), // 60일
	// httpOnly: true,
}

const getDefaultTransfrom = <T extends Transformer>(func: T[] | T | undefined): T[] =>
	func ? (Array.isArray(func) ? func : [func]) : []

export const defaultTransformRequest = getDefaultTransfrom(axios.defaults.transformRequest)
export const defaultTransformResponse = getDefaultTransfrom(axios.defaults.transformResponse)

const transformRequest: AxiosRequestTransformer[] = [
	(data: object) => {
		// return snakeize(data)
		return data
	},
	...defaultTransformRequest,
]

const solvookAPIConfig: AxiosRequestConfig = {
	baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
	responseType: 'json',
	transformRequest,
	// headers: { Authorization: Cookies.get('accessToken') },
	transformResponse: [
		...defaultTransformResponse,
		(data) => {
			// return camelize(data)
			return data
		},
	],
}
const axiosInstance = axios.create(solvookAPIConfig)

export const getAccessToken = () => {
	return Cookies.get('accessToken', { ...cookieOptions })
}
const setupInterceptors = (ax: AxiosInstance) => {
	ax.interceptors.request.use(
		(config) => {
			const accessToken = getAccessToken()
			if (accessToken) {
				config.headers.Authorization = accessToken
			}
			return config
		},
		(error) => {
			return Promise.reject(error)
		},
	)
}

setupInterceptors(axiosInstance)

export const api = axiosInstance

type AxiosVersions = 'market'
const axiosVersions: Record<AxiosVersions, AxiosRequestConfig> = {
	market: { baseURL: `${process.env.NEXT_PUBLIC_MARKET_API_ENDPOINT}` },
}
type AxiosOtherTypes = {
	[key in keyof typeof axiosVersions]: AxiosInstance
}

export const otherApiClient = Object.entries(axiosVersions).reduce<AxiosOtherTypes>(
	(acc, [k, v]) => {
		const instance = axios.create({ ...solvookAPIConfig, ...v })
		setupInterceptors(instance)

		acc[k] = instance

		return acc
	},
	{} as AxiosOtherTypes,
)

export interface PaginationQuery {
	offset?: number
	limit?: number
	cursor?: number
}

export interface IQueryResponse {
	status_code: number
	success: boolean
	message: string
	error?: string
}

export interface IMutationResponse {
	status_code: number
	success: boolean
	message: string
	error?: string
	errors?: FormError
}

export interface FormError {
	[field_key: string]: string
}
export interface IPagination {
	total: number
	offset: number
	limit: number
}
export interface IPaginationResponse {
	pagination: IPagination
}
