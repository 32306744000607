import dayjs from 'dayjs'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageView = (url) => {
	window.gtag('config', GA_TRACKING_ID, {
		page_path: url,
	})
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaEvent = ({ action, category, label, value }) => {
	if (!window.gtag) return console.log('no gtag')
	window.gtag('event', action, {
		event_category: category,
		event_label: label,
		value: value,
	})
}

export const gaAddToCart = ({
	id,
	name,
	price,
	quantity = 1,
	currency = 'KRW',
}: {
	id: string
	name: string
	price: number
	quantity?: number
	currency?: string
}) => {
	window.gtag('event', 'add_to_cart', {
		currency,
		value: price,
		items: [
			{
				item_id: id,
				item_name: name,
				index: 0,
				// item_brand: 'Google',
				// item_category: 'Apparel',
				// item_category2: 'Adult',
				// item_category3: 'Shirts',
				// item_category4: 'Crew',
				// item_category5: 'Short sleeve',
				// item_list_id: 'related_products',
				// item_list_name: 'Related Products',
				// item_variant: 'green',
				// location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
				price: price,
				quantity,
			},
		],
	})
}

export const gaViewItem = ({
	id,
	name,
	price,
	quantity = 1,
	currency = 'KRW',
}: {
	id: string
	name: string
	price: number
	quantity?: number
	currency?: string
}) => {
	if (!window.gtag) return console.log('no gtag')
	window.gtag('event', 'view_item', {
		currency,
		value: price,
		items: [
			{
				item_id: id,
				item_name: name,
				index: 0,
				// item_brand: 'Google',
				// item_category: 'Apparel',
				// item_category2: 'Adult',
				// item_category3: 'Shirts',
				// item_category4: 'Crew',
				// item_category5: 'Short sleeve',
				// item_list_id: 'related_products',
				// item_list_name: 'Related Products',
				// item_variant: 'green',
				// location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
				price: price,
				quantity,
			},
		],
	})
}

export interface IgaBeginCheckoutItem {
	item_id: number
	item_name: string
	price: number
	quantity: number
	index: number
}

export const gaBeginCheckout = ({
	items,
	currency,
	value,
}: {
	items: IgaBeginCheckoutItem[]
	currency: string
	value: number
}) => {
	window.gtag('event', 'begin_checkout', {
		currency: currency,
		value: value,
		items: items,
	})
}

export const gaPurchase = ({
	items,
	currency,
	value,
	transaction_id,
	paymethod,
}: {
	items: IgaBeginCheckoutItem[]
	currency: string
	value: number
	transaction_id: number
	paymethod: string
}) => {
	window.gtag('event', 'purchase', {
		currency: currency,
		value: value,
		transaction_id: transaction_id,
		paymethod: paymethod,
		items: items,
	})
}

export interface IgaViewItemList {
	item_id: number
	item_category: string
	price: number
}

export const gaViewItemList = ({
	item_list_id,
	item_list_name,
	items,
}: {
	items: IgaViewItemList[]
	item_list_id: string
	item_list_name: string
}) => {
	window.gtag('event', 'view_item_list', {
		item_list_id: item_list_id,
		item_list_name: item_list_name,
		items: items,
	})
}

export const gaSignupCompleted = (property: {
	registration_date: string
	type: string
	name: string
	user_id: string
}) => {
	const date = property.registration_date
	const created_at = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
	const today = dayjs().format('YYYY-MM-DD HH:mm:ss')
	const diffMinute = dayjs(today).diff(created_at, 'minute')
	if (property['type'] === 'phone') {
		window.gtag('event', 'sign_up', {
			method: 'phone',
			name: property.name,
			user_id: property.user_id,
		})
	} else if (property['type'] === 'kakao' && diffMinute < 1) {
		window.gtag('event', 'sign_up', {
			method: 'kakao',
			name: property.name,
			user_id: property.user_id,
		})
	}
}
