import styled, { useTheme } from 'styled-components'
import { ReactNode } from 'react'
import Link from 'next/link'
import { AddToCartComplete } from '@/modules/products/styles/ProductDetail.style'
import Icon from '@/components/icon/Icon'
import { ArrowRight, Sizes } from '@/components/icon/IconList'

const TextWithIconSpan = styled.span`
	display: flex;
	align-items: center;
	gap: 4px;
`
export const AddToCartToast = ({
	type,
	content,
	link,
	addToCartCompleteModal,
}: {
	type: string
	content: ReactNode
	link: string
	addToCartCompleteModal: boolean
}) => {
	const theme = useTheme()
	return (
		<Link href={link}>
			<AddToCartComplete
				className={`alert-${type} ${addToCartCompleteModal ? 'fadeIn' : 'fadeOut'}`}>
				<TextWithIconSpan>{content}</TextWithIconSpan>
				<Icon icon={ArrowRight} size={Sizes.small} color={theme.new.colors.white} />
			</AddToCartComplete>
		</Link>
	)
}
