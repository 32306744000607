import React from 'react'
import { useTheme } from 'styled-components'

import Modal from '@/components/modal/Modal'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import Sheet from 'react-modal-sheet'
import { SelectingBooksModal, SelectingBooksModalProps } from '../UserSourceStep'

interface ISelectBooksModalProps extends Omit<SelectingBooksModalProps, 'onClose'> {
	closeSelectBooksModal: SelectingBooksModalProps['onClose']
}

export const UserSourceStepModal: React.FC<ISelectBooksModalProps> = ({
	closeSelectBooksModal,
	...props
}) => {
	const { width } = useWindowDimensions()
	const isMobile = width < 769
	const theme = useTheme()

	return (
		<>
			{!isMobile ? (
				<Modal
					disableOutsideClick={true}
					onClose={closeSelectBooksModal}
					open={props.isSelectBooksModalOpen}
					name="selectBooksModal">
					<SelectingBooksModal {...props} onClose={closeSelectBooksModal} />
				</Modal>
			) : (
				<Sheet isOpen={props.isSelectBooksModalOpen} onClose={closeSelectBooksModal}>
					<Sheet.Container>
						<Sheet.Content>
							<SelectingBooksModal {...props} onClose={closeSelectBooksModal} />
						</Sheet.Content>
					</Sheet.Container>
					<Sheet.Backdrop />
				</Sheet>
			)}
		</>
	)
}
