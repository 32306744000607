import { styled } from '@mui/material'
import { RankingItemDTO } from '../../../api/main'
import { CategoryListPageSkeletonItem } from '@/components/Categories/Menu/CategoryListPageSkeleton'
import { BookCard } from '@/components/Landing/BookCard'
import * as React from 'react'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { EmptyWrapper } from '@/components/Coupons/Mypage/EmptyWrapper'

export const CategoryProductListView = ({
	isLoadingListItem,
	items,
	limit,
}: {
	isLoadingListItem: boolean
	items: Array<RankingItemDTO>
	limit: number
}) => {
	const { isLoggedIn } = useAuthStore()
	return (
		<>
			<GridContainer>
				{isLoadingListItem
					? Array.from({ length: limit }).map((item, idx) => {
							return <CategoryListPageSkeletonItem key={idx} />
					  })
					: items.map((product, idx) => {
							return (
								<BookCard
									book={product}
									index={idx}
									key={idx}
									positionType={'curation'}
									mixpanelPosition={'카테고리'}
									onClick={() => {
										//mx-48
										mixpanelEvent('Category Product Clicked', {
											subject: product.subject,
											productId: product.id,
											productTitle: product.title,
											productType: 'handout',
											isLoggedIn,
											price: product.price,
											authorName: product.author,
											'Coupon Badge': product?.isCouponAvailable,
											'Edit Badge': product?.isEditable,
											'Review Badge': product?.rating >= 3,
										})
									}}
								/>
							)
					  })}
			</GridContainer>
			{!isLoadingListItem && items.length === 0 && (
				<EmptyWrapper description={'카테고리에 해당하는 상품이 없습니다.'} />
			)}
		</>
	)
}
const GridContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 20px;
	width: 100%;
	flex-wrap: wrap;
	@media (max-width: 1080px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (max-width: 920px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 768px) {
		gap: 12px;
	}

	.book-info-wrapper {
		min-width: 180px;
		width: 100%;
		flex: 1 1 0;
		flex-direction: column;
		gap: 8px;

		img {
			width: 92px;
		}

		.thumb-wrapper {
			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			width: 100%;
			padding: 8px;
			height: 224px;
			padding-bottom: 40px;
		}

		> div {
			position: relative;
		}

		.preview-btn {
			bottom: 8px;
			left: 8px;
			right: 8px;
			width: auto;
			text-align: center;
		}
	}

	@media (max-width: 768px) {
		.book-info-wrapper {
			//width:100% ;
			min-width: 173px;

			.thumb-wrapper {
				height: 150px;
				padding-bottom: 8px;

				img {
					width: 68px;
				}
			}
		}

		.preview-btn {
			display: none;
		}
	}
`
