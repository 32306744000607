// https://www.npmjs.com/package/form-data
import FormData from 'form-data'

const uploadFile = (file: File) => {
	console.log('uploadFile: ', file)
}

const createFormData = () => {
	return new FormData()
}

export { uploadFile, createFormData }
