import { SyntheticEvent } from 'react'

import { Props as IconProps } from './IconList'
import styled from 'styled-components'

export interface Props extends IconProps {
	icon: React.FC<IconProps>
	onClick?: (e: SyntheticEvent) => void
	className?: string
}

const Icon: React.FC<Props> = ({ icon: IconComponent, onClick, className, ...props }) => {
	return (
		<IconWrapper isButton={!!onClick} onClick={onClick} className={className}>
			<IconComponent {...props} />
		</IconWrapper>
	)
}

export default Icon

const IconWrapper = styled.i<{ isButton: boolean }>`
	display: inline-block;
	${(props) => props.isButton && 'cursor: pointer;'}

	svg {
		display: block;
	}
`
