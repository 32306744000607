import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface SideMenuState {
	isOpenSideMenu: boolean
	isOpenMobileSideMenu: boolean
	currentDepth: string
	twoDepthId: string
	threeDepthId: string
	fourDepthId: string
}

export interface SideMenuActions {
	closeSideMenu: () => void
	openSideMenu: () => void
	openMobileSideMenu: () => void
	setCurrentDepth: (depthId: string) => void
	setState: <T extends keyof SideMenuState>(key: T, param: SideMenuState[T]) => void
	setInitDepth: () => void
}

export const useSideCategoryMenuStore = create<SideMenuState & SideMenuActions>()(
	devtools((set, get) => ({
		isOpenSideMenu: false,
		isOpenMobileSideMenu: false,
		currentDepth: '',
		twoDepthId: '',
		threeDepthId: '',
		fourDepthId: '',
		closeSideMenu: () =>
			set({
				isOpenSideMenu: false,
				isOpenMobileSideMenu: false,
				currentDepth: '',
				twoDepthId: '',
				threeDepthId: '',
				fourDepthId: '',
			}),
		openSideMenu: () => set({ isOpenSideMenu: true }),
		openMobileSideMenu: () => set({ isOpenMobileSideMenu: true }),
		setCurrentDepth: (depthId) => set({ currentDepth: depthId }),
		setInitDepth: () => set({ threeDepthId: '' }),

		setState: (key, param) => {
			set((state) => ({ ...state, [key]: param }))
		},
	})),
)
