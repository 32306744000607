import { getTheme } from '@bookips/solvook-ui-library'
import { createTheme } from '@mui/material'

// Create a theme instance.
const defaultTheme = createTheme()
const theme = getTheme({
	breakpoints: { values: { ...defaultTheme.breakpoints.values, sm: 768 } },

	components: {
		MuiCssBaseline: {
			styleOverrides: (th) => ({
				svg: { transform: 'translateZ(0)' },
			}),
		},
	},
})

export default theme
