import { IRestfulApiService } from '@/lib/apiService'
import { IMutationCommand } from '@bookips/solvook-api-shared'

interface LogoutResponseDTO {
	status: boolean
}

/**
 * 로그아웃 명령
 * @param
 * @returns Promise<LogoutResponseDTO>
 */
export class LogoutCommand implements IMutationCommand<any, any, any> {
	private apiService: IRestfulApiService
	constructor(apiService: IRestfulApiService) {
		this.apiService = apiService
	}

	execute(): Promise<LogoutResponseDTO> {
		const url = `/sessions`
		return this.apiService
			.fetch<LogoutResponseDTO>(url, { method: 'DELETE' })
			.then((res) => res.data)
	}
}
