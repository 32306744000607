import BigNumber from 'bignumber.js'

export const calculateSaleRate = (original: string, current: string) => {
	if (!original || !current) return '0%'
	const originalPrice = new BigNumber(original.replace(/,/g, ''))
	const currentPrice = new BigNumber(current.replace(/,/g, ''))
	if (originalPrice.isEqualTo(currentPrice)) return '0%'
	const rate = originalPrice.minus(currentPrice).dividedBy(originalPrice).multipliedBy(100)
	return `${rate.toFixed(0)}%`
}
