import Icon from '@/components/icon/Icon'
import { Sizes, Success24Fill, Warning24Fill } from '@/components/icon/IconList'
import { Modal, ModalProps } from 'antd'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const StyledBooksOrderModal = styled(Modal)`
	.ant-modal-content {
		display: flex;
		flex-direction: column;
		gap: 28px;
		padding: 28px;
		border-radius: 4px;
	}
	.stack {
		display: flex;
		gap: 8px;

		font-size: 16px;
		letter-spacing: -0.16px;
		line-height: 1.5;

		&.success svg {
			color: ${(props) => props.theme.new.colors.sol_mint_300};
		}
		&.caution svg {
			color: ${(props) => props.theme.new.colors.sol_red_400};
		}

		.stack {
			flex-direction: column;
		}
		h4 {
			margin-bottom: 0;
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.2px;
		}
		p {
			color: ${(props) => props.theme.new.colors.sol_gray_700};
		}

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.ant-modal-footer {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		margin-top: 0;

		@media (max-width: 768px) {
			flex-direction: column;

			a,
			.ant-btn {
				width: 100%;
			}
		}
	}
`

const BooksOrderModal: FC<
	Pick<ModalProps, 'children' | 'open' | 'footer' | 'title'> & {
		severity?: 'success' | 'caution'
		content?: ReactNode
	} & {
		onClose?: () => void
	}
> = ({ children, title, severity = 'success', content, ...props }) => {
	return (
		<StyledBooksOrderModal
			{...props}
			closable={false}
			maskClosable={true}
			onCancel={props.onClose}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}>
			{children || (
				<div className={`stack ${severity}`}>
					{severity === 'success' && <Icon icon={Success24Fill} size={Sizes.large} />}
					{severity === 'caution' && <Icon icon={Warning24Fill} size={Sizes.large} />}

					<div className="stack">
						<h4>{title}</h4>

						{!!content && <p>{content}</p>}
					</div>
				</div>
			)}
		</StyledBooksOrderModal>
	)
}

export default BooksOrderModal
