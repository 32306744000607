import React, { useCallback, useEffect, useState } from 'react'
import { DepthItem } from '../Category/hooks/useCategoryPanelData'
import useCloseHandler from '../Category/hooks/useCloseHandler'
import { useFunnel } from '../Funnel'
import AnchorDrawer from '../modal/AnchorDrawer'
import { SideSheetAdListStep, SideSheetDefaultStep, SideSheetMenuListStep } from './SideSheetSteps'
import { useSideCategoryMenuStore } from './store/useCategoryMenuStore'

export interface GNBSideSheetProps {
	onLogout: () => void
}

export const SideSheetSteps = {
	default: 'default',
	menuList: 'menuList',
	adList: 'adList',
} as const

export type SideSheetStep = typeof SideSheetSteps[keyof typeof SideSheetSteps]

export const GNBSideSheet: React.FC<GNBSideSheetProps> = ({ onLogout }) => {
	useCloseHandler()

	const { isOpenMobileSideMenu, openMobileSideMenu, closeSideMenu, setState } =
		useSideCategoryMenuStore()
	const [Funnel, setStep] = useFunnel(
		[SideSheetSteps.default, SideSheetSteps.menuList, SideSheetSteps.adList],
		SideSheetSteps.default,
	)
	const [currentMenu, setCurrentMenu] = useState<DepthItem>(null)

	const goToNextStep = (step: SideSheetStep) => {
		setStep(step)
	}
	const goToPrevStep = useCallback(() => {
		setStep(SideSheetSteps.default)
		setState('twoDepthId', '')
		setState('threeDepthId', '')
	}, [setState])

	useEffect(() => {
		if (!isOpenMobileSideMenu) setStep(SideSheetSteps.default)
	}, [isOpenMobileSideMenu])

	return (
		<AnchorDrawer anchor="right" open={isOpenMobileSideMenu} onClose={closeSideMenu}>
			{isOpenMobileSideMenu && (
				<Funnel>
					<Funnel.Step name={SideSheetSteps.default}>
						<SideSheetDefaultStep
							goToNextStep={goToNextStep}
							setCurrentMenu={setCurrentMenu}
							onClose={closeSideMenu}
							// onLogout={onLogout}
						/>
					</Funnel.Step>
					<Funnel.Step name={SideSheetSteps.menuList}>
						<SideSheetMenuListStep
							menu={currentMenu}
							goToPrevStep={goToPrevStep}
							onClose={() => {
								setStep(SideSheetSteps.default)
								closeSideMenu()
							}}
						/>
					</Funnel.Step>
					<Funnel.Step name={SideSheetSteps.adList}>
						<SideSheetAdListStep goToPrevStep={goToPrevStep} onClose={closeSideMenu} />
					</Funnel.Step>
				</Funnel>
			)}
		</AnchorDrawer>
	)
}
