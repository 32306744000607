import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import {
	Snackbar as MuiSnackbar,
	SnackbarProps,
	styled as muiStyled,
	Typography,
	useTheme,
} from '@mui/material'
import Icon from '@/components/icon/Icon'
import { Sizes, Success24Fill, Warning24Fill } from '@/components/icon/IconList'

export const defaultSnackbarProps: SnackbarProps = {
	autoHideDuration: 5000,
	anchorOrigin: { vertical: 'top', horizontal: 'center' },
	style: {
		top: 88,
	},
}

export const Snackbar = muiStyled(MuiSnackbar)(({ theme }) => {
	return {
		'.MuiSnackbarContent-root': {
			minWidth: 'unset',
			padding: '12px 16px',
			borderRadius: 4,
			backgroundColor: theme.palette.grey[700],
			'.MuiSnackbarContent-message': {
				padding: 0,
			},
		},
	}
})

export interface IconWithSnackbarMessageProps {
	text: ReactNode
	icon?: 'success' | 'warning'
}

export const IconWithSnackbarMessage = ({ text, icon }: IconWithSnackbarMessageProps) => {
	const theme = useTheme()
	return (
		<SnackbarMessage>
			{icon === 'success' && (
				<Typography
					color={(theme) => theme.palette.success.main}
					sx={{ display: 'flex', justifyContent: 'center' }}>
					<Icon icon={Success24Fill} size={Sizes.medium} />
				</Typography>
			)}
			{icon === 'warning' && <Icon icon={Warning24Fill} size={Sizes.medium} />}
			<Typography typography={'semibold16'} color={'#FFF'}>
				{text}
			</Typography>
		</SnackbarMessage>
	)
}
const SnackbarMessage = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
`

// variants 예시
// const snackbarVariants = {
// 	success: { icon: 'success', text: '쿠폰이 발급됐어요.' },
// 	warning: { icon: 'warning', text: '쿠폰 정보가 존재하지 않아요.' },
// };
export type SnackbarVariantType = string
const useSnackbar = (variants?: { [key in SnackbarVariantType]: IconWithSnackbarMessageProps }) => {
	const [isShowSnackbar, setIsShowSnackbar] = useState<boolean>(false)
	const [snackbarMessage, setSnackbarMessage] = useState<IconWithSnackbarMessageProps>({
		icon: null,
		text: '',
	})

	const showSnackbar = (variant?: SnackbarVariantType) => {
		if (variant) {
			setSnackbarMessage(variants[variant])
		}
		setIsShowSnackbar(true)
	}

	const hideSnackbar = () => {
		setIsShowSnackbar(false)
	}

	return {
		isShowSnackbar,
		snackbarMessage,
		showSnackbar,
		hideSnackbar,
	}
}

export default useSnackbar
