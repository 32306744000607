import { Request, Response } from 'express'
import axios from 'axios'
import { AuthInfo } from '../../auth/AuthStore'

export interface WithAuthRequest extends Request {
	auth: AuthInfo
}

export interface SessionMiddlewareOptions {
	sessionCookieKey: string
	unauthorizedForceRedirect?: boolean
	redirectUrl?: string
}

export const checkSessionMiddleware = (options: SessionMiddlewareOptions) => {
	return async (req: WithAuthRequest, res: Response, next) => {
		const sessionCookieKey = options.sessionCookieKey
		const sessionToken = req.signedCookies[sessionCookieKey]

		if (sessionToken) {
			if (!req.auth?.initialized) {
				try {
					// const instance = axios.create()

					// instance.defaults.headers.common['Authorization'] = sessionToken
					// @ts-ignore
					const { user } = await axios
						.post(
							`${process.env.NEXT_PUBLIC_API_ENDPOINT}/accounts/me`,
							{},
							{
								headers: { Authorization: sessionToken },
							},
						)
						// .then((res) => res.json())
						.then((res) => {
							return res.data
						})

					if (user) {
						req.auth = {
							initialized: true,
							verifyMethod: 'session',
							userType: 'user',
							// @ts-ignore
							user,
						}
					} else {
						req.auth = {
							initialized: true,
							verifyMethod: 'session',
							userType: 'anonymous',
						}
					}
				} catch (err) {
					console.log(err)
					res.clearCookie(sessionCookieKey, {
						domain: process.env.SESSION_DOMAIN_NAME,
					})

					if (options.redirectUrl) {
						res.redirect(options.redirectUrl)
					} else {
						res.redirect('/login')
					}
				}
			}
		} else {
			req.auth = {
				initialized: true,
				verifyMethod: 'session',
				userType: 'anonymous',
			}
		}

		// if (options.unauthorizedForceRedirect && req.auth?.userType === 'anonymous') {
		// 	if (options.redirectUrl) {
		// 		res.redirect(options.redirectUrl)
		// 	} else {
		// 		res.redirect('/login')
		// 	}
		// } else {
		// 	next()
		// }

		next()
	}
}
