import * as React from 'react'
import { createContext, useContext } from 'react'
import { UrlObject } from 'url'
import NextLink from 'next/link'

type Url = string | UrlObject

export interface LinkProps {
	href: Url
	as?: Url
	replace?: boolean
	scroll?: boolean
	shallow?: boolean
	passHref?: boolean
	prefetch?: boolean
}

export type LinkType = typeof NextLink

export interface LinkStoreType {
	Link: LinkType
	// Link: React.ElementType<LinkProps & { childrend}>
}

const LinkStore = createContext<LinkStoreType | null>(null)

export const LinkProvider: React.FC<{
	children: React.ReactNode
	value: LinkType
}> = ({ children, value }) => {
	const store = {
		Link: value,
	}
	// return <></>
	return <LinkStore.Provider value={store}>{children}</LinkStore.Provider>
}

export const useLinkStore = () => {
	const store = useContext(LinkStore)
	if (!store) {
		// this is especially useful in TypeScript so you don't need to be checking for null all the time
		throw new Error('useStore must be used within a StoreProvider.')
	}

	return store
}
