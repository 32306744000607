import React from 'react'
import styled from 'styled-components'
import { MobileFilterListItem } from '@/components/Search/MobileFilterListItem'
import { SelectedFilterType } from '@/modules/search/store/search.store'
import {
	BookListDetailFiltersResponse,
	FilterTypeToQueryKey,
	findLabel,
} from '../../../api/booksProducts'

export interface BookListMobileFilterListProps {
	handleFilter: (filterType: string, id: number, value: string) => void
	filters: BookListDetailFiltersResponse
	selectedFilters: SelectedFilterType[]
	openSlider: (isOpen: boolean, value: string) => void
	sliderOpen: { isOpen: boolean; value: string }
}

export const BookListMobileFilterList: React.FC<BookListMobileFilterListProps> = ({
	handleFilter,
	filters,
	selectedFilters,
	openSlider,
	sliderOpen,
}) => {
	return (
		<BooksMobileFilterListWrapper>
			<ul>
				{Object.keys(filters).map((key, idx) => {
					const newFilters = filters[key].map((item) => {
						return {
							id: item,
							value: findLabel(FilterTypeToQueryKey[key], item),
						}
					})
					if (filters[key].length > 0)
						return (
							<li key={idx}>
								<MobileFilterListItem
									title={FilterTypeToQueryKey[key]}
									filterItem={newFilters}
									handleFilter={handleFilter}
									selectedFilters={selectedFilters}
									openSlider={openSlider}
									sliderOpen={sliderOpen}
								/>
							</li>
						)
				})}
			</ul>
		</BooksMobileFilterListWrapper>
	)
}

export const BooksMobileFilterListWrapper = styled.div`
  p {
    margin: 0;
  }

  .filter-title-wrapper {
    cursor: pointer;
    height: 45px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100}
  }

  p {
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.new.colors.sol_gray_600};
  }

  .ant-btn {
    border: none;
  }
}

.filter-list-wrapper {
}

.title-expanding {
  background-color: ${(props) => props.theme.new.colors.sol_gray_0};
  border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_0};

  p {
    color: ${(props) => props.theme.new.colors.sol_gray_900};
  }
}
`
