import Icon from '@/components/icon/Icon'
import { Close, Sizes } from '@/components/icon/IconList'
import Router from 'next/router'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled, { css, useTheme } from 'styled-components'
import { create } from 'zustand'
import { CSSProps, lockScroll, unlockScroll, useDelayedState, useMounted } from '../../utils/misc'

/** Store */
type OverlayOption = {
	name: string
	onClose: () => void
	routeChange?: boolean
}

type OverlayPopOption = {
	afterClose?: () => void
}

export type OverlayState = {
	isBackKeyEnabled: boolean
	isLocked: boolean
	stack: OverlayOption[]
	setBackKeyEnabled: (isEnabled: boolean) => void
	open: (options: OverlayOption) => void
	close: (options?: OverlayPopOption) => void
	pop: (options?: OverlayPopOption) => void
}

const _handleOpitons = (options?: OverlayPopOption) => {
	if (!options) {
		return
	}

	if (options.afterClose) {
		setTimeout(options.afterClose, 400)
	}
}

export const useOverlayStore = create<OverlayState>((set, get) => ({
	isBackKeyEnabled: false,
	stack: [],
	isLocked: false,
	setBackKeyEnabled: (isBackKeyEnabled) => {
		set({ isBackKeyEnabled: isBackKeyEnabled })
	},
	open: (options) => {
		const { isBackKeyEnabled, isLocked } = get()

		if (!isLocked) {
			// lockScroll()
			set({ isLocked: true })
		}

		if (isBackKeyEnabled && options.routeChange) {
			const url = new URL(location.href)

			if (Router.router) {
				Router.router.isFirstPopStateEvent = true
			}
			url.hash = options.name
			history.pushState(history.state, '', url)
		}

		set((state) => ({
			stack: state.stack.concat(options),
		}))
	},
	close: (options) => {
		const { stack, isBackKeyEnabled, pop } = get()

		if (stack.length === 0) {
			return
		}

		const last = stack[stack.length - 1]
		if (isBackKeyEnabled && last.routeChange) {
			history.back()
			_handleOpitons(options)
			return
		}

		pop(options)
	},
	pop: (options) => {
		const { stack } = get()
		if (stack.length === 0) {
			return
		}

		const popped = stack[stack.length - 1]
		popped.onClose()

		if (stack.length === 1) {
			unlockScroll()
			set({ isLocked: false })
		}

		_handleOpitons(options)

		set((state) => ({ stack: state.stack.slice(0, -1) }))
	},
}))

export const useCloseOverlay = () => useOverlayStore((state) => state.close)

export interface OverlayProps {
	open: boolean
	name?: string
	depth?: number
	blocking?: boolean
	disableOutsideClick?: boolean
	onClose: OverlayState['close']
}

const Overlay = ({
	className,
	open,
	name,
	depth = 1,
	blocking = true,
	disableOutsideClick = false,
	onClose,
	children,
}: PropsWithChildren<OverlayProps & CSSProps>) => {
	const isMounted = useMounted()

	const [delayOpen, setDelayOpenImmediate, setDelayOpen] = useDelayedState(open)

	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (open) {
			setDelayOpen(true)
			lockScroll()
		} else {
			setDelayOpenImmediate(false)
			unlockScroll()
		}
		return () => {
			unlockScroll()
		}
	}, [open])

	return isMounted
		? createPortal(
				<StyledOverlay
					id={name}
					open={open}
					delayOpen={delayOpen}
					depth={depth}
					className={className}
					ref={ref}>
					{blocking && (
						<OverlayBg
							isOpen={open}
							onClick={() => !disableOutsideClick && onClose()}
						/>
					)}
					{children}
				</StyledOverlay>,
				document.getElementById('overlay')!,
		  )
		: null
}

export default Overlay

const StyledOverlay = styled.div<{ open: boolean; delayOpen: boolean; depth: number }>`
	position: fixed;
	top: 0;
	left: 0;
	max-width: inherit;
	width: 100%;
	height: 100%;
	z-index: ${(props) => 1000 + props.depth};
	${({ open }) =>
		open
			? css`
					visibility: visible;
			  `
			: css`
					visibility: hidden;
					transition: visibility 0s 0.4s;
			  `};
	${({ delayOpen }) =>
		!delayOpen &&
		css`
			pointer-events: none;
		`}
`
const OverlayBg = styled.div<{ isOpen: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s;
	${({ isOpen }) =>
		isOpen
			? css`
					opacity: 1;
			  `
			: css`
					opacity: 0;
					//background-color: red;
			  `};
`

export const OverlayClose = ({
	onClose,
	color,
	size,
	noFixed = false,
}: Pick<OverlayProps, 'onClose'> & {
	color?: string
	size?: Sizes
	noFixed?: boolean
}) => {
	const theme = useTheme()

	return (
		<OverlayCloseBtn
			// css={!noFixed && overlayCloseCSS}
			className="icon-btn"
			aria-label="닫기"
			onClick={() => onClose()}>
			<Icon
				icon={Close}
				color={color ?? theme.new.colors.black}
				size={size ?? Sizes.medium}
			/>
		</OverlayCloseBtn>
	)
}
const OverlayCloseBtn = styled.button`
	all: unset;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 9;
	cursor: pointer;
`
