import useRedirect, { REDIRECT_QUERY } from '@/hooks/useRedirect'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { LOGIN_PAGE, REGISTRATION_PAGE } from '@/settings/constant'
import { Button, ConfirmDialog } from '@bookips/solvook-ui-library'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import styled from 'styled-components'
import LoginDialog from '../../store/loginDialog'

type CheckLoginDialogProps = {
	open: boolean
	onClose: () => void
	onClickLogin?: (routePath: string) => void
	onClickSignUp?: (routePath: string) => void
}

const CheckLoginDialog = ({
	open,
	onClose,
	onClickLogin,
	onClickSignUp,
}: CheckLoginDialogProps) => {
	const router = useRouter()
	const { loginPath, redirectToLogin } = useRedirect()
	const { closeLoginDialog } = LoginDialog()
	const currentPathname = router.asPath

	const redirectLoginPage = useCallback(() => {
		// mx-9
		mixpanelEvent('Popup Button Clicked', {
			'Popup Type': 'login',
			'Popup Title': '로그인이 필요해요',
			'Button Name': '로그인',
			'Button URL': LOGIN_PAGE,
		})

		onClickLogin?.(loginPath)
		closeLoginDialog()
		redirectToLogin()
	}, [router, loginPath])

	const redirectSignUpPage = useCallback(() => {
		// mx-9
		mixpanelEvent('Popup Button Clicked', {
			'Popup Type': 'signup',
			'Popup Title': '로그인이 필요해요',
			'Button Name': '회원가입',
			'Button URL': REGISTRATION_PAGE,
		})
		const registrationPath = `${REGISTRATION_PAGE}?${REDIRECT_QUERY}=${currentPathname}`
		onClickSignUp?.(registrationPath)
		closeLoginDialog()
		router.push(registrationPath)
	}, [router, currentPathname])

	return (
		<ConfirmDialog
			open={open}
			onClose={onClose}
			title="로그인이 필요해요."
			content={<p>로그인 혹은 회원가입을 진행해주세요.</p>}
			footer={
				<ButtonBox>
					<Button variant="soft" label="로그인" onClick={redirectLoginPage}></Button>
					<Button variant="solid" label="회원가입" onClick={redirectSignUpPage}></Button>
				</ButtonBox>
			}
		/>
	)
}

const ButtonBox = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: end;
	margin-top: 28px;

	> button {
		font-size: 16px;
		padding: 12px 20px;
		line-height: 1.5;
	}
`

export default CheckLoginDialog
