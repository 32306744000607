import { IQueryResponse } from '@bookips/solvook-api-shared'
import { api } from '.'

export interface LabDTO {
	id: number
	uid?: string

	author_id: number
	lab_name: string

	content_intro: string
	content: string
	major_types: string[]
	keywords: string[]
	course_types: string[]

	links: {
		link: string
		title: string
		type: string
	}[]

	custom_meta: {
		[key: string]: string
	}

	data_meta: {
		[key: string]: string
	}

	images: {
		[key: string]: string
	}

	options?: {
		[key: string]: any
	}

	version?: string
	view_cnt?: number

	created_at?: Date
	updated_at?: Date

	author?: string // 저자명 - users에서 가져옴

	flags?: {
		[key: string]: boolean
	}
}

interface IGetLabByAuthorIdRes extends IQueryResponse {
	lab: LabDTO
}

interface IGetLabByAuthorId {
	authorId: number
}
export const getLabByAuthorId = async (authorId: number) => {
	const { data } = await api.get<IGetLabByAuthorIdRes>(`/labs/author/${authorId}`)
	return data
}
