import React from 'react'
import styled from 'styled-components'

export interface ActiveTabProps {
	tabList: IActiveTab[]
	activeTab: string
	setActiveTab: (key: string) => void
}

export interface IActiveTab {
	key: string
	label: string | React.ReactNode
	disabled?: boolean
}

export const ActiveTab: React.FC<ActiveTabProps> = ({ tabList, activeTab, setActiveTab }) => {
	return (
		<ActiveTabWrapper className={'active-tab-wrapper'}>
			{tabList.map((tab) => {
				return (
					<li
						onClick={() => setActiveTab(tab.key)}
						key={`active-tab-${tab.key}`}
						className={`${activeTab === tab.key ? 'active' : ''} ${
							tab.disabled ? 'disabled' : ''
						}`}>
						{tab.label}
					</li>
				)
			})}
		</ActiveTabWrapper>
	)
}

const ActiveTabWrapper = styled.ul`
	display: flex;
	gap: 24px;
	border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	background-color: white;
	margin: 0 -100%;
	padding: 0 100%;

	li {
		padding: 13px 0;
		border-bottom: none;
		border-radius: 4px 4px 0 0;
		cursor: pointer;

		div {
			color: ${(props) => props.theme.new.colors.sol_gray_400};
			line-height: 38px;
		}

		&.active {
			border-bottom: 2px solid ${(props) => props.theme.new.colors.sol_gray_800};
			padding: 13px 0 11px;
			font-weight: 600;

			div {
				transition: color 0.2s;
				color: ${(props) => props.theme.new.colors.sol_gray_800};
			}
		}

		&.disabled {
			pointer-events: none;
			cursor: not-allowed;
		}
	}
`
