import ExpertGNB from '@/components/common/GNB/ExpertGNB'
import GoodnotesGNB from '@/components/common/GNB/GoodnotesGNB'
import MarketGNB from '@/components/common/GNB/MarketGNB'
import { FooterView } from '@/containers/Layout/Footer/FooterView'
import { mobileTabBarHeight } from '@/containers/Layout/Header/MobileTabBar'
import { libraryLnbDimensions } from '@/modules/mypages/libraries/components/LibraryLNB'
import {
	ADMIN_PAGE_LOGIN,
	EBOOKS_UNIVERSITY_LANDING,
	MYPAGE_STORES_PRODUCTS_NEW_INDEX,
	PUBLISHER_LOGIN,
	VIEWER_PAGE,
	VIEWER_PRINTOUT_PAGE,
} from '@/settings/constant'
import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useMemo } from 'react'
import { isInWebview, useMounted } from 'src/utils/misc'
import styled from 'styled-components'
import SkeletonGNB from '@/components/common/GNB/SkeletonGNB'

const { Content } = Layout

const WebViewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: white;

	.ant-layout-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	@media (min-width: 769px) and (max-width: 1200px) {
		padding-bottom: ${mobileTabBarHeight};

		&:has(#library-lnb) > footer {
			padding-left: ${libraryLnbDimensions.widthOnTablet + 40}px;
		}
	}
`
export const LayoutContainer: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
	const router = useRouter()
	const { pathname, query } = router

	const { isUseFooter } = useMemo(
		() => ({
			isUseFooter: !isInWebview(),
		}),
		[],
	)

	const withoutLayoutPages: string[] = [
		VIEWER_PRINTOUT_PAGE,
		VIEWER_PAGE,
		MYPAGE_STORES_PRODUCTS_NEW_INDEX,
		ADMIN_PAGE_LOGIN,
		PUBLISHER_LOGIN,
	]

	const isViewerPages = /\/viewer\//.test(pathname)
	const productEditPage = /\/products\/(.*)\/edit/.test(pathname)
	const goodNotesPage = pathname.includes(EBOOKS_UNIVERSITY_LANDING)
	const expertPage = pathname.includes('/expert')
	const mounted = useMounted()
	const renderGNB = useMemo(() => {
		if (!mounted) return <SkeletonGNB />
		if (goodNotesPage) return <GoodnotesGNB />
		if (expertPage) return <ExpertGNB />
		return <MarketGNB />
	}, [mounted, pathname])

	if (withoutLayoutPages.includes(pathname) || isViewerPages || productEditPage) {
		return <Content>{children}</Content>
	}
	return (
		<WebViewWrapper>
			{isUseFooter && renderGNB}
			<Content>{children}</Content>
			{isUseFooter && <FooterView />}
		</WebViewWrapper>
	)
})
