import styled, { useTheme } from 'styled-components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Image from 'next/image'
import { themeGet } from '@styled-system/theme-get'
import { includesPath } from '@/lib/utils/url'
import {
	CATEGORY_PAGE,
	CATEGORY_TEXTBOOK_PAGE,
	HOME_PAGE,
	LIBRARIES_INDEX_PAGE,
	LOGIN_PAGE,
	MOBILE_CATEGORY_PAGE,
	MYPAGE_PAGE,
	SHOP_CART_PAGE,
} from '@/settings/constant'
import { ActiveLink } from '@/components/ActiveLink'
import { useRouter } from 'next/router'
import Icon from '@/components/icon/Icon'
import { Cart, Library, Sizes } from '@/components/icon/IconList'

export interface MobileTabBarProps {
	isLogin: boolean
}

export const MobileOnlyHeaderHeight = '64px'
export const mobileTabBarHeight = '0'

const MobileTabBarWrapper = styled.div`
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: ${mobileTabBarHeight};
	background-color: ${themeGet('background.0', '#FDFDFF')};
	border-top: solid 1px #ebebeb;
	box-shadow: 0 7px 70px 0 rgba(0, 0, 0, 0.1);
	z-index: 999;

	@media (max-width: 1200px) {
		display: block;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		li {
			flex: 1;
			height: 100%;
			text-align: center;

			.active-link {
				height: 100%;
				text-align: center;
				opacity: 0.35;

				&.active {
					opacity: 1;
				}

				> * {
					display: block;
				}
				img,
				svg {
					margin: 8px auto 4px;
				}

				span {
					display: block;
					width: 100%;
					font-size: 12px;
					font-weight: bold;
					color: ${themeGet('text.0', '#1B1B29')};
					text-align: center;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 768px) {
				}
			}
		}
	}
`

export const MobileTabBar: React.FC<MobileTabBarProps> = observer(({ isLogin }) => {
	// const {status, actions} = useViewModel({}, MobileTabBarModel)
	const router = useRouter()
	const { pathname, query } = router
	const theme = useTheme()

	return (
		<MobileTabBarWrapper>
			<ul>
				<li>
					<ActiveLink
						className={
							includesPath(pathname, HOME_PAGE) &&
							!includesPath(pathname, MOBILE_CATEGORY_PAGE) &&
							!includesPath(pathname, CATEGORY_PAGE) &&
							!includesPath(pathname, SHOP_CART_PAGE) &&
							!includesPath(pathname, LIBRARIES_INDEX_PAGE) &&
							!includesPath(pathname, MYPAGE_PAGE)
								? 'active-link active'
								: 'active-link'
						}
						href={`${HOME_PAGE}`}
						passHref>
						<Image
							src={'/static/assets/ico-home.svg'}
							alt={'홈'}
							width={24}
							height={24}
						/>
						<span>홈</span>
					</ActiveLink>
				</li>
				<li>
					<ActiveLink
						className={
							includesPath(pathname, SHOP_CART_PAGE)
								? 'active-link active'
								: 'active-link'
						}
						href={isLogin ? SHOP_CART_PAGE : LOGIN_PAGE}
						passHref>
						<Icon icon={Cart} />
						<span>장바구니</span>
					</ActiveLink>
				</li>
				<li>
					<ActiveLink
						className={
							includesPath(pathname, LIBRARIES_INDEX_PAGE)
								? 'active-link active'
								: 'active-link'
						}
						href={isLogin ? LIBRARIES_INDEX_PAGE : LOGIN_PAGE}
						passHref>
						<Icon icon={Library} />
						<span>라이브러리</span>
					</ActiveLink>
				</li>
				<li>
					<ActiveLink
						className={
							includesPath(pathname, MYPAGE_PAGE)
								? 'active-link active'
								: 'active-link'
						}
						href={isLogin ? MYPAGE_PAGE : LOGIN_PAGE}
						passHref>
						<Image
							src={'/static/assets/ico-user-tabbar.svg'}
							alt={'마이페이지'}
							width={24}
							height={24}
						/>
						<span>마이페이지</span>
					</ActiveLink>
				</li>
			</ul>
		</MobileTabBarWrapper>
	)
})
