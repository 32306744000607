import { AxiosError } from 'axios'
import { otherApiClient } from '.'

export interface Coupons {
	id: string
	title: string
	discountMaximumPrice: number
	discountMaximumPriceText: string
	minimumPrice: number
	maximumPrice: number
	minimumPriceText: string
	expiredAt: string
}

export interface HandoutCoupon {
	id: string
	name: string
	couponType: string
	discountType: string
	discountValue: number
	discountMaximumPrice: number
	minimumPrice?: number
	maximumPrice?: number
	isExpired: boolean
	expiredAt: string
	usableProductIds: Array<string>
	isApplied: boolean
	hasBeenReceived: boolean
	expiredAfterDay: number
}

export interface GetCoupons extends Coupons {
	expiredAfterDay?: number
	expiredDate?: string
}

export interface GetMyCouponResponse {
	count: number
	isMoreCoupon: boolean
	items: Array<Coupons>
}

export interface GetHandoutCouponRequest {
	productId: string
	productPrice: number
}

export interface GetHandoutCouponResponse {
	productId: string
	discountedPrice: number
	coupons: Array<HandoutCoupon>
}

export interface CouponFilterItem {
	id: string
	title: string
	count: number
}

export interface CouponFilterList extends CouponFilterItem {
	categoryItems: Array<CouponFilterItem>
}

export interface GetCouponFiltersResponse {
	subjectItems: Array<CouponFilterList>
}

export interface GetReceivableCouponsResponse {
	count: number
	items: Array<GetCoupons>
}

export interface PatchUserCouponRequest {
	orderId: string
	total: number
	items: Array<{
		orderItemId: string
		couponId: string
	}>
}

export interface PostUserCouponRequest {
	couponId: string
}

export interface PostUserCouponResponse {
	couponId?: string
	message?: AxiosError['message']
	name?: AxiosError['name']
	response?: AxiosError['response']
	status?: AxiosError['status']
}

export const getMyCoupons = async () => {
	const { data } = await otherApiClient.market<GetMyCouponResponse>(`/coupons/user`)
	return data
}
export const getHandoutCoupons = async (params: GetHandoutCouponRequest) => {
	const { data } = await otherApiClient.market<GetHandoutCouponResponse>(`/coupons`, { params })
	return data
}
export const getCouponFilters = async () => {
	const { data } = await otherApiClient.market<GetCouponFiltersResponse>(
		`/coupons/receivable/filters`,
	)
	return data
}
export const getReceivableCoupons = async ({
	subject,
	category,
}: {
	subject: string
	category: string
}) => {
	const { data } = await otherApiClient.market<GetReceivableCouponsResponse>(
		`/coupons/receivable`,
		{
			params: {
				subject,
				category,
			},
		},
	)
	return data
}

export const postUserCoupon = async (couponId: string) => {
	const { data } = await otherApiClient.market.post<PostUserCouponResponse>(`/coupons/user`, {
		couponId,
	})
	return data
}

export const patchUserCoupon = async (request: PatchUserCouponRequest) => {
	const { data } = await otherApiClient.market.patch(`/coupons/user/orders`, {
		...request,
	})
	return data
}

export const postProductCoupon = async (productId: string) => {
	const { data } = await otherApiClient.market.post(`/coupons/products`, {
		productId,
	})
	return data
}
