import { IPaginationResponse, otherApiClient, PaginationQuery } from './index'

import { ColProps } from 'antd'
import { toQueryStringCamel } from '../utils/misc'
import { CurriculumsWithString, Grades, SourceType, Subjects, SubjectsWithString } from './product'

export interface BookListDetailFiltersResponse {
	subject: Array<Subjects>
	curriculum: Array<string>
	publisher: Array<string>
}
export interface EbookUniversityFilterResponse {
	department: Array<string>
	type: Array<string>
}
export type EbookUniversityFilterKeys = keyof EbookUniversityFilterResponse

export type BookListDetailFilterKeys = keyof BookListDetailFiltersResponse

export type BookListType = Extract<SourceType, 'aladinbook' | 'ebook' | 'ebookUniv'>
export const findLabel: (key: QueryKeyType, value: string) => string = (key, value) => {
	switch (key as QueryKeyType) {
		case QueryKey.CURRICULUM:
			return CurriculumsWithString[value]
		case QueryKey.SUBJECT:
			return SubjectsWithString[value]
		case QueryKey.KEYWORD:
			return `검색 : ${value}`
		default:
			return value
	}
}

export const BookListDetailFiltersMap: Record<
	BookListDetailFilterKeys,
	{ label: string; colSpan: ColProps['span'] }
> = {
	publisher: { label: '출판사', colSpan: 12 },
	curriculum: { label: '학년', colSpan: 12 },
	subject: { label: '과목', colSpan: 12 },
}

export interface GetFiltersRequest {
	productType: BookListType
	q?: string
	fields?: Array<BookListDetailFilterKeys | EbookUniversityFilterKeys>
}

type SortKey = 'curriculum' | 'publisher' | 'subject' | 'grade'
type SortDirection = 'ASC' | 'DESC'
export const QueryKey = {
	PRODUCT_TYPE: 'productType', // 상품 타입- 알라딘, 굿노트
	KEYWORD: 'q', // 검색어
	SORT_KEY: 'sortkey', // 정렬 키
	SORT_DIRECTION: 'sortDirection', // 정렬 방향
	SUBJECT: 'subjects[]', // 과목
	CURRICULUM: 'curriculums[]', // 교육 과정
	PUBLISHER: 'publishers[]', // 출판사
	GRADE: 'grades[]', // 학년
	DEPARTMENT: 'departments[]', // 전공/계열
	TYPE: 'types[]', // 도서 유형
} as const
export type QueryKeyType = typeof QueryKey[keyof typeof QueryKey]
type Optional<T> = {
	[P in keyof T]?: T[P]
}
type QueryParams = Partial<Record<QueryKeyType, string | string[]>>
export type FilterTypeToQueryKey = Record<BookListDetailFilterKeys, QueryKeyType>
export type EbookFilterTypeToQueryKey = Record<EbookUniversityFilterKeys, QueryKeyType>

interface GetBooksProductsRequest extends PaginationQuery, QueryParams {}

export const FilterTypeToQueryKey: FilterTypeToQueryKey = {
	subject: QueryKey.SUBJECT,
	curriculum: QueryKey.CURRICULUM,
	publisher: QueryKey.PUBLISHER,
} as const

export const EbookFilterTypeToQueryKey: EbookFilterTypeToQueryKey = {
	department: QueryKey.DEPARTMENT,
	type: QueryKey.TYPE,
} as const

export interface BooksProduct {
	author: string
	available: boolean
	category?: string
	coverImg?: string
	curriculum: string
	description?: string
	grades: Array<Grades>
	id: number
	introduction: string
	isbn13: string
	originPrice: number
	previewImg?: Array<string>
	productType: SourceType
	provider: string
	publishedAt: string
	publisher: string
	subCategory?: string
	price: number
	subject: Subjects
	tableOfContents: string
	tags: Array<string>
	thumbImg: string
	title: string
	// 아래는 대학생용 이북을 위한 추가 필드
	department: string
	isRental: boolean
	rentalMonths: number
	pageCount: number
	publisherDescription: string
}

export const isBooksProduct = (product: BooksProduct | any): product is BooksProduct => {
	if (typeof product !== 'object') return false
	return ['author', 'isbn13', 'originPrice', 'productType', 'provider', 'publishedAt'].every(
		(key) => key in product,
	)
}

export interface GetBooksProductsResponse extends IPaginationResponse {
	resource: string
	data: Array<BooksProduct>
}

export interface BooksProductCountResponse {
	aladinbook: number
	ebook: number
	ebookUniversity: number
}

export const getBooksProductsFilters = async (params: GetFiltersRequest) => {
	const { data } = await otherApiClient.market<
		BookListDetailFiltersResponse | EbookUniversityFilterResponse
	>(`/products/filters/${toQueryStringCamel(params)}`)
	return data
}

export const getBooksProducts = async (params: GetBooksProductsRequest) => {
	const { data } = await otherApiClient.market<GetBooksProductsResponse>(
		`/products/${toQueryStringCamel(params)}  `,
	)
	return data
}
export const getBooksProductsCount = async (productType?: BookListType) => {
	const { data } = await otherApiClient.market<BooksProductCountResponse>(
		`/products/count/${toQueryStringCamel({ productType: productType })}  `,
	)
	return data
}

interface GetBooksProductRequest {
	id: string
	productType: BookListType
}

export const getBooksProduct = async (params: GetBooksProductRequest) => {
	const { data } = await otherApiClient.market<BooksProduct>(
		`/products/${params.id}/${toQueryStringCamel({ productType: params.productType })}`,
	)
	return data
}
