import jwtDecode from 'jwt-decode'
import { IncomingMessage } from 'http'
import { getCookie, removeCookie, setCookie } from '../sessions'

export const getJWTFromCookie = (cookie_name: string, req?: IncomingMessage) => {
	return getCookie(cookie_name, req)
}

export const saveJWTToCookie = (cookie_name: string, jwt: string) => {
	return setCookie(cookie_name, jwt)
}

export const removeJWTCookie = (cookie_name: string) => {
	return removeCookie(cookie_name)
}

export const decodeJWT = <TTokenDto>(jwt: string) => {
	return jwtDecode<TTokenDto>(jwt)
}

// interface ServerContext extends NextPageContext {}
//
// export const isAuthenticated = (cookie_name: string, ctx: ServerContext) =>
// 	!!getJWTFromCookie(cookie_name, ctx.req)
//
// export const redirectIfAuthenticated = (ctx: ServerContext, path = '/') => {
// 	if (isAuthenticated(DEFAULT_SESSION_COOKIE_NAME, ctx)) {
// 		redirect(path, ctx)
// 		return true
// 	}
// 	return false
// }
//
// export const redirectIfNotAuthenticated = (ctx: ServerContext, path = '/login') => {
// 	if (!isAuthenticated(DEFAULT_SESSION_COOKIE_NAME, ctx)) {
// 		redirect(path, ctx)
// 		return true
// 	}
// 	return false
// }
