import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { CommonGNB } from '@bookips/solvook-ui-library'
import { SearchRequestForm } from '../../../containers/Header/SearchRequestForm'

const SkeletonGNB = () => {
	return (
		<CommonGNB
			isLoggedIn={true}
			onClickLogo={() => mixpanelEvent('Logo Clicked')}
			searchTextField={<SearchRequestForm />}
			menu={{}}
			disabledCategory={true}
		/>
	)
}

export default SkeletonGNB
