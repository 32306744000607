import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import useSearchParams from '@/hooks/useSearchParams'

import { CategoryQueryKey, DetailFilterKeys } from '../api/category'

const routerOptions = {
	shallow: true,
	scroll: true,
}

export const enum FilterQueryHandlerType {
	RESET = 'reset',
	DETAILFILTER = 'detailFilter',
	CATEGORY = 'category',
	SORT = 'sort',
}

export interface SelectedFilterType {
	key: DetailFilterKeys
	value: string
}

// @TODO filter의 타입이 바뀌거나, 추가되어도 대응이 가능해야 한다.
export const useCategoryFilterHandler = () => {
	const { query, push, pathname } = useRouter()
	const searchParams = useSearchParams()

	const navigationHandler = useCallback(
		(parameter) => {
			push({ pathname, query: parameter.toString() }, undefined, routerOptions)
		},
		[push],
	)
	const handleSortFilter = (sortKey: string) => {
		searchParams.delete(CategoryQueryKey.SORT)
		//페이지네이션 초기화
		resetPagination()
		searchParams.append(CategoryQueryKey.SORT, sortKey)
		navigationHandler(searchParams)
	}
	//영어로 쿼리키가 나가야함.

	const handleDetailFilter = (filterName: string, filterItem: string) => {
		//pagination 초기화
		resetPagination()
		const isExist = searchParams.getAll(filterName).includes(filterItem.toString())
		if (isExist) {
			const newFilter = searchParams
				.getAll(filterName)
				.filter((item) => item !== filterItem.toString())
			searchParams.delete(filterName)
			if (newFilter.length > 0) {
				newFilter.map((item) => searchParams.append(filterName, item))
			}
		} else {
			searchParams.append(filterName, filterItem.toString())
		}
		navigationHandler(searchParams)
	}
	const handleResetFilter = () => {
		//productType 제외 모든 query 삭제
		//pagination 초기화
		resetPagination()
		for (const key in DetailFilterKeys) {
			searchParams.delete(DetailFilterKeys[key])
		}
		navigationHandler(searchParams)
	}
	const handleCategoryMenu = useCallback(
		(category: string) => {
			push(
				{
					pathname,
					query: {
						[CategoryQueryKey.CATEGORY]: category,
					},
				},
				undefined,
				routerOptions,
			)
		},
		[searchParams],
	)
	const selectedFilterList = useMemo(() => {
		let selectedFilters: SelectedFilterType[] = []
		Object.keys(query).forEach((key) => {
			//Querykey에 없는 key는 제외
			//categoryId는 제외
			if (!Object.values(DetailFilterKeys).includes(key as DetailFilterKeys)) return
			searchParams.getAll(key).map((item) => {
				// if (key === QueryKey.CATEGORY) return
				selectedFilters.push({ key: key as DetailFilterKeys, value: item })
			})
		})
		return selectedFilters
	}, [searchParams])
	const resetPagination = useCallback(() => {
		searchParams.delete('page')
	}, [query])
	const selectedSort = useMemo(() => {
		return searchParams.get(CategoryQueryKey.SORT)
	}, [searchParams])
	const hasSelectedFilter = useMemo(() => {
		return selectedFilterList.length > 0
	}, [selectedFilterList])
	const isCheckFilterItem = (filterName: string, filterItem: string) => {
		return searchParams.getAll(filterName).includes(filterItem.toString())
	}
	const isCheckFilterCount = (filterName: string) => {
		return searchParams.getAll(filterName).length
	}

	const filterQueryHandler = ({
		filterType,
		filterName,
		value,
	}: {
		filterType: FilterQueryHandlerType
		filterName?: string
		value?: string
	}) => {
		switch (filterType) {
			case FilterQueryHandlerType.RESET:
				handleResetFilter()
				break
			case FilterQueryHandlerType.DETAILFILTER:
				console.log(filterName, value)
				handleDetailFilter(filterName, value)
				break
			case FilterQueryHandlerType.CATEGORY:
				handleCategoryMenu(value)
				break
			case FilterQueryHandlerType.SORT:
				handleSortFilter(value)
				break
			default:
				break
		}
	}
	//getBooksProduct로 전송할 쿼리
	const getFilteredQuery = useMemo(() => {
		const filteredQuery = {}
		searchParams.forEach((value, key) => {
			filteredQuery[key] = value
		})
		return filteredQuery
	}, [searchParams])

	return {
		selectedFilterList,
		selectedSort,
		hasSelectedFilter,
		getFilteredQuery,
		isCheckFilterItem,
		isCheckFilterCount,
		filterQueryHandler,
		searchParams,
	}
}
