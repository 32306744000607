import * as React from 'react'
import styled from 'styled-components'
import JSONPretty from 'react-json-pretty'

const JsonContainer = styled.div`
	.__json-pretty__ {
		width: 100%;
		padding: 25px;
		line-height: 1.3;
		color: rgba(248, 248, 242, 1);
		background: #1e1e1e;
		overflow: auto;
	}
	.__json-key__ {
		color: #ffffff;
	}
	.__json-value__ {
		color: #ae81ff;
	}

	.__json-string__ {
		color: #e6db74;
	}
	.__json-boolean__ {
		color: #f92672;
	}
`

export const PrettyJson: React.FC<{ value: any }> = ({ value }) => (
	<JsonContainer>
		<JSONPretty data={value} />
	</JsonContainer>
)
