import { themeGet } from '@styled-system/theme-get'
import { Col } from 'antd'
import styled from 'styled-components'

export const AddToCartComplete = styled.div`
	border-radius: 6px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_700};
	color: ${(props) => props.theme.new.colors.white};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	font-size: 18px;
	font-weight: 600;
	box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.25);
	line-height: 1.5;
	position: absolute;
	z-index: 3;
	left: 0;
	right: 0;
	top: 0;
	transition: 0.3s;
	will-change: transform;

	&.fadeOut {
		visibility: hidden;
		transition: 0.25s;
		transform: scale(0.4);
		opacity: 0;
	}

	&.fadeIn {
		visibility: visible;
		transform: scale(1);
	}

	a {
		display: flex;
		align-items: center;
		color: ${(props) => props.theme.new.colors.white};
		font-size: 14px;
		font-weight: 600;
		gap: 4px;
	}
`
export const PreviewModal = styled.div`
	display: flex;
	width: 960px;
	height: 90vh;
	@media (max-width: 1200px) {
		width: 100%;
		height: 77vh;
	}

	.navigator {
		background-color: ${(props) => props.theme.new.colors.gray200};
		padding: 20px;
		overflow: scroll;
		height: 100%;

		> div {
			gap: 12px;
			flex-direction: column;
			display: flex;
		}

		button {
			width: 120px;
			height: 167px;
			background-size: cover;
			background-repeat: no-repeat;
			border: 2px solid ${(props) => props.theme.new.colors.gray200};
			background-position: center;
			backdrop-filter: opacity(0.6);

			:hover,
			&.active {
				border: 2px solid ${(props) => props.theme.new.colors.primary};
				transition: 0.4s;
			}
		}
	}

	.zoom {
		background-color: ${(props) => props.theme.new.colors.gray270};
		width: 85%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 20px;
		@media (max-width: 1200px) {
			width: 100%;
			flex: 1;
		}

		.zoom-image {
			width: 100%;
			height: calc(100% - 50px);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
`
export const PreviewTitle = styled.div`
	text-align: left;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	padding: 11px;

	span {
		margin-left: 4px;
		color: ${(props) => props.theme.new.colors.primary};
	}
`
export const ProductDetailViewWrapper = styled.div`
	max-width: 1232px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;

	@media (max-width: 1200px) {
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

export const ProductDetailInfoWrapper = styled.div`
	margin-bottom: 80px;
	display: flex;
	justify-content: center;

	@media (max-width: 1200px) {
		margin-bottom: 60px;
	}
	@media (max-width: 768px) {
		margin-bottom: 40px;
	}
	i {
		vertical-align: middle;
	}
	.ant-row {
		width: 100%;

		@media (max-width: 1200px) {
			margin: 0 !important;
			.ant-col {
				max-width: 100%;
			}
		}
		@media (max-width: 768px) {
			display: block;
			width: 100%;
		}
	}
	.amount {
		display: inline-block;
		vertical-align: bottom;
		font-size: 16px;
		font-weight: bold;
		color: rgba(27, 27, 41, 0.7);
		padding: 4px 12px;
		margin-left: 8px;
		border-radius: 15px;
		border: solid 1px rgba(27, 27, 41, 0.7);
	}
	.ant-col {
		.preview-btn {
			margin-top: 20px;
			display: block;
			height: 48px;
			width: 100%;
			border-radius: 6px;
			font-size: 16px;
			font-weight: 700;
			border-color: #6950e5;
			@media (max-width: 768px) {
				display: none;
			}
			span {
				color: #6950e5;
			}
			:hover {
				opacity: 0.7;
			}
		}
		.share-btn {
			display: block;
			width: 100%;
			height: 72px;
			font-size: 24px;
			font-weight: 600;
			color: #5f5f69;
			margin: 24px auto 0;
			box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03) !important;

			span {
				transition: color 0.2s;
			}

			&:hover {
				border-color: #01bfc8;

				span {
					color: #01bfc8;
				}
			}

			img {
				margin-right: 8px;
				margin-top: -4px;
			}

			@media (max-width: 1200px) {
				width: calc(100% - 36px);
				height: 48px;
				line-height: 0;
				padding: 0;
				margin-top: 16px;
				margin-left: 0;

				img {
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}

				span {
					font-size: 18px;
				}
			}
			@media (max-width: 768px) {
				width: 48px;
				padding: 0;
				margin-left: auto;
				margin-right: 0;
				margin-top: 8px;
				border-radius: 2px;

				img {
					width: 18px;
					height: 18px;
					margin-top: -2px;
					margin-right: 0;
				}

				span {
					display: none;
				}
			}
		}
	}

	.titleWrapper {
		display: flex;
		flex-direction: row;
	}

	.ant-radio-group-outline {
		display: flex;
		flex-direction: column;
	}

	.ant-radio-wrapper {
		border: 1px solid ${themeGet('border.0')};
		margin-bottom: 5px;
		padding: 15px;
	}

	.alert {
		width: 100%;
		text-align: right;

		span {
			color: #00bfc8;
		}
	}

	.workbook {
		border-top: 1px solid rgba(27, 27, 41, 0.1);
		border-bottom: 1px solid rgba(27, 27, 41, 0.1);
		padding: 10px 0;

		p {
			font-weight: bold;
		}
	}

	.price {
		font-weight: bold;
		font-size: 32px;
		display: flex;
		justify-content: space-between;

		@media (max-width: 1200px) {
			font-size: 24px;
		}
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}

	.btnWrapper {
		border-style: unset;
		display: flex;
		justify-content: space-between;
		margin: 20px;

		button {
			width: 45%;
			padding: 5px;
		}
	}
	.parsing-badge-wrapper {
		margin-top: 16px;
		width: 100%;
		padding: 14px;
		display: flex;
		align-items: center;
		background-color: #fff6e5;
		justify-content: space-between;
		.left-badge {
			display: flex;
			align-items: center;
			line-height: 1.43;
			font-size: 14px;
			color: ${themeGet('text.2', '#2C2C2C')};
		}
		span {
			margin-left: 8px;
			float: left;
		}
	}
	.notParsing-badge-wrapper {
		margin-top: 16px;
		width: 100%;
		padding: 14px;
		display: flex;
		align-items: center;
		background-color: rgba(27, 27, 41, 0.03);
		justify-content: space-between;
		.label-edit-img {
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
			filter: gray;
		}
		.edit-desc {
			color: #5f5f69;
			font-size: 14px;
			font-weight: 800;
			margin-left: 4px;
			text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
		}
		.left-badge {
			display: flex;
			align-items: center;
			line-height: 1.43;
			font-size: 14px;
			color: ${themeGet('text.2', '#2C2C2C')};
		}
		.edit-request {
			font-size: 14px;
			color: #1890ff;
			font-weight: 800;
		}
		span {
			margin-left: 4px;
			float: left;
		}
		.right-edit {
			line-height: 1.43;
			img {
				margin-left: 4px;
				vertical-align: sub;
			}
		}
	}
`
export const ImageSection = styled(Col)`
	padding-right: 48px;
	width: 292px;
	@media (max-width: 768px) {
		background-color: ${(props) => props.theme.new.colors.sol_gray_0};
		padding: 40px 0;
		width: 100%;
	}
	.img_box {
		width: 244px;
		border: solid 1px #e8e8ea;
		text-align: center;
		border-radius: 6px;
		height: auto;
		box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);
		img {
			border-radius: 6px;
			position: relative;
			display: inline-block;
			object-fit: contain;
			margin: 0 auto;
			width: 100%;
			height: auto;
			max-height: 698px;
		}
		.no-image {
			border-radius: 6px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			object-fit: contain;
			margin: 0 auto;
			width: 100%;
			height: 330px;
			max-height: 698px;
		}
		@media (max-width: 768px) {
			width: 160px;
			margin: 0 auto;
		}
	}
`
export const StickySection = styled(Col)`
	width: 340px;
	@media (max-width: 1200px) {
		display: none;
	}
`
export const CoreSection = styled(Col)`
	width: 100%;
	flex: 1;
	padding-right: 72px;
	@media (max-width: 1200px) {
		//margin-top: 36px;
	}
	@media (max-width: 768px) {
		margin-top: 16px;
		padding: 0 16px;
	}
	position: relative;
	padding-bottom: 20px;
	> p {
		font-size: 14px;
		color: #6950e5;
		margin-bottom: 0;
		font-weight: 600;
		> span {
			color: ${(props) => props.theme.new.colors.sol_gray_400};
			margin-left: 12px;
		}
	}
	> h1 {
		display: flex;
		align-items: center;
		font-size: 28px;
		font-weight: 700;
		color: ${(props) => props.theme.new.colors.sol_gray_900};
		line-height: 1.5;
		word-break: keep-all;
		margin-top: 4px;

		@media (max-width: 1200px) {
			font-size: 28px;
		}
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}
	.authorContainer {
		display: flex;
		font-size: 16px;
		font-weight: 600;
		color: ${(props) => props.theme.new.colors.sol_gray_600};
		line-height: 24px;
		margin-bottom: 28px;
		> a {
			border-radius: 4px;
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
			padding: 2px 4px 2px 8px;
			font-size: 12px;
			color: #4f556b;
			line-height: 18px;
			margin-left: 8px;
			display: flex;
			align-items: center;
		}
	}
	.license-guide-btn {
		position: absolute;
		top: -8px;
		right: -16px;
		transition: opacity 0.2s;

		&,
		&:hover {
			font-size: 16px;
			font-weight: bold;
			color: #5f5f69;
			border: none;
		}

		span {
			text-decoration: underline;
		}
	}
`

export const ContentInfo = styled.ul`
	font-size: 16px;
	border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	padding: 28px 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
	li {
		display: flex;
		div {
			line-height: 1.5;
			&.type {
				color: ${(props) => props.theme.new.colors.sol_gray_400};
				width: 100px;
			}
			&.type-value {
				color: ${(props) => props.theme.new.colors.sol_gray_600};
				width: calc(100% - 100px);
				i {
					margin-left: 4px;
				}
			}
		}
		&.core-info-handout {
			.출처교재 {
				display: flex;
				align-items: center;
				> span {
					cursor: pointer;
					transition: all 0.3s ease;
					:hover {
						text-decoration: underline;
						//text-underline-offset: 1px;
					}
				}
			}
		}
	}
`

export const AllowanceInfo = styled.div`
	padding: 28px 0;
	.activity {
		.desc-title {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 12px;
			color: ${(props) => props.theme.new.colors.sol_gray_900};

			span {
				font-weight: normal;
				margin-left: 8px;
				color: ${(props) => props.theme.new.colors.sol_gray_400};
				font-size: 12px;
			}
		}
		.allowance-wrapper {
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			@media (max-width: 768px) {
				//flex-wrap: nowrap;
				//overflow-x: auto;
			}
		}
	}

	.key-info {
		padding-bottom: 12px;
		border-top: solid 1px ${themeGet('border.1', '#E8E8EA')};

		.desc-title {
			font-size: 16px;
			font-weight: bold;
			color: ${themeGet('text.0', '#1B1B29')};
			padding: 16px 8px;
			margin-bottom: 12px;
			border: 0;
			background-image: linear-gradient(to right, #e8e8ea 33%, rgba(255, 255, 255, 0) 0%);
			background-position: bottom;
			background-size: 5px 2px;
			background-repeat: repeat-x;

			&:after {
				display: inline-block;
				vertical-align: text-top;
				content: '';
				width: 24px;
				height: 24px;
				margin-top: -3px;
				background: url('/static/assets/img-pin.svg') no-repeat 50% 50%;
				background-size: contain;
			}
		}

		p:not(.desc-title) {
			font-size: 16px;
			font-weight: normal;
			color: ${themeGet('text.0', '#1B1B29')};
			padding: 4px 8px;

			span {
				display: inline-block;
				width: 50px;
				font-weight: bold;
				color: #5f5f69;
			}
		}
	}

	.base-license {
		padding: 16px 8px 0;
		border: 0;
		background-image: linear-gradient(to right, #e8e8ea 33%, rgba(255, 255, 255, 0) 0%);
		background-position: top;
		background-size: 5px 2px;
		background-repeat: repeat-x;

		a {
			display: inline-block;
			border: solid 1px #e8e8ea;
			transition: opacity 0.2s;

			@media (hover: hover) {
				&:hover {
					opacity: 0.7;
				}
			}
		}

		p {
			font-size: 16px;
			font-weight: bold;
			color: #5f5f69;
			margin-bottom: 8px;
		}

		button {
			display: inline-block;
			height: auto;
			border: solid 1px #e8e8ea;
			padding: 0;
			margin-right: 4px;
		}
	}

	button.desc-title {
		display: block;
		width: 100%;
		height: 56px;
		font-size: 16px;
		font-weight: bold;
		color: ${themeGet('text.0', '#1B1B29')};
		text-align: left;
		padding: 15px 0;
		border: solid 1px ${themeGet('border.1', '#E8E8EA')};
		border-left: 0;
		border-right: 0;
		background: url('/static/assets/ico-plus-black.svg') no-repeat 100% 50%;
		background-size: 16px;
		transition: opacity 0.2s;

		@media (hover: hover) {
			&:hover {
				opacity: 0.7;
				color: ${themeGet('text.0', '#1B1B29')};
				border-color: ${themeGet('border.1', '#E8E8EA')};
			}
		}
	}
`

export const PriceInfo = styled.div`
	padding: 20px;

	background-color: #fff;
	margin-top: 28px;
	border-radius: 6px;
	box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);

	display: flex;
	flex-direction: column;

	.original-price {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-end;
		font-size: 14px;
		.origin-price-text {
			color: ${(props) => props.theme.new.colors.sol_gray_300};
			font-weight: 400;
			text-decoration: line-through;
		}
		span.sale-rate {
			color: ${(props) => props.theme.new.colors.sol_indigo_500};
			font-weight: 600;
		}
	}
	.price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 24px;
		margin: 0;
		color: ${(props) => props.theme.new.colors.sol_gray_900};
		font-weight: 700;
		span {
			flex: 1;
			font-size: 16px;
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			font-weight: 400;
		}
	}
	.ctCartBtn {
		position: relative;
		height: 56px;
		font-weight: 600;
		color: #fff;
		margin-top: 24px;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
		border-radius: 6px;
		border: none;
		span {
			white-space: pre-wrap;
		}
		:hover:not(:disabled) {
			opacity: 0.8;
			background-color: ${(props) => props.theme.new.colors.sol_indigo_500} !important;
			color: #fff !important;
		}
		:disabled {
			background-color: transparent;
			border: 1px solid ${(props) => props.theme.new.colors.sol_red_100};
			color: ${(props) => props.theme.new.colors.sol_red_400};
		}
	}
`

export const WarnBoxLayout = styled.div`
	position: relative;
	> .license {
		position: relative;
		border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		border-radius: 6px;
		padding: 20px;
		p {
			margin: 0;
			font-size: 14px;
			letter-spacing: -0.2px;
			color: ${(props) => props.theme.new.colors.sol_gray_600};
			line-height: 22px;
			b {
				font-weight: 700;
				color: ${(props) => props.theme.new.colors.sol_indigo_500};
			}
		}

		h5 {
			z-index: 2;
			position: relative;
			font-size: 20px;
			display: inline-block;
			font-weight: 700;
			color: ${(props) => props.theme.new.colors.sol_gray_900};
			margin-bottom: 16px;
			::before {
				position: absolute;
				display: block;
				vertical-align: middle;
				content: '';
				z-index: -1;
				height: 12px;
				bottom: 0;
				width: 100%;
				background-color: ${(props) => props.theme.new.colors.sol_indigo_100};
			}
			@media (max-width: 768px) {
				h5 {
					font-size: 18px;
				}
			}
		}
		a {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: flex-end;
			color: ${(props) => props.theme.new.colors.sol_gray_600};
			margin-top: 16px;
		}
		.link-default {
			position: absolute;
			top: 20px;
			right: 20px;
			margin-top: 0;
		}
	}
`
export const UCIPromotionBox = styled.div`
	display: flex;
	margin-top: 12px;
	padding: 11px 16px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	gap: 8px;
	border-radius: 4px;
	align-items: center;
	p {
		margin: 0;
	}
	> div {
		p:first-child {
			margin-bottom: 4px;
			font-size: 10px;
			color: ${(props) => props.theme.new.colors.sol_gray_600};
		}
		p:last-child {
			font-size: 7px;
			color: ${(props) => props.theme.new.colors.sol_gray_400};
		}
	}
	@media (max-width: 768px) {
		align-items: center;
		justify-content: center;
		padding: 0;
		margin-top: 17px;
		> div p:first-child {
			color: ${(props) => props.theme.new.colors.sol_gray_400};
			margin: 0;
		}
		.hide_m {
			display: none;
		}
	}
`
export const ModalTitle = styled.div`
	margin-bottom: 16px;
`
