import React from 'react'
import styled, { css } from 'styled-components'

type Placement = 'top' | 'bottom' | 'left' | 'right'

export interface GuidTooltipProps {
	children?: React.ReactNode
	placement?: Placement
	isVisibleTooltip: boolean
}

export const GuidTooltip: React.FC<GuidTooltipProps> = ({
	children,
	placement,
	isVisibleTooltip,
}) => {
	return (
		<GuidTooltipWrapper
			visible={isVisibleTooltip}
			placement={placement}
			className={'guid-tooltip-wrapper'}>
			<p>{children}</p>
		</GuidTooltipWrapper>
	)
}

const GuidTooltipWrapper = styled.div<{ visible: boolean; placement?: Placement }>`
	position: absolute;
	top: calc(100% + 8px);
	left: 50%;
	transform: translateX(-50%);
	transition: opacity 0.2s;
	pointer-events: none;
	opacity: ${(props) => (props.visible ? 1 : 0)};
	z-index: 2;

	p {
		white-space: nowrap;
		color: ${(props) => props.theme.new.colors.white};
		padding: 8px 12px;
		border-radius: 4px;
		box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		background: ${(props) => props.theme.new.colors.sol_blue_400};
		text-align: center;

		&::before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-width: 8px;
			border-style: solid;
			border-color: transparent transparent ${(props) => props.theme.new.colors.sol_blue_400}
				transparent;
		}
	}

	${(props) => {
		switch (props.placement) {
			case 'top':
				return `
			
			`
			case 'bottom':
				return `
		
			
			`
			case 'right':
				return css`
					top: unset;
					left: -60%;

					p::before {
						display: none;
					}

					p::after {
						bottom: 30%;
						transform: rotate(90deg);
						right: -14px;
						content: '';
						position: absolute;
						border-width: 8px;
						border-style: solid;
						border-color: transparent transparent
							${(props) => props.theme.new.colors.sol_blue_400} transparent;
					}
				`
			case 'left':
				return `
				top:unset;
				p::before {
				bottom:30%;
				transform:translateX(-50%) rotate(270deg);
				left:-6px;
				
				}
			
			`
			default:
				return ''
		}
	}}
`
