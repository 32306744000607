import { BOOKS_LANDING, EBOOKS_LANDING, PASSES_LANDING } from '@/settings/constant'
import { Button } from '@bookips/solvook-ui-library'
import { styled as muiStyled } from '@mui/material'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import FilledButton from '../common/Button/FilledButton'

export const getIsPasses = (pathname: string) => pathname.startsWith(PASSES_LANDING)
export const usePasses = () => {
	const { pathname } = useRouter()
	const { isPasses } = useMemo(() => {
		return { isPasses: getIsPasses(pathname) }
	}, [pathname])

	return { isPackages: isPasses }
}
export const useProductType = () => {
	const { pathname } = useRouter()
	// Todo 로직 수정 필요
	const { productType } = useMemo(() => {
		let productType
		switch (pathname.split('/')[1]) {
			case EBOOKS_LANDING:
				productType = 'ebooks'
				break
			case PASSES_LANDING:
				productType = 'package'
				break
			case BOOKS_LANDING:
				productType = 'aladinbooks'
				break
		}
		return { productType }
	}, [pathname])

	return { productType }
}

export const Title = muiStyled('h5')(({ theme }) => {
	return {
		color: theme.palette.grey[900],
		marginBottom: '24px',
		...theme.typography.h5,
		'& em': {
			color: theme.palette.indigo[500],
			textDecoration: 'none',
		},
		[theme.breakpoints.down(820)]: {
			marginBottom: '20px',
		},
	}
})

export const SubTitle = muiStyled('h5')(({ theme }) => {
	return {
		color: theme.palette.text['title'],
		marginBottom: '12px',
		...theme.typography.h6,
		'& span': {
			color: theme.palette.text['secondary'],
			marginInlineStart: '4px',
			...theme.typography.body2,
		},
		[theme.breakpoints.down('sm')]: {
			'& span': {},
		},
	}
})
export const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	margin-bottom: 40px;

	.notice-title {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-weight: 600;
		margin-bottom: 8px;
	}
`
export const NoticeList = styled.ul`
	li {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		line-height: 22px;
		list-style: initial;
		margin-inline-start: 24px;
	}

	@media (max-width: 768px) {
		li {
			font-size: 14px;
		}
	}
`

export const OriginalPrice = muiStyled('s')(({ theme }) => {
	return {
		color: theme.palette.text['disabled'],
		...theme.typography.body2,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	}
})

export const DiscountRate = muiStyled('em')(({ theme }) => {
	return {
		color: theme.palette.red[400],
		marginInlineEnd: '4px',
		...theme.typography.h6,
		[theme.breakpoints.down('sm')]: {},
	}
})

export const Price = muiStyled('p')(({ theme }) => {
	return {
		color: theme.palette.text['primary'],
		textAlign: 'right',
		whiteSpace: 'nowrap',
		...theme.typography.h6,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	}
})

export const PassTitle = muiStyled('h5')(({ theme }) => {
	return {
		color: theme.palette.text['primary'],
		marginBottom: '4px',
		maxWidth: '380px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-all',
		...theme.typography.h6,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column-reverse',
			alignItems: 'flex-start',
		},
	}
})
export const PassPrice = styled.div`
	.priceInfo {
		display: flex;
		align-items: center;
		@media (max-width: 768px) {
			display: flex;
			align-items: flex-start;
		}
	}
`
export const PassItemContainer = styled.li`
	position: relative;
	padding: 24px;
	border-radius: 6px;
	background-color: ${(pass) => pass.theme.new.colors.white};
	border: 1px solid ${(pass) => pass.theme.new.colors.sol_gray_100};

	:hover {
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
		transition: box-shadow 0.3s ease-in-out;
	}

	.ant-row {
		width: 100%;
		justify-content: space-between;
		flex-wrap: nowrap;
		gap: 40px;
		align-items: center;
	}

	.ant-col {
		align-items: center;

		&.clickArea {
			cursor: pointer;
			flex: 1;
		}

		&.priceInfo {
			text-align: right;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			height: 52px;

			.left {
				height: 100%;
				justify-content: center;
				display: flex;
				flex-direction: column;
				margin-right: 24px;
			}

			.right {
				display: flex;
				flex-direction: column;
			}
		}
	}

	@media (max-width: 768px) {
		padding: 20px;
		.ant-row {
			flex-direction: column;
			align-items: stretch;
		}

		.ant-col.priceInfo {
			align-items: flex-end;
		}

		.ant-col {
			&.priceInfo {
				.left {
					justify-content: flex-end;
				}
			}
		}
	}
`

export const SubDescription = muiStyled('span')(({ theme }) => {
	return {
		color: theme.palette.text['secondary'],
		display: 'flex',
		alignItems: 'center',
		...theme.typography.body1,
	}
})

export const OrderFilledButton = muiStyled(FilledButton)(({ theme }) => {
	return {
		padding: '10px 16px',
		...theme.typography.subtitle2,
	}
})
export const PurchaseButton = ({
	onClick,
	children,
}: {
	onClick: (e: unknown) => void
	children?: React.ReactNode
}) => {
	return (
		<OrderFilledButton
			type={'text'}
			variant={'outlined'}
			className={'cart-btn'}
			onClick={onClick}>
			{children}
		</OrderFilledButton>
	)
}
export const SubscribeButton = ({
	onClick,
	children,
}: {
	onClick: (e: unknown) => void
	children?: React.ReactNode
}) => {
	return (
		<OrderFilledButton type={'text'} variant={'solid'} className={'cart-btn'} onClick={onClick}>
			{children}
		</OrderFilledButton>
	)
}
export const PassDialogButtonContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
	padding: 20px 28px;
`

export const PassDialogButton = muiStyled(Button)(({ theme }) => {
	return {
		width: '100%',
		padding: '12px 20px',
		...theme.typography.semibold16,
	}
})
