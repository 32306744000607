import { PayMethod } from '@/lib/iamport'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import dayjs from 'dayjs'
import { create } from 'zustand'
import { Ticket, UseTicketProductInfo } from '../api/membership'

export interface IMappedPassProduct {
	remainDay?: number
	remainCount?: number
	expiredAt?: string
	startedAt?: string
	isNoCountLimit?: boolean
	descriptionList: Array<string>
}

export interface PassState {
	isPassInfoModal: boolean
	selectedPass: UseTicketProductInfo & IMappedPassProduct
	purchasePass: UseTicketProductInfo
	passPayMethod: PayMethod
}

export interface PassActions {
	closePassInfoModal: () => void
	openPassInfoModal: (pass: { passInfo: UseTicketProductInfo; userPassInfo?: Ticket }) => void
	setPurchasePass: (pass: UseTicketProductInfo) => void
	setPassPayMethod: (payMethod: PayMethod) => void
}

const usePassStore = create<PassState & PassActions>((set) => ({
	isPassInfoModal: false,
	selectedPass: null,
	purchasePass: null,
	passPayMethod: 'card',
	setPassPayMethod: (payMethod) => set({ passPayMethod: payMethod }),
	setPurchasePass: (pass) => set({ purchasePass: pass }),
	closePassInfoModal: () => {
		set({ isPassInfoModal: false })
		set({ selectedPass: null })
	},
	openPassInfoModal: (pass) => {
		// pass를 맵핑
		const { passInfo } = pass

		const descriptionList = passInfo.description ? passInfo.description.split('\n') : []
		const expiredAt = () => {
			if (!pass.userPassInfo) return null
			return dayjs(pass.userPassInfo.expiredAt).format('YYYY.MM.DD HH:mm:ss')
		}
		const startedAt = () => {
			if (!pass.userPassInfo) return null
			return dayjs(pass.userPassInfo.startedAt).format('YYYY.MM.DD')
		}
		const mappedPass: IMappedPassProduct = {
			remainDay: pass.userPassInfo ? pass.userPassInfo.remainDay : null,
			expiredAt: expiredAt(),
			startedAt: startedAt(),
			remainCount: pass.userPassInfo ? pass.userPassInfo.remainCount : null,
			isNoCountLimit: passInfo.availableCount === 999999 || !!!passInfo.availableCount,
			descriptionList,
		}
		set({ selectedPass: { ...passInfo, ...mappedPass } })
		set({ isPassInfoModal: true })
		mixpanelEvent('Product Detail Popup Viewed', {
			'Product Categories': passInfo.category,
			'Product ID': passInfo.id,
			'Product Title': passInfo.title,
			'Product Price': passInfo.price,
		})
	},
}))
export default usePassStore
