import * as React from 'react'
import ContainerWrapper from './Container.style'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'

export interface ContainerProp {
	children: React.ReactNode
	className?: string
	fullWidth?: boolean
	noGutter?: boolean
	fluid?: boolean
}

export const Container: React.FC<ContainerProp> = ({
	children,
	className,
	fullWidth,
	noGutter,
	fluid,
}) => {
	return (
		<ContainerWrapper
			className={classNames('container', className, {
				'no-gutter': noGutter,
				'full-width': fullWidth,
				fluid: fluid,
			})}
			fullWidth={fullWidth}
			noGutter={noGutter}
			fluid={fluid}>
			{children}
		</ContainerWrapper>
	)
}
