import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { SpinIndicator } from 'antd/lib/spin'

export const ActivityIndicator: React.FC<{
	color?: string
	fontSize?: number
	indicator?: SpinIndicator
}> = ({ color, fontSize = 24, indicator }) => {
	return <LoadingOutlined style={{ fontSize, color }} spin />
	// return <Spin indicator={indicator} />
}
