import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'

export const ButtonVariants = {
	plain: 'plain',
	outlined: 'outlined',
	soft: 'soft',
	solid: 'solid',
} as const
export type ButtonVariant = typeof ButtonVariants[keyof typeof ButtonVariants]

export interface FillButtonProps extends ButtonProps {
	variant?: ButtonVariant
}

const FilledButton = styled(Button)<FillButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	padding: 9px 16px;

	border-radius: 6px;
	font-weight: 600;
	letter-spacing: -0.14px;

	&.ant-btn-text:disabled {
		background-color: ${(props) => props.theme.new.colors.sol_gray_100} !important;
		color: ${(props) => props.theme.new.colors.sol_gray_300} !important;
	}

	&${(props) => (props.variant === ButtonVariants.solid ? '.ant-btn-text' : '.unavailable')} {
		background-color: ${(props) => props.theme.new.colors.sol_indigo_500};
		color: ${(props) => props.theme.new.colors.white};

		&.active,
		&:hover {
			background-color: ${(props) => props.theme.new.colors.sol_indigo_600};
			color: ${(props) => props.theme.new.colors.white};
		}
	}
	&${(props) => (props.variant === ButtonVariants.soft ? '.ant-btn-text' : '.unavailable')} {
		background-color: ${(props) => props.theme.new.colors.sol_indigo_100};
		color: ${(props) => props.theme.new.colors.sol_indigo_600};

		&.active,
		&:hover {
			background-color: ${(props) => props.theme.new.colors.sol_indigo_200};
			color: ${(props) => props.theme.new.colors.sol_indigo_600};
		}
	}
	&${(props) => (props.variant === ButtonVariants.outlined ? '.ant-btn-text' : '.unavailable')} {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
		border: 1px solid ${(props) => props.theme.new.colors.sol_indigo_500};
		&.active,
		&:hover {
			background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
			color: ${(props) => props.theme.new.colors.sol_indigo_500};
		}
	}
`

export default FilledButton
