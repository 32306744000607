import React, { useCallback, useState } from 'react'

type SubmitHandler = (done: () => void, validation: { isValid: boolean; message?: string }) => void

export const useSubmit = (): [boolean, () => void, () => void] => {
	const [isSubmmiting, setSubmitting] = useState(false)

	const start = useCallback(() => {
		setSubmitting(true)
	}, [])

	const done = useCallback(() => {
		setSubmitting(false)
	}, [])

	return [isSubmmiting, start, done]
}

type FormValidator<T> = (values: T) => [boolean, string | undefined]

export const useForm = <T>(
	initialValue: T,
	{
		validator,
		onSubmit,
	}: {
		validator?: FormValidator<T>
		onSubmit?: SubmitHandler
	},
) => {
	const [values, setValues] = useState(initialValue)
	const [isSubmitting, start, done] = useSubmit()

	const onChange = useCallback((e: React.ChangeEvent) => {
		const { name, value, type } = e.target as HTMLInputElement | HTMLTextAreaElement
		if (type === 'checkbox') {
			const { checked } = (e as React.ChangeEvent<HTMLInputElement>).target
			setValues((prev) => ({ ...prev, [name as keyof T]: checked }))
		} else {
			setValues((prev) => ({ ...prev, [name as keyof T]: value }))
		}
	}, [])

	const setValue = useCallback(<K extends keyof T>(name: K, value: T[K]) => {
		setValues((prev) => ({ ...prev, [name]: value }))
	}, [])

	const submit = useCallback(() => {
		if (isSubmitting) {
			return
		}

		if (onSubmit) {
			start()
			if (validator) {
				const [isValid, message] = validator(values)
				onSubmit(done, { isValid, message })
			} else {
				onSubmit(done, { isValid: true })
			}
		}
	}, [isSubmitting, onSubmit, validator, values])

	const reset = useCallback(() => {
		setValues(initialValue)
	}, [initialValue])

	return {
		values,
		setValues,
		setValue,
		onChange,
		submit,
		isSubmitting,
		isValidated: validator ? validator(values)[0] : true,
		reset,
	}
}
