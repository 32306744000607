import Icon from '@/components/icon/Icon'
import { Close, Sizes } from '@/components/icon/IconList'
import Modal from '@/components/modal/Modal'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { PASSES_LANDING, PASSES_ORDER } from '@/settings/constant'
import { Button } from '@bookips/solvook-ui-library'
import { Divider } from 'antd'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import Sheet from 'react-modal-sheet'
import styled from 'styled-components'
import LoginDialog from '../../store/loginDialog'
import usePassStore from '../../store/pass'
import FilledButton from '../common/Button/FilledButton'
import AnchorDrawer from '../modal/AnchorDrawer'
import { getPassScope, isCategoryIncludesExpert } from './getPassScope'

const PassInfoSheet = styled(Sheet)<{ isOpen: boolean }>`
	.react-modal-sheet-backdrop {
		background-color: rgba(0, 0, 0, 0.5) !important;
		${(props) => !props.isOpen && 'background-color: transparent;'}
		transition: background-color 0.3s;
	}
`

interface PassInfoModalProps {
	ctaAction?: () => void
}

export const PassInfoModal = ({ ctaAction }: PassInfoModalProps) => {
	const { isMobile } = useWindowDimensions()
	const isPassInfoModal = usePassStore((state) => state.isPassInfoModal)
	const closePassInfoModal = usePassStore((state) => state.closePassInfoModal)

	//모바일의 경우 바텀시트
	if (!isMobile)
		return (
			<PassInfoModalWrapper
				depth={1008}
				open={isPassInfoModal}
				onClose={closePassInfoModal}
				disableOutsideClick={false}>
				<PassInfoModalContents ctaAction={ctaAction} />
			</PassInfoModalWrapper>
		)
	return (
		<AnchorDrawer
			anchor={'bottom'}
			open={isPassInfoModal}
			onClose={() => {
				closePassInfoModal()
			}}>
			<AnchorDrawer.Contents>
				<PassInfoModalContents ctaAction={ctaAction} />
			</AnchorDrawer.Contents>
		</AnchorDrawer>
	)
}
const PassInfoModalContents = ({ ctaAction }: { ctaAction?: () => void }) => {
	const router = useRouter()
	const { isLoggedIn } = useAuthStore()
	const { openLoginDialog } = LoginDialog()

	const setIsPassInfoModal = usePassStore((state) => state.closePassInfoModal)
	const selectedPass = usePassStore((state) => state.selectedPass)
	const purchasePass = usePassStore((state) => state.purchasePass)

	const isOrderPage = router.pathname.startsWith(PASSES_LANDING) //구매페이지 일경우
	const isPassPage = router.pathname === PASSES_LANDING //이용권 페이지 일경우

	const onPurchase = useCallback(() => {
		mixpanelEvent('Purchase Button Clicked', {
			'Product Categories': purchasePass.category,
			'Product ID': purchasePass.id,
			'Product Title': purchasePass.title,
			'Product Price': purchasePass.price,
		})

		if (!isLoggedIn) {
			setIsPassInfoModal()
			openLoginDialog()
			return
		}
		router.push(`${PASSES_ORDER}?id=${purchasePass.id}`)
		setIsPassInfoModal()
	}, [isLoggedIn, purchasePass])

	if (!selectedPass) return null
	return (
		<PassInfoModalContainer>
			<main>
				<h4>
					{selectedPass.title}

					<CloseButton onClick={setIsPassInfoModal} />
				</h4>
				<div className={'pass-summary'}>
					{isOrderPage ? (
						<>
							<dl>
								<dt>
									{isCategoryIncludesExpert(selectedPass.category)
										? '총 사용가능 문제 수 :'
										: '총 사용 가능 횟수 : '}
								</dt>
								{selectedPass.isNoCountLimit ? (
									<dd>조건 내 무제한 사용</dd>
								) : (
									<dd>
										{numberFormatter(selectedPass.availableCount)}
										{isCategoryIncludesExpert(selectedPass.category)
											? '개'
											: '회'}
									</dd>
								)}
							</dl>
							<dl>
								<dt>사용 기간 :</dt>
								<dd>
									결제일로부터 {selectedPass.period}일간
									{/*({selectedPass.period}일)*/}
								</dd>
							</dl>
						</>
					) : (
						<>
							<dl>
								<dt>
									<b>
										{isCategoryIncludesExpert(selectedPass.category)
											? '남은 사용 문제 수 :'
											: '남은 사용 횟수 :'}
									</b>
								</dt>
								<dd className="style-zero">
									{selectedPass.isNoCountLimit ? (
										<b>조건 내 무제한 사용</b>
									) : (
										<b> {numberFormatter(selectedPass.remainCount) ?? 0}회</b>
									)}
								</dd>
							</dl>
							{!selectedPass.isNoCountLimit && (
								<dl>
									<dd className="style-zero">
										{isCategoryIncludesExpert(selectedPass.category)
											? '총 사용가능 문제 수 :'
											: '총 사용 가능 횟수 : '}
										{selectedPass.availableCount}
										{isCategoryIncludesExpert(selectedPass.category)
											? '개'
											: '회'}
									</dd>
								</dl>
							)}
							<dl>
								{/*<dt>남은 사용 기간 :</dt>*/}
								<dd className="style-zero">
									남은 사용 기간 : {selectedPass.remainDay}일 (
									{selectedPass.startedAt} ~ {selectedPass.expiredAt})
								</dd>
							</dl>
						</>
					)}
				</div>
				<Divider />
				<div className={'pass-range'}>
					<p>이용권 사용범위</p>
					{getPassScope(selectedPass).map((scope, idx) => (
						<dl key={'scope-' + idx}>
							<dt>{scope.type}</dt>
							<dd>{scope.value}</dd>
						</dl>
					))}
				</div>
				<Divider />
				<div className={'pass-detail'}>
					<p>이용권 상세정보</p>
					<ul>
						{selectedPass.descriptionList.map((des, idx) => (
							<li key={'description-' + idx}>{des}</li>
						))}
					</ul>
				</div>
			</main>
			<FooterContainer>
				<Button
					onClick={() => {
						mixpanelEvent('Guide Button Clicked', {
							'Guide Categories': 'Product Detail',
							'Product ID': selectedPass.id,
							'Product Title': selectedPass.title,
							'Guide URL': selectedPass.category.includes('expert')
								? 'https://solvookguide.oopy.io/expert'
								: 'https://solvookguide.oopy.io/78b924d5-140f-4475-bd9b-d13d99bedb2b',
						})

						if (selectedPass.category.includes('expert')) {
							window.open('https://solvookguide.oopy.io/expert', '_blank')
						} else {
							window.open(
								'https://solvookguide.oopy.io/78b924d5-140f-4475-bd9b-d13d99bedb2b',
								'_blank',
							)
						}
					}}
					variant="outlined"
					size="large"
					label="이용방법 보기"
				/>
				{isPassPage ? (
					<Button
						onClick={() => {
							if (ctaAction) {
								ctaAction()
							} else {
								onPurchase()
							}
						}}
						variant="solid"
						size="large"
						label="구매하기"
					/>
				) : (
					<Button
						onClick={setIsPassInfoModal}
						variant="solid"
						size="large"
						label="확인"
					/>
				)}
			</FooterContainer>
		</PassInfoModalContainer>
	)
}

const CloseButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<CloseButtonContainer onClick={onClick}>
			<Icon icon={Close} size={Sizes.medium} />
		</CloseButtonContainer>
	)
}
const CloseButtonContainer = styled.button`
	all: unset;
	/* position: absolute; */
	border: none;
	display: block;
	background: transparent;
	top: 28px;
	right: 28px;
	font-size: 0;
	width: 24px;
	height: 24px;
	cursor: pointer;
	margin-top: 4px;
`
const PassInfoModalWrapper = styled(Modal)`
	padding: 72px 0;

	> div {
		border-radius: 8px;
		display: flex;
	}
`
export const PassInfoModalContainer = styled.div`
	main {
		padding: 28px;
		&::-webkit-scrollbar {
			width: 5px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 32px;
			background: ${(props) => props.theme.new.colors.sol_gray_300};
		}

		height: auto;
		flex-grow: 1;
		overflow-y: auto;
		overflow-x: hidden;
		width: 520px;

		h4 {
			display: flex;
			justify-content: space-between;

			font-size: 20px;
			font-weight: 700;
			line-height: 1.5;
			letter-spacing: -0.2px;
			margin-bottom: 8px;
		}

		p {
			color: ${(props) => props.theme.new.colors.sol_gray_700};
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
			letter-spacing: -0.16px;
			margin-bottom: 12px;
		}

		dl {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 4px;

			dt,
			dd {
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: -0.16px;
				margin-bottom: 0;

				b {
					color: ${(props) => props.theme.new.colors.sol_blue_500};
				}
			}
		}

		.pass-summary {
			dl {
				dd {
					margin-left: 4px;
				}

				dt,
				dd {
					color: ${(props) => props.theme.new.colors.sol_gray_500};
				}

				dd.style-zero {
					margin-left: 0;
				}
			}
		}

		.pass-range {
			dl {
				dt {
					color: ${(props) => props.theme.new.colors.sol_gray_300};
					width: 100px;
				}

				dd {
					display: flex;
					flex: 1;
					color: ${(props) => props.theme.new.colors.sol_gray_700};
				}
			}
		}

		.pass-detail {
			ul {
				li {
					color: ${(props) => props.theme.new.colors.sol_gray_500};
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: -0.16px;
					list-style: disc;
					margin-inline-start: 18px;
					margin-bottom: 4px;
					white-space: break-spaces;
				}
			}
		}
	}

	.ant-divider {
		margin: 28px 0;
	}

	@media (max-width: 768px) {
		main {
			width: unset;
			text-align: left;
			padding: 0;

			.pass-detail {
				ul {
					li {
						font-size: 14px;
					}
				}
			}
		}
	}
`
const FooterContainer = styled.div`
	display: flex;
	position: sticky;
	bottom: 0;
	background-color: ${(props) => props.theme.new.colors.white};
	padding: 20px 28px;
	gap: 8px;
	border-radius: 8px;

	button {
		width: 100%;
	}

	@media (max-width: 768px) {
		padding: 20px 0;
	}
`

const ModalFilledButton = styled(FilledButton)`
	padding: 12px 20px;
	font-size: 16px;
`
const ModalFooter = styled.div`
	display: flex;
	gap: 8px;
	@media (max-width: 768px) {
		gap: 8px;
		.ant-btn {
			margin-inline-start: 0px !important;
		}
	}
`
