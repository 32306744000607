import styled from 'styled-components'
import { ReactNode } from 'react'

export const EmptyWrapper = ({ description }: { description: ReactNode }) => {
	return (
		<EmptyPassContainer>
			<p>{description}</p>
		</EmptyPassContainer>
	)
}
const EmptyPassContainer = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 10px;
	margin-bottom: 28px;

	h3 {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.5;
		letter-spacing: -0.2px;
		margin-bottom: 12px;
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.16px;
		padding: 28px 0;
	}

	@media (max-width: 768px) {
		.ant-row {
			flex-direction: column;
		}
	}
`
