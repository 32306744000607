import { ActiveTab } from '@/components/common/ActiveTab'
import FilledButton from '@/components/common/Button/FilledButton'
import Modal from '@/components/modal/Modal'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import Image from '@/components/common/Image'
import React, { useEffect, useMemo, useState } from 'react'
import Sheet from 'react-modal-sheet'
import styled from 'styled-components'

import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import { DEFAULT_BOOK_COVER } from '@/settings/constant'
import { IProduct, IRelatedProduct } from 'src/api/product'
import useHandoutProductStore from 'src/store/relatedProduct'

export interface PreviewRelatedItemProps {
	isOpen: boolean
	onClose: () => void
	usePassConfirmed: () => void
}

export const AvailableRelatedProductsModal: React.FC<PreviewRelatedItemProps> = ({
	isOpen,
	onClose,
	usePassConfirmed,
}) => {
	const { isMobile } = useWindowDimensions()
	//태블릿은 모달
	//모바일은 바텀시트
	if (!isMobile)
		return (
			<RelatedProductsPurchasedModalContainer
				open={isOpen}
				onClose={onClose}
				width={null}
				depth={1007}>
				<AvailableRelatedProducts onClose={onClose} usePassConfirmed={usePassConfirmed} />
			</RelatedProductsPurchasedModalContainer>
		)
	return (
		<PassInfoSheet isOpen={isOpen} onClose={onClose} detent={'content-height'}>
			<Sheet.Container>
				<Sheet.Content>
					<AvailableRelatedProducts
						onClose={onClose}
						usePassConfirmed={usePassConfirmed}
					/>
				</Sheet.Content>
			</Sheet.Container>
		</PassInfoSheet>
	)
}
const RelatedProductsPurchasedModalContainer = styled(Modal)`
	padding: 72px 0;
`
const PassInfoSheet = styled(Sheet)<{ isOpen: boolean }>`
	background-color: rgba(28, 31, 46, 0.5);
	${(props) => !props.isOpen && 'background-color: transparent;'}
`
export const thumbWidth = 56

const AvailableRelatedProducts = ({ onClose, usePassConfirmed }) => {
	const { updatePurchaseProductsInfo, currentProduct, membershipAvailable, hasAlreadyOwned } =
		useHandoutProductStore((state) => ({
			updatePurchaseProductsInfo: state.updatePurchaseProductsInfo,
			currentProduct: state.currentProduct,
			membershipAvailable: state.membershipAvailable,
			hasAlreadyOwned: state.hasAlreadyOwned,
		}))
	const usableProducts = useMemo(() => {
		if (currentProduct && !hasAlreadyOwned) {
			//연관교재 + 현재 보고있는 교재
			return membershipAvailable.usable.concat([
				{
					title: currentProduct.title,
					price: currentProduct.price,
					id: currentProduct.id.toString(),
					author: currentProduct.author,
					unit: '',
					thumbImg: currentProduct.thumb_img,
					previewImgs: null,
				},
			])
		}
		return membershipAvailable.usable
	}, [membershipAvailable, hasAlreadyOwned, currentProduct])
	const unUsableProducts = useMemo(() => {
		if (currentProduct && hasAlreadyOwned) {
			return membershipAvailable.unusable.concat([
				{
					title: currentProduct.title,
					price: currentProduct.price,
					id: currentProduct.id.toString(),
					author: currentProduct.author,
					unit: '',
					thumbImg: currentProduct.thumb_img,
					previewImgs: null,
				},
			])
		}
		return membershipAvailable.unusable
	}, [membershipAvailable, hasAlreadyOwned, currentProduct])
	const [currentTab, setCurrentTab] = useState('usable')

	const onChangeTab = (key: string) => {
		setCurrentTab(key)
		mixpanelEvent('Tab UI Clicked', {
			'Tab Type': 'Pass Availability',
			'Tab Name': key,
		})
	}
	const onClick = () => {
		//선택교재 업데이트
		updatePurchaseProductsInfo({
			selectedRelatedProducts: membershipAvailable.usable,
			totalCount: membershipAvailable.usable.length + 1,
			totalPrice:
				membershipAvailable.usable.reduce((acc, cur) => acc + cur.price, 0) +
				currentProduct.price,
		})
		//이용권 사용하기
		onClose()
		usePassConfirmed()
		mixpanelEvent('Popup Button Clicked', {
			'Popup Type': 'Pass Availability Confirmed',
			'Popup Title': `사용가능 자료 ${usableProducts.length}개에 이용권을 사용할까요?`,
			'Button Name': `${usableProducts.length}회 사용하기`,
			'Button URL': null,
		})
	}
	const noUsableProducts = useMemo(() => usableProducts.length === 0, [usableProducts])
	const tabList = [
		{
			key: 'usable',
			label: (
				<TabItem className={'usable'}>
					사용가능 자료 <em>{usableProducts.length}개</em>
				</TabItem>
			),
		},
		{
			key: 'unusable',
			label: (
				<TabItem className={'unusable'}>
					사용불가 자료 <em>{unUsableProducts.length}개</em>
				</TabItem>
			),
		},
	]
	useEffect(() => {
		mixpanelEvent('Pass Availability Popup Viewed', {
			'Product IDs': usableProducts
				.map((product) => product.id)
				.concat(currentProduct?.id.toString()),
			'Product Titles': usableProducts
				.map((product) => product.title)
				.concat(currentProduct?.title),
			'Pass Availability': 'Y',
		})
		mixpanelEvent('Pass Availability Popup Viewed', {
			'Product IDs': unUsableProducts.map((product) => product.id),
			'Product Titles': unUsableProducts.map((product) => product.title),
			'Pass Availability': 'N',
		})
	}, [])
	useEffect(() => {
		if (noUsableProducts) {
			setCurrentTab('unusable')
		}
	}, [noUsableProducts])
	return (
		<RelatedProductsPurchasedContainer>
			<Header>
				<h3>
					{noUsableProducts ? (
						`선택하신 자료들은 이용권을 사용할 수 없어요.`
					) : (
						<>
							사용가능 자료 <em>{usableProducts.length}개</em>에 이용권을 사용할까요?
						</>
					)}
				</h3>
				<p>
					{noUsableProducts ? (
						`이미 구매한 자료이거나 이용권 사용 조건에 맞지 않아요.`
					) : (
						<>
							선택한 이용권에 맞지 않는 자료가 있어요. <br />
							아래 리스트에서 자료를 확인 후 이용권 사용하기를 눌러주세요.
						</>
					)}
				</p>
			</Header>
			<ActiveTab activeTab={currentTab} setActiveTab={onChangeTab} tabList={tabList} />
			<ProductListLayout>
				{currentTab === 'unusable'
					? unUsableProducts.map((product, idx) => (
							<RelatedProductItem
								key={`unusable` + idx}
								currentProduct={currentProduct}
								hasAlreadyOwned={hasAlreadyOwned}
								product={product}
							/>
					  ))
					: usableProducts.map((product, idx) => (
							<RelatedProductItem product={product} key={`usable` + idx} />
					  ))}
			</ProductListLayout>
			<Footer>
				{noUsableProducts ? (
					<FilledButton type={'text'} variant={'solid'} onClick={onClose}>
						닫기
					</FilledButton>
				) : (
					<>
						<FilledButton
							type={'text'}
							variant={'soft'}
							onClick={() => {
								onClose()
								mixpanelEvent('Popup Button Clicked', {
									'Popup Type': 'Pass Availability Confirmed',
									'Popup Title': `사용가능 자료 ${usableProducts.length}개에 이용권을 사용할까요?`,
									'Button Name': '닫기',
									'Button URL': null,
								})
							}}>
							닫기
						</FilledButton>
						<FilledButton type={'text'} variant={'solid'} onClick={onClick}>
							{usableProducts.length}회 사용하기
						</FilledButton>
					</>
				)}
			</Footer>
		</RelatedProductsPurchasedContainer>
	)
}
const Footer = styled.div`
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	gap: 8px;
`
const ProductListLayout = styled.div`
	overflow: auto;
	flex-grow: 1;
`
const Header = styled.div`
	h3 {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.5;
		letter-spacing: -0.2px;

		em {
			color: ${(props) => props.theme.new.colors.sol_blue_500};
		}
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_700};
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.16px;
	}
`
const RelatedProductsPurchasedContainer = styled.div`
	padding: 28px;
	display: flex;
	flex-direction: column;
	gap: 28px;
	overflow-x: hidden;
	height: 80vh;
	width: 520px;
	.active-tab-wrapper {
		li {
			width: 100%;

			div {
				line-height: 1.5;
			}
		}

		.active {
			.usable {
				em {
					color: ${(props) => props.theme.new.colors.sol_blue_500};
				}
			}

			.unusable {
				em {
					color: ${(props) => props.theme.new.colors.sol_red_500};
				}
			}
		}
	}
	@media (max-width: 768px) {
		width: unset;
	}
`
const TabItem = styled.div`
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: -0.16px;
	text-align: center;
`

const RelatedProductItem = ({
	product,
	hasAlreadyOwned,
	currentProduct,
}: {
	product: IRelatedProduct
	hasAlreadyOwned?: boolean
	currentProduct?: IProduct
}) => {
	const isCurrentProduct = useMemo(
		() => currentProduct?.id.toString() === product.id,
		[currentProduct, product],
	)
	return (
		<RelatedProductItemContainer>
			<ImageContainer>
				<Image
					src={product.thumbImg || DEFAULT_BOOK_COVER}
					alt={product.title}
					width={44}
					height={62}
				/>
			</ImageContainer>

			<div>
				<Title>{product.title}</Title>
				{hasAlreadyOwned && isCurrentProduct ? (
					<Price>이미 구입한 자료</Price>
				) : (
					<Price>{numberFormatter(product.price)}원</Price>
				)}
			</div>
		</RelatedProductItemContainer>
	)
}
const RelatedProductItemContainer = styled.div`
	display: flex;
	padding: 12px 0;

	> div {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.activeTab {
	}
`
const Title = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: -0.16px;
`
const Price = styled.p`
	color: ${(props) => props.theme.new.colors.sol_gray_500};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.14px;
`
const ImageContainer = styled.div`
	width: 44px;
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;

	img {
		box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
		border-radius: 2px;
	}
`
