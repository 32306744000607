import { ScrollBody } from '@/components/Coupons/AdditionalCouponContents'
import SimpleDialog from '@/components/Coupons/SimpleDialog'
import Icon from '@/components/icon/Icon'
import { Close, Sizes } from '@/components/icon/IconList'
import AnchorDrawer from '@/components/modal/AnchorDrawer'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Divider, Typography, styled as muiStyled, useTheme } from '@mui/material'
import { PropsWithChildren, useMemo } from 'react'
import { PassCategory, UseTicketProductInfo } from 'src/api/membership'
import { IMappedPassProduct } from 'src/store/pass'
import { formatDateToDot } from 'src/utils/formatting'
import styled from 'styled-components'
import { getPassScope } from '../getPassScope'

interface DialogProps {
	open: boolean
	onClose: () => void
	title?: string
	subParagraph?: string
	footer?: React.ReactNode
	contentInfo?: UseTicketProductInfo & Pick<IMappedPassProduct, 'expiredAt'>
	withCloseIcon?: boolean
}
export const SubscribeDialog = ({
	open,
	onClose,
	footer,
	contentInfo,
	withCloseIcon = true,
	children,
}: PropsWithChildren<DialogProps>) => {
	const { isMobile } = useWindowDimensions()
	if (!contentInfo) return null

	if (isMobile)
		return (
			<AnchorDrawer
				anchor={'bottom'}
				open={open}
				onClose={() => {
					onClose()
					//mx-140
					mixpanelEvent('Close ui', {
						Type: '딤클릭해서 닫기',
						Position: `${contentInfo.category} detail popup`,
					})
				}}>
				<AnchorDrawer.Contents>
					<AnchorDrawer.Title>
						{contentInfo.title}
						{withCloseIcon && (
							<ButtonBox
								onClick={() => {
									onClose()
									//mx-140
									mixpanelEvent('Close ui', {
										Type: '닫기버튼',
										Position: `${contentInfo.category} detail popup`,
									})
								}}>
								<Icon icon={Close} size={Sizes.medium} />
							</ButtonBox>
						)}
					</AnchorDrawer.Title>
					<InfoHeader subscribeInfo={contentInfo} />
					<ScrollBody>
						<InfoBody subscribeInfo={contentInfo} />
					</ScrollBody>
				</AnchorDrawer.Contents>
				{footer}
			</AnchorDrawer>
		)
	return (
		<SimpleDialog
			open={open}
			onClose={() => {
				onClose()
				//mx-140
				mixpanelEvent('Close ui', {
					Type: '닫기버튼',
					Position: `${contentInfo.category} detail popup`,
				})
			}}
			scroll="paper"
			title={contentInfo.title}
			subParagraph={<InfoHeader subscribeInfo={contentInfo} />}
			footer={footer}>
			<InfoBody subscribeInfo={contentInfo} />
		</SimpleDialog>
	)
}
const InfoHeader = ({ subscribeInfo }: { subscribeInfo: DialogProps['contentInfo'] }) => {
	const theme = useTheme()
	const { availableCount, category } = subscribeInfo
	const isExpertSubscribe = useMemo(() => {
		return category === PassCategory.ExpertSubscription
	}, [category])
	return (
		<SubParagraphBox>
			<Typography variant="regular16" sx={{ color: theme.palette.grey[500] }}>
				{isExpertSubscribe
					? `총 사용가능 문제 수 : ${availableCount}개`
					: '총 사용가능 횟수 : 조건 내 무제한 사용'}
			</Typography>
			<Typography variant="regular16" sx={{ color: theme.palette.grey[500] }}>
				사용 기간: 구독 해지시까지 사용가능
			</Typography>
			{
				// 정기결제 다음 결제일, 결제 예정 금액
				subscribeInfo.expiredAt && subscribeInfo.price && (
					<>
						<Typography variant="regular16" sx={{ color: theme.palette.grey[500] }}>
							{`다음 결제일 : ${formatDateToDot(subscribeInfo.expiredAt)}`}
						</Typography>
						<Typography variant="regular16" sx={{ color: theme.palette.grey[500] }}>
							{`결제 예정 금액: ${numberFormatter(subscribeInfo.price)}원`}
						</Typography>
					</>
				)
			}
		</SubParagraphBox>
	)
}
const InfoBody = ({ subscribeInfo }: { subscribeInfo: UseTicketProductInfo }) => {
	const descriptionList = useMemo(() => {
		if (!subscribeInfo.description) return []

		return subscribeInfo.description.split('\n')
	}, [subscribeInfo])

	return (
		<ContentBody>
			<Divider sx={(theme) => ({ color: theme.palette.grey[50], borderStyle: 'thin' })} />
			<ContentGridBox>
				<Typography variant="semibold16">이용권 사용범위</Typography>
				{getPassScope(subscribeInfo).map((scope, idx) => (
					<GridRow key={'scope-' + idx}>
						<PrefixText variant="regular16">{scope.type}</PrefixText>
						<Typography variant="regular16">{scope.value}</Typography>
					</GridRow>
				))}
			</ContentGridBox>
			<Divider sx={(theme) => ({ color: theme.palette.grey[50], borderStyle: 'thin' })} />

			<ContentGridBox>
				<Typography variant="semibold16">이용권 상세정보</Typography>
				<ul>
					{descriptionList.map((des, idx) => (
						<li key={'description-' + idx}>
							<Typography variant="regular14">{des}</Typography>
						</li>
					))}
				</ul>
			</ContentGridBox>
		</ContentBody>
	)
}

const SubParagraphBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;

	span.MuiTypography-root {
		padding: 0;
	}
`

const ContentBody = muiStyled('div')(({ theme }) => ({
	'span.MuiTypography-root': {
		padding: 0,
	},

	'.MuiDialogContent-root': {
		padding: '0 28px',
	},
}))
const ContentGridBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 28px 0;

	li {
		list-style: initial;
		margin-inline-start: 24px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}
`

const GridRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 3.5fr;
`
const PrefixText = styled(Typography)`
	color: ${({ theme }) => theme.new.colors.sol_gray_300};
`
const ButtonBox = muiStyled('button')({
	all: 'unset',

	width: '30px',
	height: '30px',

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
	padding: '0',
})
