import * as React from 'react'
import { Typography } from 'antd'

const RText = Typography.Text
const RTitle = Typography.Title
const RParagraph = Typography.Paragraph

export const Text = Typography.Text
export const Title = Typography.Title
export const Paragraph = Typography.Paragraph
